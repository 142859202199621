'use strict';
angular.module('enervexSalesappApp').controller('DrawingCtrl', function($scope, $stateParams, $window, $uibModal, $q, Auth, DesignService, ExcludeMetaUtil, Account, Job, _, FanVoltage, ApplianceModel, ApplianceCompany, Util, Quote, Document, Control, $state, ApplianceCategory, Design, Fitting, Icon, VentMaterial, BuildType, Fan, BaffleType, ModType, Property, Baffle, StackOption, DesignImage, FittingMetaUtil, Product, ProductType, ProductSubtype, Application, SelectionMetaUtil, Manufacturer, LengthFitment, LayoutUtil, CommandManager, StackRule, LengthUtil, LineitemSource,SavingsGoal, PlumeHeader, PlumeCoilhand, Flash, SystemsManager, ChartUtil, FormatUtil, ModalUtil, FileUploader, DrawingUtil, Diameter) {

	var getDim1 = LengthUtil.getDim1;
	var getDim2 = LengthUtil.getDim2;
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.designId = $stateParams.designId;
	$scope.isPreviousVersion = $stateParams.version ? true : false;
	$scope.alwaysSavable = true;
	$scope.fittings = [];
	$scope.icons = [];
	$scope.isAdmin = Auth.isAdmin;
	$scope.configs = Property.configs();
	$scope.orderedSelection = []
	$scope.orderedAppliances = []
	$scope.ctx = {
		httpLoading: false,
		showAdmin: false,
		selectedSystem: "All",
		asNew: false
	}
	$scope.convertContext = {
		state: null
	}
	$scope.hasUserPermission = function(name) {
		return Auth.hasUserPermission(name)
	}
	// $scope.SystemsManager = SystemsManager;

	function createEnergySavings() {
		if (!$scope.design.energySavings) {
			$scope.design.energySavings = {
				repairsCost:0,
				cleaningCost: 0,
				heatingCost: 0,
				coolingCost: 0,
				heatingDegreeDays: 0,
				heatingDegreeDays: 0,
				heatingEfficiency: 0,
				coolingEfficiency: 0,


				//computed
				powerConsumption: 0,
				totalCost: 0,
				totalCostPerYear: 0,
				annualOperatingCost: 0,
				fixSpeedInputKW: 0,
				annualSavings: 0,
				lossPerApplianceYear: 0,
				lossTotalPerYearCost: 0,
				lossTotalPerYearTherms: 0,
				lossTotalPerYearKwh: 0,
				energySavingsFanPowerConsumption: 0,
				energySavingsLossConditionedAirCost: 0,
				energySavingsLossConditionedAirTherms: 0,
				energySavingsLossConditionedAirFromGas: 0,
				energySavingsLossConditionedAirKwh: 0,
				energySavingsLossConditionedAirFromElectricity: 0,
				totalEnerySavingsCost: 0,
				totalEnerySavingsTotalCo2Reduction: 0,
			}
		}

	}
	$scope.setAccordianArrow = function(isOpen) {
		var style = 'pull-right glyphicon'
		if (isOpen) {
			style+= ' glyphicon-chevron-down'
		} else {
			style+= ' glyphicon-chevron-right'
		}
		return style
	}
	$scope.$on("$destroy", function() {
		Flash.clear()
	})
	LineitemSource.query().$promise.then(function(result){
		$scope.lineitemSources = result
		$scope.newItem = createNewItem();
	})
	$scope.lineitemSourceName = function(code) {
		var result = _.find($scope.lineitemSources,function(o){
			return o.code == code
		})
		return result ? result.name : ""
	}
	$scope.hasLineitemSource = function(code) {
		var found = _.find($scope.design && $scope.design.items,function(item){
			return _.find(item.systemSources, function(o){
				return o[code]
			})
		})
		return found ? true: false
	}
	$scope.addQuantity = function(lineitem, amount) {
		if (lineitem.quantity + amount > 0) {
			lineitem.quantity = lineitem.quantity + amount
			$scope.ctx.formChanged = true;
			$scope.changeQuantity(lineitem)
			$scope.calcTotal()
			$scope.itemAdded
		}
	}
	$scope.itemAdded = function() {
		CommandManager.createCommand($scope.design, {
			name: "modify line item quantity"
		},$scope.checkDirty)
	}
	$scope.canEditQuantity = function(lineitem) {
		if (lineitem.origin == "manual") {
			//check to see that not multiple sources
			var filtered = _.filter($scope.lineitemSources, function(o){
				return lineitem.source[o.code] > 0
			})
			return filtered.length <= 1 && lineitem.systemSources.length == 1
		} else {
			return false
		}
	}
	$scope.changeQuantity = function(lineitem) {
		var lineitemSource = _.find($scope.lineitemSources, function(o){
			return (lineitem.source[o.code] > 0) 
		})
		lineitem.systemSources[0][lineitemSource.code] = lineitem.quantity
		lineitem.source[lineitemSource.code] = lineitem.quantity
	}
	ProductSubtype.query({active: true}).$promise.then(function(result){
		$scope.allProductSubtypes = Util.sortUncased(result, "name")
	})
	ProductType.query().$promise.then(function(result){
		$scope.allProductTypes = _.filter(result, function(o){
			return o.active
		})
	})
	LengthFitment.query().$promise.then(function(result){
		$scope.lengthFitments = result
	})
	Product.inlineFans().$promise.then(function(result){
		$scope.allInlineFans = Util.sortUncased(result, "name")
	})
	Product.economizers().$promise.then(function(result){
		$scope.allEconomizers = Util.sortUncased(result, "name")
	})
	StackRule.query().$promise.then(function(result){
		$scope.stackRules = result
	})
	SavingsGoal.query().$promise.then(function(result){
		$scope.savingsGoals = result
	})
	PlumeHeader.query().$promise.then(function(result){
		$scope.plumeHeaders = result
	})
	PlumeCoilhand.query().$promise.then(function(result){
		$scope.plumeCoilhands = result
	})
	Diameter.query().$promise.then(function(result){
		$scope.diameters = result
	})

	$scope.divDiaX = false;
	$scope.triangleLengths = DrawingUtil.getTriangleLengths()
	$scope.codeViolationModes = [{
		_id: "unset",
		name: "Not Set"
	}, {
		_id: 'hideIfFanPresent',
		name: "Hide if Fan Present"
	}, {
		_id: 'showIfFanPresent',
		name: 'Show if Fan Present'
	}]
	$scope.autofitModes = [{
		_id: "off",
		name: "Off"
	}, {
		_id: 'autofit',
		name: "Autofit"
	}, {
		_id: 'autofitComplete',
		name: 'Autofit Complete'
	}]
	$scope.variableModes = [{
		name: "Variable (if available)",
		_id:'variablePlusAdjustable'
	},{
		name: "Adjustable Only",
		_id:'adjustableOnly'
	},{
		name: "Not Set",
		_id:'unset'
	}]
	function checkQuotes() {
		$scope.existingQuote = null;
		$scope.existingDesign = null;
		if (!$scope.design) {
			return
		}
		Quote.query({
			accountId: $scope.accountId,
			jobId: $scope.jobId
		}).$promise.then(function(quotes) {
			$scope.existingQuote = _.find(quotes, function(quote) {
				$scope.existingDesign = _.find(quote.designs, function(design) {
					return design.design && design.design._id == $scope.design._id
				})
				return $scope.existingDesign;
			})
			$scope.checkDirty()
		})
	}
	$scope.stickySelections = {
		values: {

		},
		props: ["exhFan", 'supFan', "control"],
		propNames: ["Exhaust Fan", 'Supply Fan', "Control"],
		status: "clean", //pending, clean
		_updateStatus: function(type) {
			var existingControl = _.find($scope.cmbControls.options, function(option) {
				return option.code == $scope.stickySelections.values.control
			})
			if (!existingControl) {
				$scope.stickySelections.values.control = null
			}
			var existingExhFan = _.find($scope.cmbExhFans.options, function(option) {
				return option.Name == $scope.stickySelections.values.exhFan
			})
			if (!existingExhFan) {
				$scope.stickySelections.values.exhFan = null
			}
			var existingSupFan = _.find($scope.cmbSupFans.options, function(option) {
				return option.Name == $scope.stickySelections.values.supFan
			})
			if (!existingSupFan) {
				$scope.stickySelections.values.supFan = null
			}

			var differentValues = _.filter($scope.stickySelections.props, function(prop){
				var value = $scope.stickySelections.values[prop]
				var existingValue = _.get($scope.design, prop)
				if (value && value != existingValue) {
					return true
				}
			})

			if (differentValues.length > 0) {
				$scope.stickySelections.status = "pending"	
			} else {
				$scope.stickySelections.status = "clean"
			}
			console.log("stickySelections.status", $scope.stickySelections.status, $scope.stickySelections.values, differentValues)
		},
		isPending: function () {
			if ($scope.calculateBy == "event") {
				var mismatch = _.find($scope.stickySelections.props, function(prop){
					var value = $scope.stickySelections.values[prop]
					var existing = _.get($scope.design, prop)
					if (value && value != existing) {
						return true
					}
				})
				return mismatch ? true : false
			}
		},
		capture: function(type) {
			var value = $scope.design[type]
			$scope.stickySelections.values[type] = value
			$scope.stickySelections._updateStatus(type)
		},
		dismiss: function(type) {
			$scope.stickySelections.status = "clean"
			$scope.stickySelections.values = {}
		},
		override: function() {
			_.each($scope.stickySelections.props, function(prop){
				var value = $scope.stickySelections.values[prop]
				var existingValue = _.get($scope.design, prop)
				if (value && value != existingValue) {
					_.set($scope.design, prop, value)
				}
			})
			$scope.stickySelections.status = "clean"
			$scope.stickySelections.values = {}
		}
	}
	function getPermission(name) {
		return $scope.permissions && $scope.permissions[name]
	}
	$scope.checkDirty = function() {
		$scope.updateCan()
	}
	$scope.updateCan = function() {
		$scope.canUndo = $scope.checkCanUndo()
		// console.log("canUndo", $scope.canUndo)

		$scope.canSaveAndUpdateQuote = $scope.checkCanSaveAndUpdateQuote()
		// console.log("canSaveAndUpdateQuote", $scope.canSaveAndUpdateQuote)
		$scope.canSaveAndCreateQuote = $scope.checkCanSaveAndCreateQuote()

		$scope.canUpdateQuote = $scope.checkCanUpdateQuote()
		$scope.canCreateQuote = $scope.checkCanCreateQuote()

		$scope.canViewQuote = $scope.checkCanViewQuote()

		$scope.canSaveVersion = $scope.checkCanSaveVersion()
		$scope.canPromoteVersion = $scope.checkCanPromoteVersion()
		$scope.canSaveAs = $scope.checkCanSaveAs()
	}
	$scope.checkCanViewQuote = function() {
		if ($scope.canSaveAndUpdateQuote ||
			$scope.canUpdateQuote ||
			$scope.canSaveAndCreateQuote ||
			$scope.canCreateQuote) {
			return false
		}
		return $scope.existingDesign && $scope.existingDesign.version == $scope.design.version && !$scope.isPreviousVersion
	}
	$scope.checkCanUpdateQuote = function() {
		if ($scope.canSaveAndUpdateQuote) {
			return false;
		} else {
			return $scope.existingDesign && $scope.existingDesign.version != $scope.design.version && !$scope.isPreviousVersion && getPermission("edit")
		}
	}
	$scope.checkCanSaveAndUpdateQuote = function() {
		if (!$scope.canUndo) {
			return false;
		} else {
			return $scope.existingQuote && !$scope.isPreviousVersion && getPermission("edit")
		}
	}
	$scope.checkCanSaveAndCreateQuote = function() {
		if (!$scope.canUndo) {
			return false
		} else {
			return !$scope.existingQuote && !$scope.isPreviousVersion && getPermission("edit")
		}
	}
	$scope.checkCanCreateQuote = function() {
		if ($scope.canSaveAndCreateQuote) {
			return false
		} else {
			return !$scope.existingQuote && !$scope.isPreviousVersion && getPermission("edit")
		}
	}
	$scope.checkCanSaveVersion = function() {
		if (!$scope.canUndo) {
			return false
		}
		return !$scope.isPreviousVersion && getPermission("edit")
	}
	$scope.checkCanPromoteVersion = function() {
		return $scope.isPreviousVersion && getPermission("edit")
	}
	$scope.checkCanSaveAs = function() {
		return getPermission("edit")
	}
	$scope.checkCanUndo = function() {
		if (!$scope.design){
			return false
		}
		var commands = CommandManager.getCommands($scope.design)
		// console.log("commands length "+commands.length)
		return commands.length > 1
	}
	$scope.undo = function() {
		var commands = CommandManager.getCommands($scope.design)
		if (commands.length > 1) {
			var command = commands[commands.length -2]
			var design = CommandManager.restoreCommand(command.id, $scope.design)
			if (design) {
				_loadLocal(design)
			}
		}
	}
	$scope.includesDisabled = function() {
		var application = $scope.design && $scope.design.application
		return (application) ? application.properties.stackOnly : false
	}
	// $scope.selectedSystem = function() {
	// 	return $scope.ctx.selectedSystem;
	// }
	$scope.availableSystems = function() {
		return SystemsManager.availableSystems
	}
	$scope.hasMultipleSystems = function() {
		return SystemsManager.hasMultipleSystems($scope.design)
	}
	$scope.filterSections = function(_sections) {
		var sections = null
		if ($scope.ctx.selectedSystem == "All") {
			 sections = _sections
		} else {
			sections = _.filter(_sections, function(ss){
				var system = $scope.calcSystem(ss.section)
				return system == $scope.ctx.selectedSystem
			})
		}
		if (!sections) {
			sections = []
		}
		return sections
	}
	$scope.calcSystem = function(GUIidentPath) {
		try {
			var pairs = GUIidentPath.split(".")
			return parseInt(pairs[0])
		} catch(e) {
			console.log("erro splitting path ", GUIidentPath, e)
		}
	}
	$scope.calcSection = function(GUIidentPath) {
		try {
			var pairs = GUIidentPath.split(".")
			return pairs[1]
		} catch(e) {
			console.log("erro splitting path ", GUIidentPath, e)
		}
	}
	$scope.calcFittingEnd = function(path) {
		var pairs = path.split(".")
		return pairs[1] + "." + pairs[2]
	}
	$scope.calcCount = function(item, level, _systemId) {
		var systemId = _systemId ? _systemId : $scope.ctx.selectedSystem
		return SystemsManager.calcCount(item, level, systemId, $scope.lineitemSources)
	}
	$scope.bomLink = function(rule) {
		return "B-"+rule.id.substring(rule.id.length -5)
	}
	$scope.stackLink = function(rule) {
		return "S-"+rule.id.substring(rule.id.length -5)
	}
	$scope.systemName = function(systemId) {
		return SystemsManager.systemName(systemId, $scope.design)
	}
	$scope.calcTotalcount = function(level) {
		var totalCount = 0
		_.each($scope.design && $scope.design.items, function(item){
			totalCount = totalCount + $scope.calcCount(item, level)
		})
		return totalCount
	}
	$scope.hasMissingPrice = function() {
		var missing = _.find($scope.design && $scope.design.items, function(item){
			if (!$scope.hasList(item)) {
				return true
			}
		})
		return missing ? true : false
	}
	$scope.hasList = function(item) {
		if (item.list == undefined) {
			return false
		}
		if (item.list === null) {
			return false
		}
		return true
	}
	$scope.calcTotalPrice = function(level) {
		var totalPrice = 0
		_.each($scope.design && $scope.design.items, function(item){
			if (!$scope.hasList(item)) {
				return true
			}
			totalPrice = totalPrice + $scope.calcCount(item, level) * item.list
		})
		return totalPrice
	}
	$scope.economizerSectionFit1 = function() {
		return Util.economizerSectionFit1($scope.design)
	}
	$scope.economizerSectionFit2 = function() {
		return Util.economizerSectionFit2($scope.design)
	}
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})
	function addCustomComponent(component) {
		if (!$scope.design.customComponents) {
			$scope.design.customComponents = []
		}
		var found = _.find($scope.design.customComponents, function(product){
			return product._id == component._id
		})
		if (!found){
			$scope.design.customComponents.push(component)
		}
	}
	$scope.designUploader = new FileUploader({});
	$scope.importItems = function() {
		_.each($scope.designUploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			Flash.clear()
			Design.importItems({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.designId,
			}, fd).$promise.then(function(design){
				$scope.designUploader.clearQueue()
				$scope.design = design
			}).catch(function(e){
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.designUploader.clearQueue()
			})
		});
	}

	$scope.getProductType = function(id) {
		if (!id) {
			return
		} else {
			var product = $scope.allProductTypes.find(function(productType) {
				return productType._id == id
			})
			return product.name
		}
	}
	$scope.openSystems = function() {
		var parentElem = angular.element(document.querySelector('#bomResults'));
		// pass things through to the other controller with the resolve method
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/systemsModal.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'SystemsModalCtrl',
			// resolve: {
			// 	component: function() {
			// 		return component
			// 	},
			// }
		});
		modalInstance.rendered.then(function() {
			$(".modal-content").draggable();
			var newHeight = $(window).height() * .7;
			$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		})
		modalInstance.result.then(function() {
			// if (customComponent) {
			// 	addCustomComponent(customComponent)
			// }
		}).catch(function() {
			$scope._toSelectMode()
		})
		handleModels(modalInstance)
	}
	
	$scope.openLineitemModal = function() {
		ModalUtil.modalBuilder('#bomResults', 'components/design/add-lineitem-modal.html', 'AddLineitemModalCtrl', {
			addProduct: null,
			isApplicationSetting: false
		}, $scope, 'lg')
	}
	$scope.openCustomLineitemModal = function() {
		ModalUtil.modalBuilder('#bomResults', 'components/design/add-custom-lineitem-modal.html', 'AddCustomLineitemModalCtrl', {
			addProduct: function() {
				return $scope.addProduct;
			},
			isApplicationSetting: false
		}, $scope, 'lg')
	}

	$scope.openCustomComponentModal = function(component) {
		if (!$scope.isAdmin) {
			return
		}
		var parentElem = angular.element(document.querySelector('#drawingsMain'));
		// pass things through to the other controller with the resolve method
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/customComponentModal.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'CustomComponentCtrl',
			resolve: {
				component: function() {
					return component
				},
			}
		});
		modalInstance.rendered.then(function() {
			$(".modal-content").draggable();
			var newHeight = $(window).height() * .7;
			$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		})
		modalInstance.result.then(function(customComponent) {
			if (customComponent) {
				addCustomComponent(customComponent)
			}
		}).catch(function() {
			$scope._toSelectMode()
		})
		handleModels(modalInstance)
	}

	//BOM edit
	$scope.itemTypes = [
		{
			name: 'Existing Product',
			value: 'existing'
		},
		{
			name: 'Custom Product',
			value: 'custom'
		}
	]
	$scope.unremoveProduct = function(lineitem){
		lineitem.removed = false;
		$scope.calcTotal();
		CommandManager.createCommand($scope.design, {
			name: "unremove line item"
		},$scope.checkDirty)
	}
	$scope.removeProduct = function(lineitem){
		$scope.formChanged = true;
		if (lineitem.origin == "manual"){
			var index = $scope.design.items.indexOf(lineitem);
			if (index > -1){
				$scope.design.items.splice(index, 1);
			}
		} else {
			lineitem.removed = true
		}
		$scope.calcTotal()
		CommandManager.createCommand($scope.design, {
			name: "remove line item"
		},$scope.checkDirty)
	}
	$scope.validateProduct = function() {
		var item = $scope.newItem

		Flash.clear()
		var messages = []
		if (!item.systemSources[0].lineitemSource) {
			messages.push("Please select a source")
		}
		if (newItem.itemType == "existing") {
			if (!newItem.product) {
				messages.push("Please select a product")
			}
		}
		if (messages.length > 0) {
			var message = "<strong>Error!</strong> Cannot add line item"
			_.each(messages, function(o, index){
				message = message + '<div>'+ o+ '</div>'
			})
			Flash.create('danger', message, 0, {}, true);
			return false
		}
		return true
	}
	$scope.validateProduct = function(item) {
		var newItem = item ? item : $scope.newItem

		Flash.clear()
		var messages = []
		if (!newItem.systemSources[0].lineitemSource) {
			messages.push("Please select a source")
		}
		if (newItem.itemType == "existing") {
			if (!newItem.product) {
				messages.push("Please select a product")
			}
		}
		if (messages.length > 0) {
			var message = "<strong>Error!</strong> Cannot add line item"
			_.each(messages, function(o, index){
				message = message + '<div>'+ o+ '</div>'
			})
			Flash.create('danger', message, 0, {}, true);
			return false
		}
		return true
	}
	$scope.addProduct = function(item){
		//validate item
		if (!$scope.validateProduct(item)) {
			return
		}
		$scope.formChanged = true;
		var lineitemSource = item.systemSources[0].lineitemSource
		item.source[lineitemSource] = item.quantity
		item.systemSources[0][lineitemSource] = item.quantity
		$scope.newItem = createNewItem(item);
		delete item.systemSources[0].lineitemSource
		console.log("added item", item)
		$scope.design.items.push(item);
		$scope.calcTotal()
		//save snapshot
		CommandManager.createCommand($scope.design, {
			name: "add line item"
		},$scope.checkDirty)
	}
	$scope.addCustomProduct = function(item){
		$scope.addProduct(item)
	}
	$scope.clearForm = function(){
		$scope.newItem = createNewItem();
	}
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		containerCssClass:"bomresult-autocomplete",
		dropdownWidth: "500px",
		data: function (term) {
			var params = {
				limit:20,
				term: term,
				manufacturer: $scope.manufacturer._id
			};

			if ($scope.configs.allow_deleted_manual_products != 'true'){
				params.deleted = false;
			}
			// params.isAggregate = 'false'
			return Product.query(params).$promise.then(function(products){
				$scope.productsPage = products
				return _.map(products, function(product){
					return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
				});
			})
		},
		itemSelected: function(item){
			var fullProduct = _.find($scope.productsPage, function(product){
				return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
			})
			$scope.newItem.product = fullProduct;
			fullProduct.formattedPrice = accounting.formatMoney(fullProduct.price);
		}
	}
	function createNewItem(previousItem) {
		if (previousItem) {
			var source = {}
			var previousSystemSouce = previousItem.systemSources[0]
			var previousSouce = previousItem.source
			var systemSource = {
				systemId: previousSystemSouce.systemId,
				lineitemSource: previousSystemSouce.lineitemSource
			}
			_.each($scope.lineitemSources, function(o){
				source[o.code] = 0
				systemSource[o.code] = 0
			})
			return {
				itemType: previousItem.itemType,
				quantity: 1,
				uom: "EA",
				origin: previousItem.origin,
				source: source,
				customProduct: {
					name: "",
					price: 0,
					description: "",
					unit: "EA"
				},
				systemSources: [systemSource]
			}
		} else {
			var source = {}
			var systemSource = {
				systemId: 1,
			}
			_.each($scope.lineitemSources, function(o){
				source[o.code] = 0
				systemSource[o.code] = 0
			})
			return {
				itemType: "existing",
				quantity: 1,
				uom: "EA",
				origin: 'manual',
				source: source,
				customProduct: {
					name: "",
					price: 0,
					description: "",
					unit: "EA"
				},
				systemSources: [systemSource]
			}
		}
	}
	$scope.triangleFonts = DrawingUtil.getTriangleFonts()
	$scope.lineOffsets = DrawingUtil.getLineOffsets()
	$scope.triangleColorOptions = DrawingUtil.getTriangleColorOptions()
	$scope.commonColorPreferences = DrawingUtil.getCommonColorPreferences()
	$scope.detailLineColorOptions = DrawingUtil.getDetailLineColorOptions()
	$scope.paint = function() {
		paint()
	}
	$scope.colorPickerOptions = {
		required: [false, true],
		disabled: false,
		// placeholder: '',
		inputClass: 'drawing-color',
		// id: undefined,
		// name: undefined,
		// validation
		restrictToFormat: true,
		// preserveInputFormat: [false, true],
		// allowEmpty: [false, true],
		// color
		// format: ['hsl', 'hsv', 'rgb', 'hex', 'hexString', 'hex8', 'hex8String', 'raw'],
		format: 'hexString',
		// case: ['upper', 'lower'],
		// sliders
		hue: false,
		// saturation: [false, true],
		lightness: true, // Note: In the square mode this is HSV and in round mode this is HSL
		alpha: false,
		// dynamicHue: [true, false],
		// dynamicSaturation: [true, false],
		// dynamicLightness: [true, false],
		// dynamicAlpha: [true, false],
		// swatch
		swatch: true,
		swatchPos: 'left',
		swatchBootstrap: true,
		swatchOnly: false,
		// popup
		round: true,
		pos: 'bottom right',
		inline: false,
		horizontal: false,
		// show/hide
		// show: {
		// 	swatch: [true, false],
		// 	focus: [true, false],
		// },
		// hide: {
		// 	blur: [true, false],
		// 	escape: [true, false],
		// 	click: [true, false],
		// },
		// // buttons
		// close: {
		// 	show: [false, true],
		// 	label: 'Close',
		// 	class: '',
		// },
		// clear: {
		// 	show: [false, true],
		// 	label: 'Clear',
		// 	class: '',
		// },
		reset: {
			show: true,
			label: 'Reset',
			class: '',
		},
	}
	$scope.eventApi = {
		onChange:  function(api, color, $event) {
			$scope.paint()
		},
		onBlur: function(api, color, $event) {},
		onOpen: function(api, color, $event) {},
		onClose: function(api, color, $event) {},
		onClear: function(api, color, $event) {},
		onReset: function(api, color, $event) {},
		onDestroy: function(api, color) {},
	};
	$scope.changeColor = function(name) {
		if (name == 'lineTriangleColor') {
			$scope.design.lineColor = $scope.design.lineTriangleColor;
		}
		paint()
	}
	$scope.ventMaterialName = function(code){
		var existing = _.find($scope.ventMaterials, function(v){
			return v.code == code
		})
		if (existing){
			return existing.name
		} else {
			return code
		}
	}
	$scope.fittingName = function(code){
		var existing = _.find($scope.fittings, function(v){
			return v.code == code
		})
		if (existing){
			return existing.name
		} else {
			return code
		}
	}
	$scope.getUnits = Util.getUnits;
	$scope.downLength = Util.downLength;
	$scope.dimXText = Util.dimXText;
	$scope.dimYText = Util.dimYText;
	function setDims() {
		if ($scope.design.application.computeAs != "COM"){
			var c = document.getElementById("myCanvas");
			if (c) {
				c.height = window.innerHeight -68;
				c.width = window.innerWidth;
			}
			$(".bottom-bar").css('width', (window.innerWidth - 316)+"px")
		}
		$(".drawing-wrapper").css('height', (window.innerHeight)+"px");
		// $(".drawings-main").css('height', (window.innerHeight - 68)+"px");
		$(".right-toolbar").css('height',(window.innerHeight -68) +"px")
	}
	getDesign();
	$scope.ZoomLev = 1;

	var IsMoving = false,
		Center = {
			X: 0,
			Y: 0
		},
		mPoint = {
			X: 0,
			Y: 0
		},
		CanvasOffset = {
			X: 0,
			Y: 0
		},
		panStart = {},
		DrawPoint = {
			X: 0,
			Y: 0
		},
		MoveStartPoint = null,
		FromPoint = {},
		SelPoint = {},
		SelBoxStart = undefined,
		MoveHandlers = [],
		Tan30 = Math.tan(30 / 180 * Math.PI),
		ShiftX = 0,
		ShiftY = 0,
		ShiftX3D = 0,
		ShiftY3D = 0,
		ShiftZ3D = 0,
		Undo = [],
		Redo = [],
		Auto = false,
		LastSave = "",
		//images
		actionImg = null,
		ctrlDown = false,
		overRideExistingParams = true,
		actDoubleClick = false,
		printStats = false,
		drawLineColor = "#000000";

	$scope.baffleTypes = BaffleType.query();
	$scope.baffles = Baffle.query({active:true});
	$scope.actionContext = {
		dim: null,
		trapped: false,
		change:function($event) {
			console.log("actionContext.change")
			var sections = $scope.actionContext.dim.split(" ");
			var vs = $scope.selectionManager.selectedVents();
			if (vs.length == 1){
				var v = vs[0];
				var sections = this.dim.split(" ");
				_.each(sections, function(s){
					var nv = s.split(":"),
						prop = nv[0],
						value = nv[1];
					if (prop == "X"){
						v.DimX = Util._float(Util.changeDim(value, $scope.design))
					}
					if (prop == "Y"){
						v.DimY = Util._float(Util.changeDim(value, $scope.design))
					}
					if (prop == "Z"){
						v.DimZ = Util._float(Util.changeDim(value, $scope.design))
					}
				});
				$scope._validate()
			}
		},
		trap: function() {
			this.trapped = true;
		},
		untrap: function() {
			this.trapped = false;
		},
		setVent: function(v){
			// var text = "X:"+v.DimX + " Y:"+v.DimY + " Z:"+v.DimZ;
			var dimx = "X:"+Util.formatLength(v.DimX , false, $scope.design, true),
				dimy = " Y:"+Util.formatLength(v.DimY , false, $scope.design, true),
				dimz = " Z:"+Util.formatLength(v.DimZ , false, $scope.design, true);

			$scope.actionContext.dim = $scope.design.viewMode == '3D' ? dimx + dimy + dimz : dimx + dimy;
			if ($scope.DrawMode == "DRAW") {
				$("#actionDim").focus();
				var field = $("#actionDim")[0];
				var start = 2,
					end = 3;
				var direction = $scope.selectionManager.direction();
				if (direction.x){
					start = 2;
					end = dimx.length;
				} else if (direction.y){
					start = dimx.length +3;
					end = dimx.length + dimy.length;
				} else if (direction.z){
					start = dimx.length + dimy.length +3;
					end = dimx.length + dimy.length + dimz.length;
				}
				if( field.createTextRange ) {
					var selRange = field.createTextRange();
					selRange.collapse(true);
					selRange.moveStart('character', start);
					selRange.moveEnd('character', end);
					selRange.select();
				} else if( field.setSelectionRange ) {
					field.setSelectionRange(start, end);
				} else if( field.selectionStart ) {
					field.selectionStart = start;
					field.selectionEnd = end;
				}
			}
			try {
				if (!$scope.$$phase) {
				  $scope.$apply();
				}
			} catch(squash){}

		},
		unsetVent:function(){
			this.dim = null;
			$("#actionDim").blur()
		}
	}
	function removeIncludedAccessories(v) {
		_.each($scope.design.stack.accessoryMetas, function(am) {
			if (am.GUIidentPath == v.GUIidentPath) {
				am.product = null
			}
		})

		var accessories = Util.getSubtypes("Accessory", $scope.allProductSubtypes, $scope.design)
		accessories = _.filter(accessories, function(productSubtype){
			return productSubtype.activeProducts
		})
		_.filter(accessories, function(subtype){
			_.each($scope.design.stack.accessoryMetas, function(ac){
				if (ac.GUIidentPath == v.GUIidentPath) {
					ac.quantity = 0
				}
			})
		})
	}
	$scope.sectionManager = {
		onDeleted:function(items) {
			_.each(items, function(v) {
				if (hasIncludedAccessories(v)) {
					console.log('remove included', v)
					removeIncludedAccessories(v)
				}
				if (hasExcludedAccessories(v)) {
					ExcludeMetaUtil.removeExcludeMeta(v, $scope.design)
				}
			})
		},
		onCreated: function(v) {
			console.log("sectionManager.onCreated", v.ID, v.GUIidentPath)
			var inlets = Util.getInlets(v, $scope.design)
			var outlet = Util.getOutlet(v, $scope.design)
			var from = null
			var to = null
			if (outlet) {
				//drawing into
				to = outlet
				from = v
			} else {
				//drawing from
				to = v
				if (inlets && inlets.length == 1){
					from = inlets[0]
				}
			}
			var fittingMeta = FittingMetaUtil.getFittingMeta(to, $scope.design, $scope.fittings, "oncreated")
		},
		onChanged: function(items) {
			console.log("sectionManager.onChanged", _.pluck(items, "ID"), _.pluck(items, "GUIident"))
		}
	}
	$scope._transformGUIident = function(object, design, params) {
		var v = _.find(design.fc5layout.Layout, function(v){
			return v.ID == object.ID && v.ID
		})
		if (v) {
			if (object.GUIident != v.GUIident) {
				object.GUIident = v.GUIident
				// console.log("set GUIident for "+object.ID + " = "+object.GUIident, object)
			}
			if (object.GUIidentPath != v.systemId + "." + v.GUIident) {
				object.GUIidentPath = v.systemId + "." + v.GUIident
			}
		}
	}
	$scope._transformGUIidentOwned = function(object, design, params) {
		var v = _.find(design.fc5layout.Layout, function(v){
			return v.ID == object.ownedID && v.ID
		})
		if (v) {
			if (object.ownedGUIident != v.GUIident) {
				object.ownedGUIident = v.GUIident
				// console.log("set ownedGUIident for "+object.ownedID + " = "+object.ownedGUIident, object)
			}
			if (object.ownedGUIidentPath != v.systemId + "." + v.GUIident) {
				object.ownedGUIidentPath = v.systemId + "." + v.GUIident
			}
		}
	}
	$scope._transformGUIidentCenterpoints = function(_object, design, params) {
		_.each(["centerPointA", "centerPointB", "centerPointC", "centerPointD"], function(name){
			var object = _object[name]
			if (object) {
				$scope._transformGUIident(object, design, params)
			}
		})
	}
	$scope._transformIdCenterpoints = function(_object, design, params) {
		_.each(["centerPointA", "centerPointB", "centerPointC", "centerPointD"], function(name){
			var object = _object[name]
			$scope._transformId(object, design, params)
		})
	}
	$scope._transformIdOwned = function(object, design, params) {
		if (object.ownedGUIidentPath && !object.ownedID) {
			var v = _.find(design.fc5layout.Layout, function(v){
				return v.GUIidentPath == object.ownedGUIidentPath && v.GUIidentPath
			})
			if (v) {
				if (object.ownedID != v.ID) {
					object.ownedID = v.ID
					// console.log("set id for "+object.ownedGUIident + " = "+object.ownedID, object)
				}
			}
		}
	}
	$scope._transformId = function(object, design, params) {
		var force = params && params.force;
		if (!object) {
			return
		}
		if (object.GUIidentPath && (!object.ID || force)) {
			var v = _.find(design.fc5layout.Layout, function(v){
				return v.GUIidentPath == object.GUIidentPath && v.GUIidentPath
			})
			if (v) {
				if (object.ID != v.ID) {
					object.ID = v.ID
					// console.log("set id for "+object.GUIident + " = "+object.ID, object)
				}
			}
		}
	}
	$scope._transforms= function(objects, design, f, params) {
		_.each(objects, function(object){
			f(object, design, params)
		})
	}
	$scope.transformGUIidents = function(design, params) {
		$scope._transforms(design.stack.accessoryMetas, design, $scope._transformGUIident, params)
		$scope._transforms(design.stack.excludeMetas, design, $scope._transformGUIident, params)
		$scope._transforms(design.stack.fittingMetas, design, $scope._transformGUIident, params)
		$scope._transforms(design.stack.fittingMetas, design, $scope._transformGUIidentCenterpoints, params)
		$scope._transforms(design.stack.sectionMetas, design, $scope._transformGUIident, params)
		$scope._transforms(design.stack.sectionMetas, design, $scope._transformGUIidentOwned, params)
		$scope._transforms(design.stack.selectionMetas, design, $scope._transformGUIident, params)
	}
	$scope.transformIds = function(design, params) {
		$scope._transforms(design.stack.accessoryMetas, design, $scope._transformId, params)
		$scope._transforms(design.stack.excludeMetas, design, $scope._transformId, params)
		$scope._transforms(design.stack.fittingMetas, design, $scope._transformId, params)
		$scope._transforms(design.stack.fittingMetas, design, $scope._transformIdCenterpoints, params)
		$scope._transforms(design.stack.sectionMetas, design, $scope._transformId, params)
		$scope._transforms(design.stack.sectionMetas, design, $scope._transformIdOwned, params)
		$scope._transforms(design.stack.selectionMetas, design, $scope._transformId, params)
	}
	$scope.selectionManager = {
		selectVent: function(v) {
			if (!v.Selected){
				v.Selected = true;
				this.updateWindow();
			}
		},
		selectPan: function(vs, bottomLeftToTopRight) {
			var self = this;
			_.each(vs,function(v){
				self.selectVent(v)
				if (!bottomLeftToTopRight && v.Appl){
					self.selectAppliance(v)
				}
			})
			this.updateWindow()
		},
		unselectVent: function(v) {
			if (!!v.Selected){
				v.Selected = false;
				this.updateWindow();
			}
		},
		selectAppliance: function(v) {
			if (!v.Appl.Selected){
				v.Appl.Selected = true;
				this.updateWindow();
			}
		},
		unselectAppliance: function(v) {
			if (!!v.Appl.Selected){
				v.Appl.Selected = false;		
				this.updateWindow();
			}
		},
		toggleAppliance: function(v){
			if (!v.Appl.Selected){
				this.selectAppliance(v)
				$scope.orderedAppliances.push(v)
			} else {
				this.unselectAppliance(v)
				$scope.orderedAppliances = $scope.orderedAppliances.filter(function(item) {
					return item.ID !== v.ID
				});
			}
		},
		toggleVent: function(v){
			if (!v.Selected){
				this.selectVent(v)
			} else {
				this.unselectVent(v)
			}
		},
		selectedVents: function() {
			if ($scope.design && $scope.design.fc5layout) {
				return _.filter($scope.design.fc5layout.Layout, function(v){
					return v.Selected
				})
			} else {
				return []
			}
		},
		selectedAppliances: function() {
			return _.filter($scope.design.fc5layout.Layout, function(v){
				if (v.Appl && v.Appl.Selected) {
					return v.Fit1 == "STP"
				}
			})
		},
		selectedJoints: function() {
			return _.filter($scope.design.fc5layout.Layout, function(v){
				if (v.Appl && v.Appl.Selected) {
					return v.Fit1 != "STP"
				}
			})
		},
		updatedVents:function() {
			this.updateWindow();
		},
		updateWindow:function() {
			var statuses = [];
			var selectedVents = this.selectedVents()

			if (selectedVents.length == 0){
				// status = status+ "Sections: 0"
			} else {
				var ventStatus = "Sections:"+_.pluck(selectedVents, "GUIident")
				// var ventStatus = "Sections:"+_.pluck(selectedVents, "GUIidentAlt")
				if (selectedVents.length == 1){
					var v = selectedVents[0];
					// status = status+"Direction:" + this.direction() + ",X1:"+v.X1+",X2:"+v.X2+",Y1:"+v.Y1+",Y2:"+v.Y2+")";
					if (v.VentType) {
						ventStatus = ventStatus + " Material:"+v.VentType
					}
					ventStatus = ventStatus + " Optimize:"+v.Optimize
					if (v.Optimize == 'No'){
						if (v.Shape == 'ROU' || v.Shape == "SQU"){
							ventStatus = ventStatus + " Diameter:"+v.Dim1
						} else {
							ventStatus = ventStatus + " Dim:"+v.Dim1 + "x" + v.Dim2
						}

					} else {
						if (v.result) {
							if (v.Shape == "ROU" || v.Shape == "SQU"){
								ventStatus = ventStatus + " Diameter:"+v.result.Diameter
							} else {
								ventStatus = ventStatus + " Dim:"+v.result.Dim1 + "x" + v.result.Dim2
							}
						}
					}
					if (v.Shape) {
						ventStatus = ventStatus + " Shape:"+v.Shape
					}
					if (v.Fit1) {
						ventStatus = ventStatus + " Fit1:"+v.Fit1
					}
					if (v.Fit2) {
						ventStatus = ventStatus + " Fit2:"+v.Fit2
					}
				}
				statuses.push(ventStatus)
			}

			var selectedAppliances = this.selectedAppliances()
			if (selectedAppliances.length == 0){
				// status = status+ " Appliances: 0"
			} else {
				var applianceStatus = "Appliances: "+_.pluck(_.pluck(selectedAppliances, "Appl"), "GUIident")
				statuses.push(applianceStatus)
			}
			var selectedJoints = this.selectedJoints()
			if (selectedJoints.length == 0){
				// status = status+ " Selected Fittings: 0"
			} else {
				var names = _.map(selectedJoints, function(v){
					return v.GUIident + "." + v.Fit1
				})
				var fittingStatus = "Selected Fittings: "+names.join(",")
				statuses.push(fittingStatus)
			}
			if (selectedVents.length == 1 && selectedAppliances.length == 0 && selectedJoints.length == 0){
				$scope.actionContext.setVent(selectedVents[0])
			} else {
				$scope.actionContext.unsetVent()
			}
			$scope.selectionManager.status = statuses.join(" | ")
			// console.log(statuses.join(" | "))
		},
		selectAllAppliances:function() {
			this.unselectAll(true);
			_.each($scope.design.fc5layout.Layout, function(v){
				if (v && v.Appl && v.Fit1 == "STP"){
					v.Appl.Selected = true;
				}
			})
			this.updateWindow()
		},
		selectAllVents:function() {
			this.unselectAll(true);
			_.each($scope.design.fc5layout.Layout, function(v){
				v.Selected = true;
			})
			this.updateWindow()
		},
		selectAllJoints:function() {
			this.unselectAll(true);
			_.each($scope.design.fc5layout.Layout, function(v){
				if (v && v.Fit1 != "STP"){
					if (!v.Appl){
						v.Appl = Util.createAppliance($scope.design)
					}
					v.Appl.Selected = true;
				}
			})
			this.updateWindow()
		},
		unselectAll:function(silent){
			var self = this;
			var changed = false;
			$scope.orderedSelection = []
			$scope.orderedAppliances = []
			_.each($scope.design.fc5layout.Layout, function(v) {
				if (v.Selected){
					v.Selected = false;
					changed = true;
				}
				if (v.Appl && v.Appl.Selected){
					changed = true;
					v.Appl.Selected = false
				}
			})
			if (changed && !silent) {
				this.updateWindow()
			}
		},
		_connections:function(v){
			var inlets = [];
			var endsIn = [];
			_.each($scope.design.fc5layout.Layout, function(vv) {
				if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) {
					endsIn.push(vv);
				}
			})
			_.each($scope.design.fc5layout.Layout, function(vv) {
				if (vv.X2 == v.X1 && vv.Y2 == v.Y1 && vv.Z2 == v.Z1) {
					inlets.push(vv);
				}
			})
			return {
				inlets: inlets,
				endsIn: endsIn
			}
		},
		nextVent:function() {
			var selected = this.selectedVents()
			if (selected.length == 1){
				var GUIident = parseInt(selected[0].GUIident)
				var systemId = parseInt(selected[0].systemId)

				return _.filter($scope.design.fc5layout.Layout,function(v){
					return parseInt(v.GUIident) == (GUIident +1) && v.systemId == systemId
				})
				// var conns = this._connections(selected[0])
				// return conns.endsIn
				// if (conns.endsIn && conns.endsIn.length == 1){
				// 	return conns.endsIn[0]
				// }
			} else {
				return []
			}
		},
		previousVent:function(){
			var selected = this.selectedVents()
			if (selected.length == 1){
				// var conns = this._connections(selected[0])
				// return conns.inlets
				var GUIident = parseInt(selected[0].GUIident)
				var systemId = parseInt(selected[0].systemId)
				return _.filter($scope.design.fc5layout.Layout,function(v){
					return parseInt(v.GUIident) == (GUIident -1) && v.systemId == systemId
				})
				// if (conns.inlets && conns.inlets.length == 1){
				// 	return conns.inlets[0]
				// }
			} else {
				return []
			}
		},
		selectJoint:function(v){
			if (!v.Appl){
				v.Appl = Util.createAppliance($scope.design)
			}
			if (!v.Appl.Selected){
				v.Appl.Selected = true
				this.updateWindow()
			}
		},
		nextJoint:function() {
			var selected = this.selectedJoints()
			if (selected.length == 1){
				var GUIident = parseInt(selected[0].GUIident)
				var sorted = _.sortBy($scope.design.fc5layout.Layout, function(v){
					return parseInt(v.GUIident)
				})
				var next = _.find(sorted,function(v){
					return parseInt(v.GUIident) >= (GUIident +1) && v.Fit1 != "STP"
				})
				return (next) ? [next]: [];
			} else {
				return []
			}
		},
		previousJoint:function(){
			var selected = this.selectedJoints()
			if (selected.length == 1){
				var GUIident = parseInt(selected[0].GUIident)
				var sorted = _.sortBy($scope.design.fc5layout.Layout, function(v){
					return parseInt(v.GUIident)*-1
				})
				var prev = _.find(sorted,function(v){
					return parseInt(v.GUIident) <= (GUIident -1) && v.Fit1 != "STP"
				})
				return (prev) ? [prev]: [];
			} else {
				return []
			}
		},
		direction:function() {
			var selectedVents = this.selectedVents();
			if (selectedVents.length != 1) {
				return
			}
			var v = selectedVents[0];
			return Util.direction(v, $scope.design)
		},
		nextAppliance:function() {
			var selected = this.selectedAppliances()
			if (selected.length == 1){
				var GUIident = parseInt(selected[0].Appl.GUIident)
				return _.filter($scope.design.fc5layout.Layout,function(v){
					if (v.Appl){
						return parseInt(v.Appl.GUIident) == (GUIident +1)
					}
				})
			} else {
				return []
			}
		},
		previousAppliance:function() {
			var selected = this.selectedAppliances()
			if (selected.length == 1){
				var GUIident = parseInt(selected[0].Appl.GUIident)
				return _.filter($scope.design.fc5layout.Layout,function(v){
					if (v.Appl){
						return parseInt(v.Appl.GUIident) == (GUIident -1)
					}
				})
			} else {
				return []
			}
		},
		hasSingleSectionSelected: function() {
			var result = this.getSelected()
			return result.vents.length == 1 && !result.hasMixed;
		},
		getSelected:function() {
			var result = {
				appliances: this.selectedAppliances(),
				joints: this.selectedJoints(),
				vents: this.selectedVents()
			}
			var types = 0;
			if (result.appliances.length > 0) {
				types = types +1;
			}
			if (result.joints.length > 0) {
				types = types +1;
			}
			if (result.vents.length > 0) {
				types = types +1;
			}
			result.hasMixed = types > 1;
			result.empty = types == 0
			return result;
		}
	}
	$scope.modsManager = {
		summaries:[],
		setMODS: function(_setProps) {
			var needMODS = $scope.configs.needMODSLegacy == "true" ? $scope.modsManager._needMODSLegacy() : $scope.modsManager._needMODS()
			var col = [];
			if (needMODS == true) {
				col.push({
					text: "Yes",
					value: true
				})
				col.push({
					text: "No - Not recommended",
					value: false
				})
			} else {
				col.push({
					text: "No - Not required",
					value: false
				})
				col.push({
					text: "Yes",
					value: true
				})
			}

			$scope.cmbDamper.options = col;
			var existing = _.find($scope.cmbDamper.options, function(opt) {
				return opt.value === $scope.design.damper;
			})
			if (_overRide(_setProps) || !existing) {
				$scope.design.damper = $scope.cmbDamper.options[0].value;
			}

			var existingModType = _.find($scope.modTypes, function(o){
				return o._id == $scope.design.modType;
			})
			if (_overRide(_setProps) || !existingModType) {
				$scope.design.modType = $scope.modTypes[0]._id;
			}
		},
		_needMODS:function() {
			try {
				var appliances = Util.getApplianceSections($scope.design)
				$scope.modsManager.summaries = [];
				var needV = _.find(appliances, function(appliance){
					var PresL = parseFloat(appliance.Appl.PresL);
					// var statLossGrav = _.find($scope.design.calcResult.PartialGrav, function(stat){
					// 	if (stat.LossMin < PresL) {
					// 		var loss = stat.LossMin
					// 		$scope.modsManager.summaries.push({
					// 			message: "MODS has been selected because the system draft (" + loss + ") exceeds the appliance minimum draft requirements (" + PresL + ") allowed for Appliance " + appliance.Appl.GUIident,
					// 			messageType: "info"
					// 		})
					// 		return true
					// 	}else if (stat.LossStd < PresL){
					// 		var loss = stat.LossStd
					// 		$scope.modsManager.summaries.push({
					// 			message: "MODS has been selected because the system draft (" + loss + ") exceeds the appliance minimum draft requirements (" + PresL + ") allowed for Appliance " + appliance.Appl.GUIident,
					// 			messageType: "info"
					// 		})
					// 		return true
					// 	} else if (stat.LossMax < PresL) {
					// 		var loss = stat.LossMax
					// 		$scope.modsManager.summaries.push({
					// 			message: "MODS has been selected because the system draft (" + loss + ") exceeds the appliance minimum draft requirements (" + PresL + ") allowed for Appliance " + appliance.Appl.GUIident,
					// 			messageType: "info"
					// 		})
					// 		return true
					// 	}
					// });
					// if (statLossGrav) {
					// 	return true;
					// }
					var statLossMech = _.find($scope.design.calcResult.PartialMech, function(stat){
						if (stat.LossMin < PresL) {
							var loss = stat.LossMin
							$scope.modsManager.summaries.push({
								message: "MODS has been selected because the system draft (" + loss + ") exceeds the appliance minimum draft requirements (" + PresL + ") allowed for Appliance " + appliance.Appl.GUIident,
								messageType: "info"
							})
							return true
						}else if (stat.LossStd < PresL){
							var loss = stat.LossStd
							$scope.modsManager.summaries.push({
								message: "MODS has been selected because the system draft (" + loss + ") exceeds the appliance minimum draft requirements (" + PresL + ") allowed for Appliance " + appliance.Appl.GUIident,
								messageType: "info"
							})
							return true
						} else if (stat.LossMax < PresL) {
							var loss = stat.LossMax
							$scope.modsManager.summaries.push({
								message: "MODS has been selected because the system draft (" + loss + ") exceeds the appliance minimum draft requirements (" + PresL + ") allowed for Appliance " + appliance.Appl.GUIident,
								messageType: "info"
							})
							return true
						}
					});
					if (statLossMech) {
						return true;
					} else {
						return false
					}
				})
				return (needV) ? true : false
			}catch(e){
				console.log("error in _needMODS",e)
				return false
			}
		},
		_needMODSLegacy:function() {
			var needMODS = false;
			var FanMin = 0;

			if (getTotalHeight() > 50) {
				needMODS = true;
			}
			var fan = $scope.design.exhFan;
			_.each($scope.design.calcResult.ExhFans, function(exhFan) {
				if (exhFan.Name === fan) {
					FanMin = exhFan.MinVol
				}
			})

			if (FanMin > $scope.design.calcResult.ExhVol) {
				var found = _.find($scope.design.calcResult.PartialMech, function(pl) {
					return (($scope.design.units !== "SI" && Util._float(pl.LossMin) < -0.2) || ($scope.design.units == "SI" && Util._float(pl.LossMin) < -50));
				});
				needMODS = (found) ? true : false
			}
			return needMODS;
		}
	}
	var imageLoader = {
		images: {},
		// convertedImages: {},
		load:function(url, loader){
			if (this.images[url]){
				return this.images[url]
			} else {
				// console.log('loading '+url)
				this.images[url] = loader.addImage(url);
			}
		},
		get:function(url){
			if (this.images[url]){
				return this.images[url]
			} else {
				// var img = this.images[url]
				// if (img) {
				// 	// img.crossOrigin = "Anonymous"
				// 	this.convertedImages[url] = img
				// 	// this.convertedImages[url] = img.toDataURL()
				// 	return this.convertedImages[url]
				// }
				// if (img) {
				// 	this.convertedImages[url] = url.toDataURL()
				// }
			}
		}
	}
	$scope.selectEconomizer = function() {
		Util.updateEconomizer($scope.design, $scope.economizers)
		//now compute
		$scope.calculate()
	}
	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	}).$promise.then(function(job){
		$scope.job = job;
		$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs)
	});
	$scope.fans = []
	$scope.fanName = function(code) {
		var fan = _.find($scope.fans, function(fan){
			var fanCode = (fan.Quantity ==1) ? fan.code : fan.code+"-"+fan.Quantity;
			return fanCode == code;
		})
		return (fan) ? fan.name : code
	}
	Fan.query({limit:3000}).$promise.then(function(fans){
		$scope.fans = fans;
	})
	$scope.getInlinFanProduct = function(code) {
		var fan = _.find($scope.fans, function(fan) {
			return fan.code == code
		})
		if (!fan) {
			return null
		}
		return _.find($scope.allInlineFans, function(o){
			return o.fan == fan._id
		})
	}
	$scope.controls = Control.query()
	$scope.applianceCategories = ApplianceCategory.query();
	$scope.promoteVersion = function() {
		Design.promoteVersion({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: $stateParams.designId,
			version: $stateParams.version
		}).$promise.then(function() {
			$state.go('drawing', {
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				designId: $stateParams.designId,
			}, {
				reload: true,
				inherit: false
			})
		})
	}
	$scope.createClone = function() {
		$state.go("design", {
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: "new",
			copyVersion: $scope.design.version,
			copyDesignId: $scope.design._id
		}, {
			reload: true,
			inherit: false
		})
	}
	function getApplianceCompanies() {
		ApplianceCompany.query({
			active: true,
			limit: 300,
			applications: $scope.design.application._id
		}).$promise.then(function(result) {
			$scope.cmbManuOptions = result;
		});
	}
	function getModels(applianceCompany) {
		ApplianceModel.query({
			active: true,
			applianceCompany: applianceCompany._id,
			applications: $scope.design.application._id,
			limit: 1000
		}).$promise.then(function(result) {
			$scope.cmbModelOptions = result
		})
	}
	$scope.fanvoltages = FanVoltage.query({});
	$scope.returnToBOM = function(design) {
		$state.go('design', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			designId: $scope.designId,
		}, {
			reload: false,
			inherit: false
		});
	}
	$scope.showPerspectiveMenu = function() {
		if ($scope.perspectiveMenu === false) {
			$scope.perspectiveMenu = true;
		} else {
			$scope.perspectiveMenu = false;
		}
	}
	$scope.sectionByGUIident = function(GUIident) {
		var section = _.find($scope.design.fc5layout.Layout, function(section) {
			return section.GUIident == GUIident
		})
		return section;
	}
	$scope.sectionLength = function(GUIident) {
		var section = $scope.sectionByGUIident(GUIident)
		return section.DimX + section.DimY + section.DimZ
	}
	$scope.sectionGUIident = function(sectionId) {
		var section = _.find($scope.design.fc5layout.Layout, function(section) {
			return section.ID == sectionId
		})
		var result = (section) ? section.GUIident : '';
		return result;
	}
	$scope._addEventListenerMouseup = function(e){
		if ($scope.addEventListenerMouseup) {
			if ($scope.addEventListenerMouseup(e)) {
				return true
			}
		}
	}
	function loadJquery() {
		$scope.$on("$destroy", function() {
			$scope.destroyed = true;
			if ($scope.design.application.computeAs != 'COM') {
				$("#myCanvans").off("touchstart")
				$("#myCanvans").off("touchmove")
				$("#myCanvans").off("touchend")
				$("#myCanvans").off("mousedown")
				$("#myCanvans").off("mouseup")
				$("#myCanvans").off("mousemove")
				$("#myCanvans").off("DOMMouseScroll")
				$("#myCanvans").off("mousewheel")
				$(document).off("keyup")
				$(document).off("keydown")
			}
			CommandManager.setStorage("design-"+$scope.design._id, angular.toJson($scope.design), false)
			window.onresize = null;
			window.onbeforeunload = null
			// console.log("cleared interval")
			clearInterval(saveInterval);
		});
		$(document).ready(function() {
			if ($scope.design.application.computeAs != 'COM') {
				document.getElementById("myCanvas").addEventListener("DOMMouseScroll", wheel, false);
				document.getElementById("myCanvas").addEventListener("mousewheel", wheel, false);
				$('#myCanvas').bind('touchstart', function(e) {
					conosole.log("touchstart")
					e.preventDefault();
					var can = document.getElementById("myCanvas");
					var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
					mPoint.X = touch.pageX - CanvasOffset.X;
					mPoint.Y = touch.pageY - CanvasOffset.Y;
					switch ($scope.design.fc5layout.ViewAngle) {
						case "TopRight":
						case "TopLeft":
							DrawPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
							DrawPoint.Y = Math.round(Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev) / (50 * Tan30)) * (50 * Tan30));
							SelPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev));
							SelPoint.Y = Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev));
							break;
						default:
							DrawPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
							DrawPoint.Y = Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
							SelPoint.X = ((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev);
							SelPoint.Y = ((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev);
					}
					MouseDown(touch.pageX, touch.pageY, 0, "touch");
				});
				$('#myCanvas').bind('touchmove', function(e) {
					e.preventDefault();
					var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
					try {
						MouseMove(touch.pageX, touch.pageY, 0, "touch");
					}catch(e){
						console.log("MouseMove Error", e)
					}
				});

				$('#myCanvas').bind('touchend', function(e) {
					e.preventDefault();
					var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
					if ($scope._addEventListenerMouseup(e)) {
						return
					}
					$scope.MouseUp(touch.pageX, touch.pageY, 0, false, false, "touch");
				});
				// add click listener to canvas
				$('#myCanvas').mousedown(function(e) {
					e.preventDefault;
					MouseDown(e.pageX, e.pageY, e.button, "mouse");
					// console.log("mousedown SelBoxStart, e", SelBoxStart, {pageX:e.pageX, pageY: e.pageY})
				});
				$('#myCanvas').mouseup(function(e) {
					e.preventDefault;
					if ($scope._addEventListenerMouseup(e)) {
						return
					}
					// console.log("mouseup SelBoxStart, e", SelBoxStart, {pageX:e.pageX, pageY: e.pageY})
					$scope.MouseUp(e.pageX, e.pageY, e.button, e.ctrlKey, e.shiftKey, "mouse");
				});
				$('#myCanvas').mousemove(function(e) {
					e.preventDefault;
					try {
						MouseMove(e.pageX, e.pageY, e.button, "mouse");
					}catch(e){
						console.log("MouseMove Error", e)
					}
				});
				$('#myCanvas').dblclick(function(e){
					e.preventDefault;
					if ($scope._addEventListenerMouseup(e)) {
						return
					}
					$scope.selectionManager.unselectAll();
					IsMoving = false;
					SelBoxStart = undefined;
					MoveHandlers = [];
					actDoubleClick = true;
					$scope.MouseUp(e.pageX, e.pageY, e.button, e.ctrlKey, e.shiftKey, "dblclick");
				})

				$(document).on("keyup", function(e) {
					if ($scope.destroyed || $scope.modalOpen) {
						return
					}

					if (e.keyCode == 84) {
						if ($scope.drawMode) {
							$scope.ClickMenu("SEL")
						} else {
							$scope.ClickMenu("DRAW")
						}
						return
					}

					if (e.keyCode == 27) {
						$scope.ClickMenu("SEL");
						$scope.selectMode = true;
						$scope.drawMode = false;
						$scope.$apply();
						// SelBoxStart = undefined
						$scope.MouseUp(0, 0, 0, false, false, "mouse")
					}
					if (e.keyCode == 8 || e.keyCode == 46) {
						if ($scope.DrawMode == "DRAW"){
							// console.log("ignoring delete while drawing")
							return;
						} else if ($scope.actionContext.trapped){
							// console.log("ignoring delete while trapped")
							return;
						}
						$scope.ClickMenu("DELETE");
						$scope.selectMode = true;
						$scope.drawMode = false;

						$scope.MouseUp(0, 0, 0, false, false, "mouse")
					}
					if (e.keyCode == 17 || e.keyCode == 91) {
						ctrlDown = false;
					}

					if (e.keyCode === 32) {
						var selected = $scope.selectionManager.getSelected()
						if (selected.appliances.length > 0 || selected.vents.length > 0 || selected.joints.length > 0) {
							openSelectionModal()
						}
					}



					// move selection with arrow keys
					_.each($scope.design.fc5layout.Layout, function(v) {
						if (v.Selected == true) {
							if (e.keyCode == 37) {
								//left
								v.X1 = v.X1 - 50
								v.X2 = v.X2 - 50
								$scope.sectionManager.onChanged([v])
							}
							if (e.keyCode == 38) {
								//up
								v.Y1 = v.Y1 - 50
								v.Y2 = v.Y2 - 50
								$scope.sectionManager.onChanged([v])
							}
							if (e.keyCode == 39){
								//right
								v.X1 = v.X1 + 50
								v.X2 = v.X2 + 50
								$scope.sectionManager.onChanged([v])
							}
							if (e.keyCode == 40) {
								//down
								v.Y1 = v.Y1 + 50
								v.Y2 = v.Y2 + 50
								$scope.sectionManager.onChanged([v])
							}
						}
					})
					SetSameFittings();
					autoFit();
					paint()


				});
				$(document).on("keydown", function(e) {
					if ($scope.detroyed || $scope.modalOpen) {
						return
					}
					if (e.keyCode == 17 || e.keyCode == 91) {
						ctrlDown = true;
					}
					if (ctrlDown === true) {
						if (e.keyCode == 86) {

							// revert to select tool
							$scope.ClickMenu("PASTE");
							$scope.selectMode = true;
							$scope.drawMode = false;

							// deselect
							// $scope.MouseUp(0, 0, 0, false, false, "mouse")
						}

						if (e.keyCode == 67) {

							// revert to select tool
							$scope.ClickMenu("COPY");
							$scope.selectMode = true;
							$scope.drawMode = false;

							// deselect
							$scope.MouseUp(0, 0, 0, false, false, "mouse")
						}

						if (e.keyCode == 88) {

							// revert to select tool
							$scope.ClickMenu("CUT");
							$scope.selectMode = true;
							$scope.drawMode = false;

							// deselect
							$scope.MouseUp(0, 0, 0, false, false, "mouse")
						}
					}
				});
			}
			window.onresize = function() {
				setDims()
				if ($scope.design.application.computeAs != "COM"){
					paint();
				}
			}
			window.onbeforeunload = function() {
				if (Edited() == true) {
					return "You are about to leave the page. The changes you made will not be saved if you do. Please click the Save button before exiting.";
				}
			};
			setDims()
		});
	}

	$scope.updateQuoteDoc = function() {
		Quote.updateVersions({
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			designId: $scope.design._id,
			version: $scope.design.version
		}, {}).$promise.then(function(quotes) {
			_.each(quotes, function(quote) {
				try {
					Document.updateVersions({
						accountId: $scope.accountId,
						jobId: $scope.jobId,
						quoteId: quote._id,
						version: quote.version
					}, {}).$promise.then(function(result) {
						// console.log("updated")
					}).catch(function(err) {
						console.log("err", err)
					})
				} catch (e) {
					// console.log("err", e)
				}
			})
			checkQuotes();
		})
	}
	$scope.updateCreateQuote = function() {
		if ($scope.existingQuote) {
			$scope.updateQuote()
		} else {
			$scope.createQuote()
		}
	}
	$scope.updateQuote = function() {
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			quoteId: $scope.existingQuote._id,
			designId: $scope.designId,
			mode: "edit"
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.createQuote = function() {
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			quoteId: "new",
			designId: $scope.designId,
			mode: "edit"
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.viewQuote = function() {
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			quoteId: $scope.existingQuote._id,
			designId: $scope.designId,
		}, {
			reload: true,
			inherit: false
		});
	}


	function checkQuoteDoc() {
		Quote.hasUpdatableVersions({
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			designId: $scope.design._id,
			version: $scope.design.version
		}).$promise.then(function(quotes) {
			$scope.canUpdateVersion = (quotes.length > 0)
		})
	}
	function getStrDenom() {
		if ($scope.design.application.computeAs == "DRY") {
			if ($scope.design.units == "IP") {
				return '"'
			} else {
				return ' m'
			}
		} else if ($scope.design.application.computeAs == "BWH") {
			if ($scope.design.units == "SI") {
				return  ' m';
			} else {
				return  '"'
			}
		}
	}

	function unselectedLayout(design) {
		if ($scope.design.fc5layout && $scope.design.fc5layout.Layout) {
			var unselectedlayout = _.map($scope.design.fc5layout.Layout, function(v) {
				var item = _.cloneDeep(v);
				delete item.Selected
				if (item.Appl) {
					delete item.Appl.Selected
				}
				return item;
			});
			var fc5layout = _.defaults({
				Layout: unselectedlayout
			}, $scope.design.fc5layout)

			return _.defaults({
				fc5layout: fc5layout
			}, $scope.design)
		} else {
			return $scope.design
		}
	}

	function checkPoint() {
		if ($scope.design.application.computeAs != 'COM') {
			if ($scope.design.fc5layout && $scope.design.fc5layout.Layout) {
				_cleanSections($scope.design.fc5layout.Layout);
			}
		}
		updateMethodText()
		return unselectedLayout();
	}

	function updateMethodText() {
		var method = _.find($scope.cmbIntMethod.options, function(option) {
			return option.value === ($scope.design.fc5layout && $scope.design.fc5layout.Intake && $scope.design.fc5layout.Intake.Method)
		})
		if (method) {
			$scope.design.fc5layout.Intake.MethodText = method.text
		}
	}

	function Edited(deselect) {
		if (deselect) {
			$scope.selectionManager.unselectAll()
		}
		var current = checkPoint();
		var currentJson = angular.toJson(current);
		var result = currentJson != LastSave;
		return result
	}

	function _LoadFunctions() {
		_.each($scope.design.fc5layout.Layout, function(section) {
			if (!section.X) {
				Util.LoadFunctions(section)
			}
		})
	}

	function IntakeData() {
		return {
			Include: ($scope.design.application.computeAs != "DRY") ? true : false,
			Ducting:"NON",
			DiaSel:"MAN",
			Dia1:0,
			Dia2:0,
			Length:0,
			L90:0,
			L45:0,
			AddPres:0,
			Heater:"NO",
			Method:"NORM",
			Volume: 0
		}
	}

	function getSelected() {
		return _.find($scope.design.fc5layout.Layout, function(sv) {
			return sv.Selected == true
		})
	}

	function getSelectedCol() {
		return $scope.selectionManager.selectedVents();
	}

	function getSelectedNo() {
		var vents = $scope.selectionManager.selectedVents()
		return (vents) ? vents.length : 0;
	}

	function isTouchDevice() {
		return (typeof(window.ontouchstart) != 'undefined') ? true : false;
	}

	function SetSameFittings() {
		_.each($scope.design.fc5layout.Layout, function(v) {
			_.each($scope.design.fc5layout.Layout, function(vv) {
				if (v.ID != vv.ID) {
					if (v.X1 == vv.X1 && v.Y1 == vv.Y1 && v.Z1 == vv.Z1 && v.Fit1 != vv.Fit1 && vv.Fit1 != "") {
						if (vv.Fit1 != "TER" && vv.Fit1 != "STP") {
							v.Fit1 = vv.Fit1
						} else {
							v.Fit1 = "";
							vv.Fit1 = "";
						}
					}
					if (v.X1 == vv.X2 && v.Y1 == vv.Y2 && v.Z1 == vv.Z2 && v.Fit1 != vv.Fit2 && vv.Fit2 != "") {
						if (vv.Fit2 != "TER" && vv.Fit2 != "STP") {
							v.Fit1 = vv.Fit2
						} else {
							v.Fit1 = "";
							vv.Fit2 = "";
						}
					}
					if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1 && v.Fit2 != vv.Fit1 && vv.Fit1 != "") {
						if (vv.Fit1 != "TER" && vv.Fit1 != "STP") {
							v.Fit2 = vv.Fit1
						} else {
							v.Fit2 = "";
							vv.Fit1 = "";
						}
					}
					if (v.X2 == vv.X2 && v.Y2 == vv.Y2 && v.Z2 == vv.Z2 && v.Fit2 != vv.Fit2 && vv.Fit2 != "") {
						if (vv.Fit2 != "TER" && vv.Fit2 != "STP") {
							v.Fit2 = vv.Fit2
						} else {
							v.Fit2 = "";
							vv.Fit2 = "";
						}
					}
				}
			})
		})
	}

	function setValue(prop, value, typ, selectedItem, fromFlipSection) {
		// console.log("setValue()", prop, value, selectedItem.GUIident)
		$scope.dirty = true;
		DoUndo();

		var changed = null
		if (!selectedItem) {
			selectedItem = {};
		} else {
			changed = [selectedItem]
		}

		_.each($scope.design.fc5layout.Layout, function(v) {
			if (prop == "Fit2") {
				if (v === selectedItem) {
					// v.Selected = true;
					// console.log("selecting vent")
					$scope.selectionManager.selectVent(v)
				}
			}
			if (prop == "multi") {
				if (v === selectedItem) {
					// console.log("selecting vent")
					$scope.selectionManager.selectVent(v)
					// v.Selected = true;
				}
			}
			if (v.Selected == true ) {
				if (value == "STP") {
					prop = "Fit1"
				} else {
					if (prop == "Fit1") {
						if (Util.Ais1(v, $scope.design) == false) {
							prop = "Fit2"
						}
					} else if (prop == "Fit2") {
						if (Util.Ais1(v, $scope.design) == false) {
							prop = "Fit1"
						}
					}
					//If STP or TER flip
					if (!fromFlipSection) {
						if (prop == "Fit1" && value == "TER") {
							FlipSection(v);
							prop = "Fit2"
						}
						if (prop == "Fit2" && value == "STP") {
							FlipSection(v);
							prop = "Fit1"
						}
					}
					//If Inline Fan remove termination
					if ((prop == "Fit1" || prop=="Fit2") && (value == "INL" || value == "ECO")) {
						_.each($scope.design.fc5layout.Layout, function(vvv) {
							vvv.TermFan = undefined;
							if (vvv.Fit1 == "INL" || vvv.Fit1 == "ECO") {
								vvv.Fit1 = ""
							};
							if (vvv.Fit2 == "INL" || vvv.Fit2 == "ECO") {
								vvv.Fit2 = ""
							};
						})
					}
				}
				var oldValue = v[prop]

				switch (typ) {
					case "int":
						try {
							v[prop] = parseInt(value);
							if (isNaN(v[prop])) {
								v[prop] = 0
							}
						} catch (err) {
							alert("The value '" + value + "' could not be converted to a number.")
						}
						break;
					case "flo":
						try {
							v[prop] = parseFloat(value);
							if (isNaN(v[prop])) {
								v[prop] = 0
							}
						} catch (err) {
							alert("The value '" + value + "' could not be converted to a number.")
						}
						break;
					default:
						// console.log("setting " + v.GUIident+ ".1 "+v.GUIident +"." +prop +" to "+value)
						v[prop] = value;
				}
				//If Fitting, set other fittings in the same points
				if (prop == "Fit1") {
					_.each($scope.design.fc5layout.Layout, function(vv) {
						if (v.ID != vv.ID) {
							if (v.X1 == vv.X1 && v.Y1 == vv.Y1 && v.Z1 == vv.Z1) {
								vv.Fit1 = v.Fit1;
								// console.log("setting " + v.GUIident+ ".2 " + vv.GUIident+".Fit1 to "+vv.Fit1)
								if (value == "ECM") {
									vv.ECM = selectedItem.ECM;
								}
							}
							if (v.X1 == vv.X2 && v.Y1 == vv.Y2 && v.Z1 == vv.Z2) {
								vv.Fit2 = v.Fit1;
								// console.log("setting " + v.GUIident+ ".3 " + vv.GUIident+".Fit2 to "+vv.Fit2)
								if (value == "ECM") {
									vv.ECM = selectedItem.ECM;
								}
							}
						}
					})
				}

				if (prop == "Fit2") {
					_.each($scope.design.fc5layout.Layout, function(vv) {
						if (v.ID != vv.ID) {
							if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) {
								vv.Fit1 = v.Fit2;
								// console.log("setting " + v.GUIident+ ".4 " + vv.GUIident+".Fit1 to "+vv.Fit1)
								if (value == "ECM") {
									vv.ECM = selectedItem.ECM;
								}
							}
							if (v.X2 == vv.X2 && v.Y2 == vv.Y2 && v.Z2 == vv.Z2) {
								vv.Fit2 = v.Fit2;
								// console.log("setting " + v.GUIident+ ".5 " + vv.GUIident+".Fit2 to "+vv.Fit2)
								if (value == "ECM") {
									vv.ECM = selectedItem.ECM;
								}
							}
						}
					})
				}

				if (prop == "multi") {
					_.each($scope.design.fc5layout.Layout, function(vv) {
						if (v.ID != vv.ID) {
							if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) {
								vv.Fit1 = v.Fit1;
								// console.log("setting " + v.GUIident+ ".6 " + vv.GUIident+".Fit1 to "+vv.Fit1)
							}
							if (v.X2 == vv.X2 && v.Y2 == vv.Y2 && v.Z2 == vv.Z2) {
								vv.Fit2 = v.Fit1;
								// console.log("setting " + v.GUIident+ ".7 " + vv.GUIident+".Fit2 to "+vv.Fit2)
							}
						}
					})
				}
				//If no inline fan then set term
				if ((prop == "Fit1" || prop=="Fit2") && (oldValue == "INL" || oldValue == "ECO")) {
					var existing= _.find($scope.design.fc5layout.Layout, function(v){
						return v.Fit1 == "INL" || v.Fit1 == "ECO"
					})
					if (!existing) {
						if ($scope.design.exhFan) {
							setTermFan($scope.design.exhFan)
						}
					}
				}
			}
		})
		if (prop == "Shape") {
			if (value == "REC") {
				$scope.divDim2 = true;
			} else {
				$scope.divDim2 = false;
			}
		}
		paint();
		if (changed) {
			$scope.sectionManager.onChanged(changed)
		}


	}
	$scope.ClickMenu = function(butval){
		$scope._ClickMenu(butval);
		if ($scope.design){
			$scope._validate(true)
		}
	}
	function pruneMetas () {
		$scope.design.stack.selectionMetas = _pruneMetas($scope.design.stack.selectionMetas)
		$scope.design.stack.accessoryMetas = _pruneMetas($scope.design.stack.accessoryMetas)
		$scope.design.stack.excludeMetas = _pruneMetas($scope.design.stack.excludeMetas)
		$scope.design.stack.fittingMetas = _pruneMetas($scope.design.stack.fittingMetas)
	}
	function _pruneMetas(collection) {
		return _.filter(collection, function(v){
			var existing = _.find($scope.design.fc5layout.Layout, function(o){
				return o.ID == v.ID
			})
			if (existing) {
				return true
			} else {
				return false
			}
		})
	}
	$scope._ClickMenu = function(butval) {
		var str = "";
		var PasteSuccess = false;
		if ($scope.modalOpen) {
			return
		}

		switch (butval) {
			case "DRAW":
				// deselect all
				$scope.MouseUp(0, 0, 0, false, false, "mouse")
				$scope.DrawMode = "DRAW"
				$scope.drawMode = true;
				$scope.selectMode = false;
				break;

			case "SEL":
				$scope._toSelectMode()
				// $scope.DrawMode = "SEL"
				// $scope.selectMode = true;
				// $scope.drawMode = false;
				break;

			case "DELETE":
				$scope.dirty = true;
				DoUndo();
				var deleted = _.filter($scope.design.fc5layout.Layout, function(v) {
					return v.Selected
				})
				$scope.design.fc5layout.Layout = _.filter($scope.design.fc5layout.Layout, function(v) {
					return !v.Selected
				})

				paint();
				SelChanged();
				$scope.sectionManager.onDeleted(deleted)
				CommandManager.createCommand($scope.design, {
					name: "Draw delete"
				},$scope.checkDirty)
				break;
			case "CUT":
				$scope.dirty = true;
				if ($scope.design.fc5layout.Layout.length > 0) {
					DoUndo();
					var cv = _.filter($scope.design.fc5layout.Layout, function(v) {
						return v.Selected
					})
					if (cv.length > 0) {
						sessionStorage.dryClipBoard = JSON.stringify(cv);
						$scope.design.fc5layout.Layout = _.filter($scope.design.fc5layout.Layout, function(v) {
							return !v.Selected
						})
						paint();
						$scope.sectionManager.onDeleted(cv)
					} else {
						alert("Please highlight one or more sections before clicking Cut.");
					}

				} else {
					alert("There is nothing to Cut.");
				}
				break;

			case "COPY":
				if ($scope.design.fc5layout.Layout.length > 0) {
					var cv = _.filter($scope.design.fc5layout.Layout, function(v) {
						if (v.Appl && v.Appl.Selected) {
							return true
						} else {
							return v.Selected
						}
					})
					if (cv.length > 0) {
						sessionStorage.dryClipBoard = JSON.stringify(cv);
					} else {
						// console.log("drawing is trapping", $scope.destroyed)
						// alert("Please highlight one or more sections before clicking Copy.");
					}
				} else {
					// console.log("drawing is trapping", $scope.destroyed)
					// alert("There is nothing to Copy.");
				}
				break;

			case "PASTE":
				$scope.dirty = true;

				if (sessionStorage.dryClipBoard != "") {
					DoUndo();
					var cv = JSON.parse(sessionStorage.dryClipBoard);
					PasteSuccess = false;
					_.each($scope.design.fc5layout.Layout, function(v) {
						$scope.selectionManager.unselectVent(v)
						// v.Selected = false;
					})
					pruneMetas()
					var pasted = []
					while (PasteSuccess == false) {
						_.each(cv, function(v) {
							Util.LoadFunctions(v);
							v.copyID = v.ID
							v.ID = newID();
							v.GUIident = "";
							//copy accessoryMetas
							_.each($scope.design.stack && $scope.design.stack.accessoryMetas, function(copyObj){
								if (copyObj.ID == v.copyID) {
									var pasteObj = _.clone(copyObj)
									pasteObj.copyID = pasteObj.ID
									pasteObj.ID = v.ID
									delete pasteObj.GUIidentPath
									delete pasteObj.GUIident
									$scope.design.stack.accessoryMetas.push(pasteObj)
								}
								return true
							})
							//copy excludeMetas
							_.each($scope.design.stack && $scope.design.stack.excludeMetas, function(copyObj){
								if (copyObj.ID == v.copyID) {
									var pasteObj = _.clone(copyObj)
									pasteObj.copyID = pasteObj.ID
									pasteObj.ID = v.ID
									delete pasteObj.GUIidentPath
									delete pasteObj.GUIident
									$scope.design.stack.excludeMetas.push(pasteObj)
								}
								return true
							})
							//copy selectionMetas
							_.each($scope.design.stack && $scope.design.stack.selectionMetas, function(copyObj){
								if (copyObj.ID == v.copyID) {
									var pasteObj = _.clone(copyObj)
									pasteObj.copyID = pasteObj.ID
									pasteObj.ID = v.ID
									delete pasteObj.GUIidentPath
									delete pasteObj.GUIident
									$scope.design.stack.selectionMetas.push(pasteObj)
								}
								return true
							})
							//copy fittingMetas - difficult to map
							_.each($scope.design.stack && $scope.design.stack.fittingMetas, function(copyObj){
								if (copyObj.ID == v.copyID) {
									var pasteObj = _.clone(copyObj)
									pasteObj.copyID = pasteObj.ID
									pasteObj.ID = v.ID
									delete pasteObj.GUIidentPath
									delete pasteObj.GUIident
									_.each(["centerPointA", "centerPointB", "centerPointC", "centerPointD"], function(key){
										if (pasteObj[key]) {
											pasteObj[key] = _.clone(pasteObj[key])
											pasteObj[key].copyID = pasteObj[key].ID
											delete pasteObj[key].ID
											delete pasteObj[key].GUIidentPath
											delete pasteObj[key].GUIident
										}
									})
									$scope.design.stack.fittingMetas.push(pasteObj)
								}
								return true
							})
							// _.each($scope.design.stack && $scope.design.stack.fittingMetas, function(copyObj){
							// 	if (copyObj.ID == fromID) {
							// 		var pasteObj = _.clone(copyObj)
							// 		pasteObj.ID = v.ID
							// 		$scope.design.stack.fittingMetas.push(pasteObj)
							// 	}
							// 	return true
							// })

							$scope.selectionManager.selectVent(v)
							// v.Selected = true;
							if ($scope.design.fc5layout.ViewAngle == "Left") {
								v.Z1 -= 50;
								v.Z2 -= 50;
							} else if ($scope.design.fc5layout.ViewAngle == "Right") {
								v.Z1 += 50;
								v.Z2 += 50;
							} else {
								v.X1 += 50;
								v.X2 += 50;
								v.Y1 += 50;
								v.Y2 += 50;
							}
						})
						_.find(cv, function(v) {
							if (AllowDrop(v, false) == false) {
								return true
							}
							PasteSuccess = true;
						})
						if (PasteSuccess == true) {
							_.each(cv, function(v) {
								$scope.design.fc5layout.Layout.push(v);
								pasted.push(v)
							})
							cv.length = 0;
							_.each($scope.design.stack && $scope.design.stack.fittingMetas, function(fittingMeta){
								_.each(["centerPointA", "centerPointB", "centerPointC", "centerPointD"], function(key){
									var centerPoint = fittingMeta[key]
									if (centerPoint) {
										if (centerPoint.copyID && !centerPoint.ID) {
											var existing = _.find($scope.design.fc5layout.Layout, function(v){
												return v.copyID == centerPoint.copyID
											})
											if (existing) {
												centerPoint.ID = existing.ID
											}
										}
									}
								})
							})
						}
					}
					paint();
					CommandManager.createCommand($scope.design, {
						name: "Draw Pasted"
					},$scope.checkDirty)
				} else {
					alert("The clipboard is empty.");
				}
				break;

			case "UNDO":
				if (Undo.length > 0) {
					DoRedo();
					str = Undo.pop()
					$scope.design.fc5layout.Layout = JSON.parse(str);
					_.each($scope.design.fc5layout.Layout, function(v) {
						Util.LoadFunctions(v);
					})
					SelChanged();
					paint();
				} else {
					alert("No events to Undo.");
				}
				break;

			case "REDO":
				if (Redo.length > 0) {
					DoUndo();
					str = Redo.pop()
					$scope.design.fc5layout.Layout = JSON.parse(str);
					_.each($scope.design.fc5layout.Layout, function(v) {
						Util.LoadFunctions(v);
					})
					SelChanged();
					paint();
				} else {
					alert("No events to Redo.");
				}
				break;
			case "SWITCH":
				// this is how to switch only for single item selections

				// if (getSelectedNo() == 1) {
				// 	var v = getSelected();
				// 	FlipSection(v);
				// } else {
				// 	alert("Please select (only) one section to switch flow direction");
				// }
				// FlipSection(v);
				// paint();
				// break;


				// this switches all selected items
				var v = getSelectedCol();
				if (v.length > 1){
					alert("Please select (only) one section to switch flow direction");
				} else {
					_.each(v, function(section) {
						FlipSection(section, true)
					})
					paint();
				}
				break;
			case "ZOOMIN":
				$scope.ZoomLev += 0.1;
				if ($scope.ZoomLev > 2.5) {
					$scope.ZoomLev = 2.5
				}
				// if ($scope.zoomLev === 280) {
				// 	$scope.zoomLev = $scope.zoomLev
				// } else if ($scope.zoomLev === 20) {
				// 	$scope.zoomLev = $scope.zoomLev
				// } else {
				// 	$scope.zoomLev = $scope.zoomLev + 10;
				// }
				paint();
				break;
			case "ZOOMOUT":
				$scope.ZoomLev -= 0.1;
				if ($scope.ZoomLev < 0.25) {
					$scope.ZoomLev = 0.25
				}

				// if ($scope.zoomLev === 280) {
				// 	$scope.zoomLev = $scope.zoomLev
				// } else if ($scope.zoomLev === 20) {
				// 	$scope.zoomLev = $scope.zoomLev
				// } else {
				// 	$scope.zoomLev = $scope.zoomLev - 10;
				// }
				paint();
				break;

			case "CALC":
				$scope.dirty = true;
				Calculate();
				break;
			case "PRINT":
				printCanvas("myCanvas");
				break;
			case "SAVE":
				if (Edited() == true) {
					Save()
				} else {
					alert("The calculation was saved.");
				}

				break;
			case "CANCEL":
				$scope._toSelectMode()
				paint();
				break;
				// case "CLOSE":
				// 	if (Edited() == true) {
				// 		Save("project.html");
				// 	} else {
				// 		window.location.href = "project.html";
				// 	}
				// 	return false;
				// 	break;
			default:
				alert("Command is undefined");
		}
	}
	$scope.ChangeView = function(butval) {
		switch (butval) {
			case "FRONT":
				setDrawView("Front");
				$scope.perspectiveMenu = false;
				$scope.selectedPerspective = true;
				paint();
				break;
			case "LEFT":
				setDrawView("Left");
				$scope.perspectiveMenu = false;
				$scope.selectedPerspective = true;
				paint();
				break;
			case "RIGHT":
				setDrawView("Right");
				$scope.perspectiveMenu = false;
				$scope.selectedPerspective = true;
				paint();
				break;
			case "TOPLEFT":
				setDrawView("TopLeft");
				$scope.perspectiveMenu = false;
				$scope.selectedPerspective = true;
				paint();
				break;
			case "PLAN":
				setDrawView("Plan");
				$scope.perspectiveMenu = false;
				$scope.selectedPerspective = true;
				paint();
				break;
			case "TOPRIGHT":
				setDrawView("TopRight");
				$scope.perspectiveMenu = false;
				$scope.selectedPerspective = true;
				paint();
				break;
		}
	}
	$scope.changeCmbDamper = function() {
		SetControls();
		$scope.design.baffles = _.map($scope.availableBaffleTypes, function(baffleType){
			return {
				baffle: baffleType.selectedBaffle._id,
				voltage: baffleType.selectedVoltage
			}
		})
		setBaffles();
		getBom();
	}
	$scope.changeCmbControls = function() {
		getBom();
	}
	$scope.cmbExhFanChange = function(donthide) {
		if (!donthide) {
			_.each($scope.design.calcResult.ExhFans, function(fan) {
				if (fan.Name == $scope.design.exhFan && fan.hidden){
					$scope.toggleExhCurve(fan)
				} else if (fan.Name != $scope.design.supFan && !fan.hidden) {
					$scope.toggleExhCurve(fan)
				}
			})
		}

		$scope.txtResFEI = getFanFEI($scope.design.exhFan, $scope.design.calcResult.ExhFans)

		setVoltage($scope.design.exhFan);
		SetControls();
		getBom();
	}
	$scope.cmbExhFanVoltageChange = function() {
		SetControls();
		getBom();
	}
	$scope.cmbSupFanVoltageChange = function() {
		SetControls();
		getBom();
	}
	$scope.cmbSupFanChange = function(donthide) {
		if (!donthide){
			_.each($scope.design.calcResult.SupFans, function(fan) {
				if (fan.Name == $scope.design.supFan && fan.hidden){
					$scope.toggleSupCurve(fan)
				} else if (fan.Name != $scope.design.supFan && !fan.hidden) {
					$scope.toggleSupCurve(fan)
				}
			})
		}

		$scope.txtResSupFEI = getFanFEI($scope.design.supFan, $scope.design.calcResult.SupFans)

		setVoltage();
		SetControls();
		getBom();
	}
	$scope.updateModels = function(applianceCompany) {
		getModels(applianceCompany)
	}
	$scope.changeCmbModel = function(data) {
		setFuelNew(data.fuels);
		$scope.supplyAppl.selectedCmbFuel = $scope.cmbFuel.options[0];
		$scope.selectFuel($scope.supplyAppl.selectedCmbFuel)
	}

	function setFuelNew(fuelDatas) {
		var options = _.filter(fuelDatas, function(fuelData) {
			return fuelData.include != false && fuelData.fuel.active == true
		})
		options = _.sortBy(options, function(f) {
			return f.fuel.sortOrder * -1;
		});
		$scope.cmbFuel.options = options;
	}
	$scope.selectFuel = function(fuel) {
		try {
			//now set the values
			$scope.supplyAppl.MBTU = fuel.mbhmax;
			$scope.supplyAppl.co2 = parseInt(fuel.co2)
			// console.log("set supplly appliance based on", fuel)
		} catch (e) {
			console.log("error in selectFuel()", e, fuel)
		}
	}

	function autoFit() {
		console.log('autofit')
		_.each($scope.design.fc5layout.Layout, function(v) {
			var Inlets = [];
			var EndsIn = [];
			_.each($scope.design.fc5layout.Layout, function(vv) {
				if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) {
					EndsIn.push(vv);
				}
			})
			_.each($scope.design.fc5layout.Layout, function(vv) {
				if (vv.X2 == v.X1 && vv.Y2 == v.Y1 && vv.Z2 == v.Z1) {
					Inlets.push(vv);
				}
			})

			if (Inlets.length == 0) {
				v.Fit1 = "STP"
				if (v.Appl == undefined) {
					v.Appl = Util.createAppliance($scope.design)
				}
			} else {
				if (v.Fit1 == "STP") {
					v.Fit1 = "";
					v.Appl = undefined;
				}
			}

			if (EndsIn.length == 0) {
				v.Fit2 = "TER"
			} else {
				if (v.Fit2 == "TER") {
					v.Fit2 = "";

				}
			}
		})
	}
	function handleModels(instance) {
		instance.rendered.then(function(){
			$scope.modalOpen = true
			$scope.showingModal = true;
			paint()
		})
		instance.closed.then(function(){
			$scope.modalOpen = false
			paint()
		})
	}
	$scope.expandExhaustFan = function() {

		var parentElem = angular.element(document.querySelector('#drawingsMain'));
		// pass things through to the other controller with the resolve method
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/exhaustFanModal.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'ExhaustFanCtrl',
			resolve: {
				items: function() {
					$scope.showingModal = false;
					return;
				}
			}
		});
		modalInstance.rendered.then(function() {
			$(".modal-content").draggable();
			var newHeight = $(window).height() * .7;
			$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		})
		handleModels(modalInstance)
	}
	$scope.expandSupplyFan = function() {
		var parentElem = angular.element(document.querySelector('#drawingsMain'));

		// pass things through to the other controller with the resolve method
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/supplyFanModal.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'SupplyFanCtrl',
			resolve: {
				items: function() {
					return;
				}
			}
		});
		modalInstance.rendered.then(function() {
			$(".modal-content").draggable();
			var newHeight = $(window).height() * .7;
			$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		})
	}
	$scope.openBank = function() {
		var parentElem = angular.element(document.querySelector('#drawingsMain'));

		// pass things through to the other controller with the resolve method
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/winBankBuilderModal.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'WinBankBuilderCtrl',
			size: 'lg',
			resolve: {
				items: function() {
					return $scope.design.fc5layout
				}
			}
		});
		modalInstance.rendered.then(function() {
			$(".modal-content").draggable();
			var newHeight = $(window).height() * .7;
			$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		})
		handleModels(modalInstance)
	}
	$scope.canBOMclose = function() {
		$scope.bomResults = false;
	}
	$scope.canVentClose = function() {
		$scope.calcResults = false;
	}
	$scope.showResults = function() {
		var newHeight = $(window).height() * .75;
		$('#winResults .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		if ($scope.calcResults === undefined || $scope.calcResults === false) {
			$("#winResults").draggable({});
			$scope.calcResults = true;
		} else {
			$scope.calcResults = false;
		}
	}
	$scope.openVentBOM = function() {
		var newHeight = $(window).height() * .5;

		$('#bomResults .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		if ($scope.bomResults === undefined || $scope.bomResults === false) {
			$("#bomResults").draggable();
			$scope.bomResults = true;
		} else {
			$scope.bomResults = false;
		}
	}

	function _cleanSections(sections) {
		_.each(sections, function(section) {
			if (!section) {
				return
			}
			if (section.Appl && $scope.design.application.computeAs === 'BWH') {
				section.DamperSection = true;
			} else if (section.DamperSection) {
				section.DamperSection = false;
			}
		})
	}

	function colorForIndex(index) {
		var fanColors = ["#2fb3b2", "#76382a", "#c72802", "#ef9d58", "#118f0b", "#b6a6e6", "#d27223", "#547baa", "#24efcc", "#12dca7", "#d06ca1", "#dc5903", "#57d644", "#4c4e1", "#c5571b", "#757797", "#21cde5", "#fb2417", "#ecdc40", "#d95297", "#74c7cc", "#4bd7ec", "#8e6de2", "#5b154b", "#9c3457", "#bed90a", "#522115", "#7edf53", "#fd5c03", "#3b9946"];
		return fanColors[index];
	}

	function toIntake() {
		if ($scope.design.application.computeAs == "COM") {
			$scope.design.include.supply = true
		}
		var method = _.find($scope.cmbIntMethod.options, function(option) {
			return (option.value === $scope.design.fc5layout.Intake.Method)
		})
		$scope.design.fc5layout.Intake.MethodText = method && method.text || ""
		$scope.design.fc5layout.Intake.Dia1 = $scope.design.fc5layout.Intake.Dia1 || 0
		$scope.design.fc5layout.Intake.Dia2 = $scope.design.fc5layout.Intake.Dia2 || 0
		$scope.design.fc5layout.Intake.L45 = $scope.design.fc5layout.Intake.L45 || 0
		$scope.design.fc5layout.Intake.L90 = $scope.design.fc5layout.Intake.L90 || 0
		$scope.design.fc5layout.Intake.Length = $scope.design.fc5layout.Intake.Length || 0
		$scope.design.fc5layout.Intake.AddPres = $scope.design.fc5layout.Intake.AddPres || 0

		return $scope.design.fc5layout.Intake
	}

	function populateDiameter(sections) {
		if (sections) {
			_.each(sections, function(section) {
				if (section.Optimize == 'No') {
					if (section.Dim1 == undefined && section.Diameter) {
						section.Dim1 = section.Diameter
						delete section.Diameter
					}
				}
			})
		}
		return sections
	}

	function sortByGuiindent(col) {
		return _.sortBy(col, function(obj) {
			return parseInt(obj.GUIident)
		})
	}

	function toLayout() {
			try {
				setSystems();
				_toSummaries()
				paint();
			} catch (e) {
				console.log("error in tolayout", e)
				_toSummaries()
				paint();
				throw e;
			}

		checkPoint();
		toIntake()

		if ($scope.design.application.computeAs == "DRY" || $scope.design.application.computeAs == "BWH") {
			$scope.design.fc5layout.Layout = sortByGuiindent(populateDiameter($scope.design.fc5layout.Layout))
		} else if ($scope.design.application.computeAs == "COM") {
			$scope.design.fc5layout.Lines = sortByGuiindent(populateDiameter($scope.design.fc5layout.Lines))
		}
	}
	$scope.baffleName = function(baffleTypeCode){
		var baffle = _.find($scope.baffles,function(db){
			var selected =  _.find($scope.design.baffles, function(b){
				return b.baffle == db._id
			})
			return selected && db.baffleType.code == baffleTypeCode
		})
		if ($scope.configs.show_baffle_options == 'true' && baffle) {
			return baffle.name + " (" + baffle.baffleType.name + ")"
		} else {
			return baffleTypeCode
		}
	}
	function toBomRequest() {
		$scope.design.supFan = $scope.design.supFan || "";

		$scope.design.exhFan = $scope.design.exhFan || "";
		if ($scope.design.exhFan) {
			setTermFan($scope.design.exhFan)
		}

		paint();

		if ($scope.design.exhFan) {
			setTermFan($scope.design.exhFan)
		}

		if ($scope.cmbSupFans.options) {
			$scope.txtResSup = $scope.fanName($scope.design.supFan);
		}
		$scope.txtResSol = $scope.fanName($scope.design.exhFan) || '';
		if ($scope.design.damper) {
			$scope.txtResSol += ' + MODS';
		}
		$scope.design.baffles = _.map($scope.availableBaffleTypes, function(baffleType){
			return {
				baffle: baffleType.selectedBaffle._id,
				voltage: baffleType.selectedVoltage
			}
		})
	}
	function setBaffles(_setProps) {
		$scope.availableBaffleTypes = _.filter($scope.baffleTypes, function(baffleType){
			var existing = _.find($scope.design.calcResult.Diameters, function(d){
				return d.Baffle == baffleType.code
			})
			if (!baffleType.baffles){
				baffleType.baffles = _.filter($scope.baffles, function(baffle){
					return baffle.baffleType.code == baffleType.code
				})

			}
			var selected = _.find(baffleType.baffles, function(b){
				return _.find($scope.design.baffles, function(db){
					return b._id == db.baffle
				})
			})
			if (!selected){
				selected = baffleType.baffles[0]
			}
			baffleType.selectedBaffle = selected;

			if (selected.baffleVoltages){
				var selectedVoltage = _.find(selected.baffleVoltages, function(voltage){
					return _.find($scope.design.baffles, function(entry){
						return selected._id == entry.baffle && entry.voltage == voltage
					})
				})
				if (!selectedVoltage) {
					selectedVoltage = selected.baffleVoltages[0]
				}
				baffleType.selectedVoltage = selectedVoltage;
			} else {
				baffleType.selectedVoltage = null;
			}

			return (existing) ? true : false;
		})
	}
	$scope.$watch("design.damper", function(newObj, oldObj) {
		if (!$scope.design) {
			return
		}
		if ($scope.design.damper == true) {
			$scope.txtResSol = $scope.fanName($scope.design.exhFan) || '';
			if ($scope.design.damper) {
				$scope.txtResSol += ' + MODS';
			}
		} else {
			$scope.txtResSol = $scope.fanName($scope.design.exhFan) || '';
		}
	});
	function selectUnhiddenExhFan() {
		var shownNotHidden = _.find($scope.fanResults, function(f){
			return f.hidden == false && f.Name == $scope.design.exhFan
		})
		if (shownNotHidden) {
			return
		}else {
			var nonHidden = _.find($scope.fanResults, function(f){
				return f.hidden == false
			})
			if (nonHidden) {
				$scope.design.exhFan = nonHidden.Name
			}
		}
		$scope.cmbExhFanChange(true)
	}
	function selectUnhiddenSupplyFan() {
		var shownNotHidden = _.find($scope.fanResultsSup, function(f){
			return f.hidden == false && f.Name == $scope.design.supFan
		})
		if (shownNotHidden) {
			return
		}else {
			var nonHidden = _.find($scope.fanResultsSup, function(f){
				return f.hidden == false
			})
			if (nonHidden) {
				$scope.design.supFan = nonHidden.Name
			}
		}
		$scope.cmbSupFanChange(true)
	}

	function findObjectWithHighestIntValue(array, key) {
	  if (!Array.isArray(array) || array.length === 0) {
		return undefined;
	  }

	  var maxIntValue = Number.MIN_SAFE_INTEGER;
	  var maxObject = array[0];

	  for (var i = 0; i < array.length; i++) {
		var obj = array[i];
		var value = obj[key];

		if (typeof value === 'number' && Number.isInteger(value) && value > maxIntValue) {
		  maxIntValue = value;
		  maxObject = obj;
		}
	  }

	  return maxObject;
	}

	function getHighPointFan(selectedFans) {
		var fans = _.map(selectedFans, function(fan) {
			return {
				name: fan.Name,
				highPoint: _.last(fan.systemCurveData) && _.last(fan.systemCurveData)[0]
			}
		})

		return findObjectWithHighestIntValue(fans, 'highPoint'); 
	}

	function clearCharts() {
		$scope._hideExhCurve = function(fan) {
			if (fan.hidden == true) {
				_.each($scope.n3options.series, function(value) {
					if (value.color === fan.color) {
						value.visible = false;
						value.thickness = '0px';
					}
				})
			} else {
				_.each($scope.n3options.series, function(value) {
					if (value.color === fan.color) {
						value.visible = true;
						value.thickness = '2px';
					}
				})
			}
		}

		
		$scope.toggleExhCurve = function(fan) {
			if (!fan.hidden || fan.hidden === false) {
				fan.hidden = true;
				selectUnhiddenExhFan()
			} else {
				fan.hidden = false;
				selectUnhiddenExhFan()
			}
			$scope.n3data = [];
			$scope.fanResults = [];
			var xArray = [];
			var yArray = [];
			
			var selectedFans = _.filter($scope.design.calcResult.ExhFans, function(fan) {
				if (!fan.hidden) {
					return fan;
				}
			})
			var highPointFan = getHighPointFan(selectedFans)

			_.each($scope.design.calcResult.ExhFans, function(fan) {
				$scope.fanResults.push(fan);
				if (fan.hidden) return;
				var yVal = $scope.fanResults.indexOf(fan) + 1;
				var yExpression = "value" + yVal;
				var sysCurveExpression = "sysCurve"
				ChartUtil.setPoints(fan, fan.Points, $scope.n3data, xArray, yArray, yExpression)
				if (highPointFan.name == fan.Name && $scope.configs.calculate_fei == 'true') {
					ChartUtil.setPoints(fan, fan.systemCurveData, $scope.n3data, xArray, yArray, sysCurveExpression)
				}
			})

			var largest = Math.ceil(Math.max.apply(Math, xArray) / 100) * 100;
			var chartMax = Math.max.apply(Math, yArray);

			largest = Math.max(largest, Number($scope.design.calcResult.ExhVol))
			chartMax = Math.max(chartMax, Number($scope.design.calcResult.ExhPres))
			
			$scope.n3options = ChartUtil.getN3Options(largest, chartMax, $scope.configs.calculate_fei)

			$scope.n3options.series.push({
				y: 'answer',
				color: '#04486C',
				visible: true,
				thickness: '5px',
				drawDots: true,
				type: 'area',
				dotSize: 5
			});
			$scope.n3data.push({
				x: Number($scope.design.calcResult.ExhVol),
				answer: Number($scope.design.calcResult.ExhPres)
			});

			_.each($scope.design.calcResult.ExhFans, function(fan, index) {
				var yVal = $scope.design.calcResult.ExhFans.indexOf(fan) + 1;
				var yExpression = "value" + yVal;
				fan.color = colorForIndex(index);

				if (fan.value == "None") {
					return
				}
				$scope.n3options.series.push({
					y: yExpression,
					color: fan.color,
					thickness: '2px',
					type: 'line',
					visible: true,
					drawDots: false
				})
				$scope._hideExhCurve(fan)
			})
			$scope.n3PrintOptions = ChartUtil.getN3PrintOptions(largest, $scope.n3options.series)
			Util.setFanOption("exhaust", {
				Name: fan.Name,
				hidden: fan.hidden
			}, $scope.design)
			$scope._hideExhCurve(fan)
		}
		$scope._hideSupCurve = function(fan) {
			if (fan.hidden == true) {
				_.each($scope.n3optionsSup.series, function(value) {
					if (value.color === fan.color) {
						value.visible = false;
						value.thickness = '0px';
					}
				})
			} else {
				_.each($scope.n3optionsSup.series, function(value) {
					if (value.color === fan.color) {
						value.visible = true;
						value.thickness = '2px';
					}
				})
			}
		}

		$scope.toggleSupCurve = function(fan) {
			if (!fan.hidden || fan.hidden === false) {
				fan.hidden = true;
				selectUnhiddenSupplyFan()
			} else {
				fan.hidden = false;
				selectUnhiddenSupplyFan()
			}
			$scope.n3dataSup = [];
			$scope.fanResultsSup = [];
			var xArray = [];
			var yArray = [];

			var selectedFans = _.filter($scope.design.calcResult.SupFans, function(fan) {
				if (!fan.hidden) {
					return fan;
				}
			})
			var highPointFan = getHighPointFan(selectedFans)

			_.each($scope.design.calcResult.SupFans, function(fan) {
				$scope.fanResultsSup.push(fan);
				if (fan.hidden) return;
				var yVal = $scope.fanResultsSup.indexOf(fan) + 1;
				var yExpression = "value" + yVal;
				var sysCurveExpression = "sysCurve"
				ChartUtil.setPoints(fan, fan.Points, $scope.n3dataSup, xArray, yArray, yExpression)
				if (highPointFan.name == fan.Name && $scope.configs.calculate_fei == 'true') {
					ChartUtil.setPoints(fan, fan.systemCurveData, $scope.n3dataSup, xArray, yArray, sysCurveExpression)
				}
			})

			var largest = Math.ceil(Math.max.apply(Math, xArray) / 100) * 100;
			var chartMax = Math.max.apply(Math, yArray);

			largest = Math.max(largest, Number($scope.design.calcResult.SupVol))
			chartMax = Math.max(chartMax, Number($scope.design.calcResult.SupPres))

			$scope.n3optionsSup = ChartUtil.getN3Options(largest, chartMax, $scope.configs.calculate_fei)
			
			$scope.n3optionsSup.series.push({
				y: 'answer',
				color: '#04486C',
				visible: true,
				thickness: '5px',
				drawDots: true,
				type: 'area',
				dotSize: 5
			});
			$scope.n3dataSup.push({
				x: Number($scope.design.calcResult.SupVol),
				answer: Number($scope.design.calcResult.SupPres)
			});
			_.each($scope.design.calcResult.SupFans, function(fan, index) {
				var yVal = $scope.design.calcResult.SupFans.indexOf(fan) + 1;
				var yExpression = "value" + yVal;
				fan.color = colorForIndex(index);

				if (fan.value == "None") {
					return
				}
				$scope.n3optionsSup.series.push({
					y: yExpression,
					color: fan.color,
					thickness: '2px',
					type: 'line',
					visible: true,
					drawDots: false
				})
				$scope._hideSupCurve(fan)
			})

			$scope.n3PrintOptionsSup = ChartUtil.getN3PrintOptions(largest, $scope.n3optionsSup.series)

			Util.setFanOption("supply", {
				Name: fan.Name,
				hidden: fan.hidden
			}, $scope.design)
			$scope._hideSupCurve(fan)
		}
	}
	function _showError(e){
		if (_.isArray(e)){

		} else {

		}
	}
	function fromCalcResult(setProps) {
		try {
			_fromCalcResult(setProps)
			_toSummaries()
		} catch (e) {
			_toSummaries();
			_calcError(e)
			throw e
		}
	}

	function _clearResults() {
		_.each($scope.design.fc5layout.Layout, function(section) {
			delete section.result
		})
	}

	function _overRide(_setProps) {
		return (_setProps && overRideExistingParams)
	}
	$scope.hasBaffles = function() {
		if ($scope.design && $scope.design.calcResult && $scope.design.calcResult.Diameters && $scope.design.calcResult.Diameters.length > 0) {
			var baffleSections = _.filter($scope.design.calcResult.Diameters, function(d) {
				return d.Baffle && d.Baffle != ''
			})
			return baffleSections.length > 0;
		} else {
			return false;
		}
	}

	function getFanFEI(fan, fans) {
		var selectedFan = _.find(fans, function(_fan) {
			return _fan.Name == fan
		})
		return selectedFan && selectedFan.computeFEI && Math.round(selectedFan.fei * 100) / 100
	}

	function _fromCalcResult(_setProps) {
		if ($scope.design.calcResult.failure) {
			throw {
				message: $scope.design.calcResult.message || "An error occurred while trying to get information from the server"
			}
		}
		_clearResults()
		$scope.accordian.isOpen6 = true
		if ($scope.design.application.computeAs === 'COM') {
			$scope.cmbSupFans.options = $scope.design.calcResult.SupFans;
			$scope.stickySelections.capture("supFan")
			if ($scope.cmbSupFans.options && $scope.cmbSupFans.options.length > 0) {
				if (_overRide(_setProps)) {
					$scope.design.supFan = $scope.cmbSupFans.options[0].Name;
				}
			} else {
				$scope.design.supFan = "None"
			}
			$scope.txtResCFMSup = Math.round($scope.design.calcResult.SupVol);
			$scope.txtResPresSup = $scope.design.calcResult.SupPres;
		}
		if ($scope.design.calcResult.ExhVol) {
			$scope.cmbExhFans.options = $scope.design.calcResult.ExhFans;
			if ($scope.design.calcResult.ExhFanNeed === false || $scope.design.application.exhaustFanStrategy == "allowNoneAlways") {
				var existing = _.find($scope.cmbExhFans.options, function(f){
					return f.Name == "None"
				})
				if (!existing){
					$scope.cmbExhFans.options.unshift({
						Name: "None",
						value: "None"
					})
				}
			}
			$scope.stickySelections.capture("exhFan")
			if (!$scope.design.calcResult.ExhFans || $scope.design.calcResult.ExhFans.length == 0) {
				$scope.design.exhFan = "None"
			} else {
				if (_overRide(_setProps)) {
					if ($scope.design.calcResult.ExhFans.length > 0) {
						$scope.design.exhFan = $scope.design.calcResult.ExhFans[0].Name;
					} else {
						$scope.design.exhFan = null
					}
				}

				$scope.n3data = [];
				$scope.fanResults = [];
				var xArray = [];
				var yArray = [];

				_.each($scope.design.calcResult.ExhFans, function(fan) {
					var option = Util.getFanOption("exhaust", fan, $scope.design)
					if (_overRide(_setProps)) {
						if (fan.Name != $scope.design.exhFan){
							fan.hidden = true;
							Util.setFanOption("exhaust",{
								hidden:true,
								Name: fan.Name
							}, $scope.design)
						} else {
							fan.hidden = false;
							Util.setFanOption("exhaust",{
								hidden:false,
								Name: fan.Name
							}, $scope.design)
						}
					} else {
						if (option) {
							fan.hidden = option.hidden
						}
					}
					$scope.fanResults.push(fan);
					var yVal = $scope.fanResults.indexOf(fan) + 1;
					var yExpression = "value" + yVal;
					var sysCurveExpression = "sysCurve"
					if (fan.hidden) return;
					ChartUtil.setPoints(fan, fan.Points, $scope.n3data, xArray, yArray, yExpression)
					if ($scope.configs.calculate_fei == 'true') { 
						ChartUtil.setPoints(fan, fan.systemCurveData, $scope.n3data, xArray, yArray, sysCurveExpression)
					}
				})

				$scope.n3data.push({
					x: Number($scope.design.calcResult.ExhVol),
					answer: Number($scope.design.calcResult.ExhPres)
				});

				var largest = Math.ceil(Math.max.apply(Math, xArray) / 100) * 100;
				var chartMax = Math.max.apply(Math, yArray);
				largest = Math.max(largest, Number($scope.design.calcResult.ExhVol))
				chartMax = Math.max(chartMax, Number($scope.design.calcResult.ExhPres))

				$scope.n3options = ChartUtil.getN3Options(largest, chartMax, $scope.configs.calculate_fei)

				_.each($scope.design.calcResult.ExhFans, function(fan, index) {
					var yVal = $scope.design.calcResult.ExhFans.indexOf(fan) + 1;
					var yExpression = "value" + yVal;
					fan.color = colorForIndex(index);

					if (fan.value == "None") {
						return
					}
					$scope.n3options.series.push({
						y: yExpression,
						color: fan.color,
						thickness: '2px',
						type: 'line',
						visible: true,
						drawDots: false
					})
					$scope._hideExhCurve(fan)
				})

				$scope.n3PrintOptions = ChartUtil.getN3PrintOptions(largest, $scope.n3options.series)
			}
		}
		if ($scope.design.calcResult.SupFans && $scope.design.calcResult.SupFans.length > 0) {
			$scope.n3dataSup = [];
			$scope.fanResultsSup = [];
			var xArray = [];
			var yArray = [];

			$scope.cmbSupFans.options = $scope.design.calcResult.SupFans;
			if ($scope.design.calcResult.SupFans) {
				if (_overRide(_setProps)) {
					$scope.design.supFan = $scope.cmbSupFans.options[0] && $scope.cmbSupFans.options[0].Name;
				} else {
					$scope.design.supFan = null;
				}
			}

			_.each($scope.design.calcResult.SupFans, function(fan) {
				var option = Util.getFanOption("supply", fan, $scope.design)
				if (_overRide(_setProps)) {
					if (fan.Name != $scope.design.supFan){
						fan.hidden = true;
						Util.setFanOption("supply",{
							hidden:true,
							Name: fan.Name
						}, $scope.design)
					} else {
						fan.hidden = false;
						Util.setFanOption("supply",{
							hidden:false,
							Name: fan.Name
						}, $scope.design)
					}
				} else {
					if (option) {
						fan.hidden = option.hidden
					}
				}
				$scope.fanResultsSup.push(fan);
				var yVal = $scope.design.calcResult.SupFans.indexOf(fan) + 1;
				var yExpression = "value" + yVal;
				var sysCurveExpression = "sysCurve"
				if (fan.hidden) return;

				ChartUtil.setPoints(fan, fan.Points, $scope.n3dataSup, xArray, yArray, yExpression)
				if ($scope.configs.calculate_fei == 'true') { 
					ChartUtil.setPoints(fan, fan.systemCurveData, $scope.n3dataSup, xArray, yArray, sysCurveExpression)
				}
			})

			var largest = Math.ceil(Math.max.apply(Math, xArray) / 100) * 100;
			var chartMax = Math.max.apply(Math, yArray);
			largest = Math.max(largest, Number($scope.design.calcResult.SupVol))
			chartMax = Math.max(chartMax, Number($scope.design.calcResult.SupPres))

			$scope.n3dataSup.push({
				x: Number($scope.design.calcResult.SupVol),
				answer: Number($scope.design.calcResult.SupPres)
			});

			$scope.n3optionsSup = ChartUtil.getN3Options(largest, chartMax, $scope.configs.calculate_fei)

			_.each($scope.design.calcResult.SupFans, function(fan, index) {
				var yVal = $scope.design.calcResult.SupFans.indexOf(fan) + 1;
				var yExpression = "value" + yVal;

				fan.color = colorForIndex(index);

				if (fan.value == "None") {
					return
				}
				$scope.n3optionsSup.series.push({
					y: yExpression,
					color: fan.color,
					thickness: '2px',
					type: 'line',
					visible: true,
					drawDots: false
				})
				$scope._hideSupCurve(fan)
			})

			$scope.n3PrintOptionsSup = ChartUtil.getN3PrintOptions(largest, $scope.n3optionsSup.series)
		} else {
			$scope.cmbSupFans.options = [];
			// $scope.cmbSupFanVoltage.options = []
			$scope.stickySelections.capture('supFan')
			// $scope.stickySelections.capture('supVoltage')
			$scope.design.supFan = "None";
		}
		if ($scope.design.calcResult.InclComb === false) {
			$scope.divCombSol = false;
			$scope.supFanRow = false;
		} else {
			$scope.divCombSol = true;
			$scope.supFanRow = true;
		}

		if ($scope.design.calcResult.Diameters) {
			var bafCol = [];
			_.each($scope.design.calcResult.Diameters, function(d) {
				_.find($scope.design.fc5layout.Layout, function(s) {
					if (s.ID == d.ID) {
						s.result = {}
						s.result.Dim1 = d.Dim1;
						s.result.Dim2 = d.Dim2;
						s.result.Classification = d.Classification;

						//missing baffle and damper section code
						if (d.Diameter > 0) {
							s.result.Diameter = d.Diameter
						};
						s.result.Baffle = d.Baffle;
						s.result.BafflePD = d.BafflePD;
						s.result.BaffleAngle = d.BaffleAngle;
						s.result.Classification = d.Classification;
						s.result.DamperSection = d.DamperSection;

						if (s.Baffle !== "") {
							var baf = {
								SectionNo: s.GUIident,
								Type: s.Baffle,
								Diameter: s.Diameter,
								BaffleType: s.Baffle,
								DeltaP: s.BafflePD,
								Setting: s.BaffleAngle
							}
							bafCol.push(baf);
						}
						return true
					}
				})
			})
		}
		if ($scope.design.calcResult.InclComb === true || $scope.design.application.computeAs == "COM") {
			$scope.txtResCFMSup = Math.round($scope.design.calcResult.SupVol);
			$scope.txtResPresSup = $scope.design.calcResult.SupPres;

			// $scope.divNoComb = false;
			// $scope.divSupResults = true;

			// $scope.divSupAddVol = $scope.design.fc5layout.Intake.Volume + ' CFM';
			if ($scope.design.fc5layout.Intake.Ducting !== "NON") {
				$scope.SupDucting = true;
				// $scope.divSupDims = $scope.design.calcResult.SupDims;
				// $scope.divSupLength = $scope.design.fc5layout.Intake.Length + ' '+Util.getUnits($scope.design, 'Len');
				// $scope.divSup90L = $scope.design.fc5layout.Intake.L90;
				// $scope.divSup45L = $scope.design.fc5layout.Intake.L45;
				// $scope.divSupAddPres = $scope.design.fc5layout.Intake.AddPres +' ' +Util.getUnits($scope.design, 'Pres');
				// $scope.divSupHeater = $scope.design.fc5layout.Intake.Heater;
			} else {
				$scope.SupDucting = false;
			}
		} else {
			// $scope.divNoComb = true;
			// $scope.divSupResults = false;

		}

		$scope.txtResCFM = Math.round($scope.design.calcResult.ExhVol || 0);
		$scope.txtResPres = $scope.design.calcResult.ExhPres || 0;

		$scope.txtResFEI = getFanFEI($scope.design.exhFan, $scope.design.calcResult.ExhFans)
		$scope.txtResSupFEI = getFanFEI($scope.design.supFan, $scope.design.calcResult.SupFans)

		if ($scope.design.calcResult.CalcFailed == null) {
			$scope.design.calcResult.CalcFailed = true
		}
		if ($scope.design.calcResult.Failed == true) {
			if ($scope.design.calcResult.CalcFailed === false) {
				// return
			} else {
				$scope.design.calcResult.CalcFailed = true
			}
		}
		if ($scope.design.application.computeAs !== 'COM' && $scope.design.calcResult.ExhFans.length == 0  && $scope.design.calcResult.ExhFanNeed) {
			throw {
				message: "Could not determine exhaust fans"
			}
		}
		if (($scope.design.application.computeAs == 'COM' || $scope.design.calcResult.InclComb === true) && $scope.design.calcResult.SupFans.length == 0) {
			throw {
				message: "Could not determine supply fans"
			}
		}

		setVoltage(_setProps);
		if ($scope.design.application.computeAs === 'BWH') {
			$scope.modsManager.setMODS(_setProps);
			setBaffles(_setProps)
		}
		SetControls(_setProps);
	}
	$scope.includeChanged = function(target) {
		$scope.convertContext.state = target
		if (target == 'stack') {
			console.log('changed stack to ', $scope.design.include.stack)
		}
		if ($scope.design.include.exhaust == false) {
			$scope.design.include.supply = false
		}
		var application = $scope.design.application

		if (application && application.code == "COM") {
			$scope.design.include.supply = true
			$scope.design.include.exhaust = true
		}
		FittingMetaUtil.purgeFittingMetas($scope.design, target)
		$scope._validate(false, target)
	}
	$scope._validate = function(apply, target) {
		if ($scope.$$phase) {
			return
		}
		try {
			setSystems(target);
			_toSummaries()
			if (apply){
				try {
					$scope.$apply();
				}catch(squash){
					console.log("squash _validate")
				}
			}
		} catch(e) {
			// console.log("error in _validate", e)
			_calcError(e, apply)
			paint()
		}
	}
	function toCalcRequest() {
		clearCharts()
		return toLayout()
	}
	function setProcessState(state, force) {
		if ($scope.processState == 'init' || force){
			$scope.processState = state;
		}
	}
	function _toSummaries() {
		if (!$scope.design){
			return;
		}
		$scope.summaries = [];
		$scope.showExhFans = false;
		$scope.showSupFans = false;
		if (!$scope.processState){
			$scope.processState = 'init'
		}


		if ($scope.validationManager.status == "invalid"){
			setProcessState('invalid');
			$scope.summaries = $scope.validationManager.toSummaries();
			return
		} else {
			$scope.summaries = $scope.validationManager.toSummaries()
			if ($scope.processState == 'invalid'){
				setProcessState('ready', true);
				$scope.design.calcResult = null;
				$scope.design.bomResponse = null;
				$scope.summaries.push({
					message: "Ready to Compute",
					messageType: "info"
				})
				return;
			}
			if (!$scope.design.calcResult){
				$scope.summaries.push({
					message: "Ready to Compute",
					messageType: "info"
				})
				return;
			}
		}
		if ($scope.design.calcResult && $scope.design.calcResult.Failed == true){
			if ($scope.design.calcResult.FailedDesc && $scope.design.calcResult.FailedDesc != '') {
				$scope.summaries.push({
					message: $scope.design.calcResult.FailedDesc,
					messageType: "error"
				})
			}
			_.each($scope.design.calcResult.Summary, function(obj) {
				$scope.summaries.push({
					message: obj.message,
					messageType: obj.type
				})
			})
			if ($scope.design.calcResult.CalcFailed) {
				setProcessState('failedCalc', true);
				return;
			}
		}
		if ($scope.loadErrors && $scope.loadErrors.length >0){
			_.each($scope.loadErrors, function(m){
				var message = (m && m.message) ? m.message : ""+m;
				var messageType = (m && _.isString(m.type)) ? m.type : "error";
				$scope.summaries.push({
					message: message,
					messageType: messageType,
					subtype: m.subtype,
					faultItem: m.faultItem
				})
			})
			setProcessState("failedCalcLoad", true);
			return;
		}
		if (_.isString($scope.design.bomResponse)) {
			$scope.design.bomResponse = null;
		}
		if ($scope.design.bomResponse == "undefined") {
			$scope.design.bomResponse = null;
		}
		if ($scope.design.bomResponse && $scope.design.bomResponse.status  != "success"){
			// if ($scope.design.bomResponse.message && !$scope.design.bomResponse.messages){
			// 	$scope.design.bomResponse.messages = [$scope.design.bomResponse.message]
			// }
			if (!_.isArray($scope.design.bomResponse.messages)){
				$scope.design.bomResponse.messages = [$scope.design.bomResponse.message]
			}

			//now reduce
			var reducedSummaries = []

			_.each($scope.design.bomResponse.messages, function(m){
				var payload = null
				if (m && m.message && _.isString(m.type)) {
					payload =  {
						message: m.message,
						messageType: m.type,
						subtype: m.subtype,
						faulttype: m.faulttype,
						faultItems: m.faultItem ? [m.faultItem] : [],
						fixes: m.fixes,
						params: m.params,
					}
				} else {
					payload = {
						message: m,
						messageType: "error",
						subtype: m.subtype,
						faulttype: m.faulttype,
						faultItems: m.faultItem ? [m.faultItem] : [],
						fixes: m.fixes,
						params: m.params,
					}
				}
				var existing = _.find(reducedSummaries, function(o){
					return o.message == payload.message && o.messageType == o.messageType
				})
				if (existing) {
					existing.faultItems = existing.faultItems.concat(payload.faultItems)
				} else {
					reducedSummaries.push(payload)
				}
			})
			$scope.summaries = $scope.summaries.concat(reducedSummaries)
			setProcessState('failedBom', true);
		}
		if ($scope.design.calcResult && $scope.design.calcResult.Failed == false){
			_.each($scope.design.calcResult.Summary, function(s){
				$scope.summaries.push({
					message: s && s.message,
					messageType: s.type
				})
			})
			if ($scope.processState != 'failedBom') {
				$scope.processState = "successCalc";
			}
		}
		if ($scope.design.bomResponse && $scope.design.bomResponse.status  == "success"){
			if (!_.isArray($scope.design.bomResponse.messages)){
				$scope.design.bomResponse.messages = [$scope.design.bomResponse.message]
			}
			var reducedSummaries = []

			_.each($scope.design.bomResponse.messages, function(m){
				var payload = null
				if (m && m.message && _.isString(m.type)) {
					payload =  {
						message: m.message,
						messageType: m.type,
						subtype: m.subtype,
						faulttype: m.faulttype,
						faultItems: m.faultItem ? [m.faultItem] : [],
						fixes: m.fixes,
						params: m.params,
					}
				} else {
					payload = {
						message: m,
						messageType: "error",
						subtype: m.subtype,
						faulttype: m.faulttype,
						faultItems: m.faultItem ? [m.faultItem] : [],
						fixes: m.fixes,
						params: m.params,
					}
				}
				var existing = _.find(reducedSummaries, function(o){
					return o.message == payload.message && o.messageType == o.messageType
				})
				if (existing) {
					existing.faultItems = existing.faultItems.concat(payload.faultItems)
				} else {
					reducedSummaries.push(payload)
				}
			})
			$scope.summaries = $scope.summaries.concat(reducedSummaries)
			setProcessState("successBom", true);
		}
		if ($scope.design.calcResult && $scope.design.calcResult.ExhVol){
			$scope.showExhFans = true;
		}

		if ($scope.design.calcResult && $scope.design.calcResult.SupFans && $scope.design.calcResult.SupFans.length > 0){
			$scope.showSupFans = true;
		}
		if ($scope.processState == 'successBom') {
			$scope.modsManager.setMODS()
			$scope.summaries = $scope.summaries.concat($scope.modsManager.summaries)
		}
	}
	function hasManualItems(payload) {
		var items = payload.items
		return _.filter(items, function(item) {
			return item.origin == 'manual'
		})
	}
	$scope.confirmManualItems = function($event){
		var payload = scrubDesign(_.clone($scope.design))
		var manualItems = hasManualItems(payload)
		if (manualItems.length == 0) {
			$scope.calculate($event)
		} else {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			// pass things through to the other controller with the resolve method
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/confirmManualItemsModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'ConfirmManualItemsCtrl',
				backdrop: 'static',
				size: 'sm',
				resolve: {
					manualItems: function() {
						return manualItems
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function(result) {
				if (result.showBOM) {
					$scope.openVentBOM()
				} else {
					$scope.calculate($event, result.removeItems)
				}
			})
			handleModels(modalInstance)
		}
	}
	$scope.calculateBy = "silent"
	$scope.calculate = function($event, removeItems) {
		if (!removeItems) {
			removeItems = false
		}
		if ($event) {
			$scope.calculateBy = "event"
			try {
				$event.stopImmediatePropagation()
			} catch(squash){
				// console.log("$evetn.stopImmediatePropagation()",squash)
			}
			$event.preventDefault();
		} else {
			$scope.calculateBy = "silent"
		}
		$scope.ctx.httpLoading = true;
		$scope.design.bomResponse = null
		$scope.processState = "init"

		try {
			toCalcRequest();
			var payload = scrubDesign(_.clone($scope.design))
			CommandManager.createCommand($scope.design, {
				name: "Hit compute button"
			},$scope.checkDirty)
			setPayload(payload)
			DesignService.calculate(payload).$promise.then(function(res) {
				try {
					$scope.design.calcResult = res.calcResult;
					$scope.design.versionInfo = res.versionInfo
					fromCalcResult(true)
					if ($scope.design.calcResult.Failed == false) {
						if ($scope.stickySelections.isPending()) {
							confirmSticky(removeItems)
						} else {
							getBom(removeItems);
						}
					} else {
						$scope.ctx.httpLoading = false;
					}
				} catch (squash) {
					console.log("error", squash)
				}
			}).catch(function(res) {
				$scope.design.calcResult = res.data.calcResult
				$scope.design.calcResult.failure = true
				fromCalcResult(true);
				_toSummaries();
				$scope.ctx.httpLoading = false;
			});
		} catch (e) {
			_toSummaries()
			_calcError(e);
			$scope.ctx.httpLoading = false;
		}
	}
	function confirmSticky(removeItems) {
		var parentElem = angular.element(document.querySelector('#drawingsMain'));
		// pass things through to the other controller with the resolve method
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/stickySelectionsModal.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'StickySelectionsCtrl',
			backdrop: 'static',
			size: 'sm',
			resolve: {
			}
		});
		modalInstance.rendered.then(function() {
			$(".modal-content").draggable();
			var newHeight = $(window).height() * .7;
			$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
		})
		modalInstance.result.then(function(result) {
			if (result.override) {
				$scope.stickySelections.override()
				getBom(removeItems);
			} else {
				$scope.stickySelections.dismiss()
				getBom(removeItems);
			}
		})
		handleModels(modalInstance)
	}
	$scope.calcTotal = function() {
		$scope.totalPrice = 0;
		$scope.totalCount =0;
		$scope.totalCountStack = 0;
		$scope.totalPriceStack = 0;
		$scope.totalCountExhaust = 0;
		$scope.totalPriceExhaust = 0;
		$scope.totalCountSupply = 0;
		$scope.totalPriceSupply = 0;
		$scope.totalCountOther = 0;
		$scope.totalPriceOther = 0;

		_.each($scope.design.items, function(item, index) {
			var line = Util.totalLineitem(item)
			$scope.totalPrice = $scope.totalPrice + line.totalPrice
			$scope.totalCount = $scope.totalCount + line.totalCount
			$scope.totalCountStack = $scope.totalCountStack + line.totalCountStack
			$scope.totalPriceStack = $scope.totalPriceStack + line.totalPriceStack
			$scope.totalCountExhaust = $scope.totalCountExhaust + line.totalCountExhaust
			$scope.totalPriceExhaust = $scope.totalPriceExhaust + line.totalPriceExhaust
			$scope.totalCountSupply = $scope.totalCountSupply + line.totalCountSupply
			$scope.totalPriceSupply = $scope.totalPriceSupply + line.totalPriceSupply
			$scope.totalCountOther = $scope.totalCountOther + line.totalCountOther
			$scope.totalPriceOther = $scope.totalPriceOther + line.totalPriceOther
		})
	}
	function transformEnergySavings() {
		if ($scope.design.application.supportsEnergySavings) {
			if ($scope.design.energySavings) {
				var energySavings = $scope.design.energySavings
				energySavings.heatingDegreeDaysFormatted = FormatUtil.format(energySavings.heatingDegreeDays, "0,000");
				energySavings.coolingDegreeDaysFormatted = FormatUtil.format(energySavings.coolingDegreeDays, "0,000");
				energySavings.totalEnerySavingsCostFormatted = FormatUtil.format(energySavings.totalEnerySavingsCost, "0,000");
				energySavings.totalEnerySavingsTotalCo2ReductionFormatted = FormatUtil.format(energySavings.totalEnerySavingsTotalCo2Reduction, "0,000");
			}
		}
	}
	function fromBomResponse() {
		$scope.accordian.isOpen7 = true
		_toSummaries()
		if ($scope.configs.display_energySavings == 'true') {
			transformEnergySavings()
		}
		if ($scope.design.bomResponse.status != 'success') {
			return
		}
		$scope.calcTotal()
	}
	$scope.selectedBuildType = function() {
		return _.find($scope.buildTypes, function(bt){
			return bt._id == $scope.design.buildType
		})
	}
	function loadDesign(design) {
		if (design.buildType && design.buildType._id) {
			design.buildType = design.buildType._id
		}
		SystemsManager.traverseSystems(design)
		$scope.transformIds(design)
		$scope.transformGUIidents(design)
		return design
	}
	function setPayload(payload) {
		if (payload.stack) {
			payload.stack = _.clone(payload.stack)
		}
		dehydrateDesignPayload(payload)
		setLengthFitment(payload)
	}
	function dehydrateDesignPayload(payload) {
		if (payload.stack) {
			_.each(payload.stack.fittingMetas, function(fittingMeta){
				FittingMetaUtil.dehydrateFittingMeta(fittingMeta)
			})
		}
	}
	function setLengthFitment(payload) {
		if (payload.stack) {
			_.each($scope.design.fc5layout.Layout,function(v){
				if (Util.isTermination(v)) {
					var selectionMeta = SelectionMetaUtil.getSelectionMeta(v, payload, Util.getProductType('TerminationPoint',$scope.allProductTypes)._id)
					selectionMeta.lengthFitment = payload.stack.lengthFitmentLastSection
				}
			})
		}
	}
	function scrubDesign(payload) {
		if (payload.stack) {
			payload.stack.accessoryMetas = _.filter(payload.stack.accessoryMetas, function(meta){
				return meta.quantity >0
			})
		}
		if (payload.$resolved) {
			delete payload.$resolved
		}
		if (payload.$promise) {
			delete payload.$promise
		}
		if (payload.__cached_attachments) {
			delete payload.__cached_attachments
		}
		// payload.faults = $scope.validationManager.toDesignFaults()
		payload.faults = $scope.summaries
		return payload
	}
	function removeManualItems(payload) {
		var items = payload.items
		return _.filter(items, function(item) {
			return item.origin != 'manual'
		})
	}
	function getBom(removeItems) {
		if (!removeItems) {
			removeItems = false
		}
		setSystems()
		if ($scope.design.calcResult.Failed) {
			return
		}
		toBomRequest()
		$scope.ctx.httpLoading = true;
		var payload = scrubDesign(_.clone($scope.design))
		if (payload.stack) {
			payload.stack = _.clone(payload.stack)
		}

		setPayload(payload)
		if (removeItems) {
			payload.items = removeManualItems(payload)
		}
		DesignService.getBOM(payload).$promise.then(function(res) {
			$scope.design.bomResponse = res.bomResponse;
			$scope.design.items = res.items;
			$scope.design.stack = res.stack
			$scope.design.versionInfo = res.versionInfo
			$scope.design.energySavings = res.energySavings
			fromBomResponse()
			$scope.ctx.httpLoading = false;
		}).catch(function(res) {
			$scope.design.bomResponse = res.data;
			$scope.design.bomResponse.failure = true;
			$scope.design.bomResponse.status = "failure";
			fromBomResponse()
			$scope.ctx.httpLoading = false;
		});
	}

	function ListAppliance() {
		this.ID = newID();
		this.Qty = 1;
		this.Manufacturer = ""
		this.Model = ""
		this.MBTU = 0
		this.CO2 = 0
		this.Fuel = ""
	}
	$scope.removeSupplyAppl = function(supplyAppl) {
		$scope.supplyEdit = true;
		var index = $scope.design.fc5layout.Lines.indexOf(supplyAppl);
		if (index > -1) {
			$scope.design.fc5layout.Lines.splice(index, 1);
		}
	}
	$scope.addAppl = function(supplyAppl) {
		$scope.supplyEdit = true;

		var li = new ListAppliance();
		li.Qty = $scope.supplyAppl.qty;
		li.Manufacturer = $scope.supplyAppl.selectedCmbManu.company
		li.Model = $scope.supplyAppl.selectedCmbModel.model
		li.Fuel = $scope.supplyAppl.selectedCmbFuel.fuel.code
		if (li.Fuel == "") {
			li.Fuel = "NG"
		};
		li.MBTU = $scope.supplyAppl.MBTU;
		li.CO2 = $scope.supplyAppl.co2;
		if (!$scope.design.fc5layout.Lines) {
			$scope.design.fc5layout.Lines = []
		}
		$scope.design.fc5layout.Lines.push(li);
		$scope.supplyAppl = {};
	}

	function isEdited(deselect) {
		if ($scope.alwaysSavable == true) {
			return true
		}
		var result = false;
		if ($scope.design.application.computeAs != 'COM' && Edited(deselect) == true) {
			result = true;
		} else if ($scope.design.application.computeAs === 'COM') {
			if ($scope.supplyEdit === true) {
				result = true;
			} else if ($scope.cmbObj.supplyForm && $scope.cmbObj.supplyForm.$dirty) {
				result = true;
			}
		}
		return result;
	}

	function convertCanvasToImage(canvas, cb) {
		var dataURI = canvas.toDataURL("image/png", 1.0);
		// var dataURI = canvas.toDataURL("image/webp", 1.0);
		var byteString = atob(dataURI.split(',')[1]);
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		var blob = new Blob([ab], {
			type: 'image/png'
			// type: 'image/webp'
		});
		cb(blob)
	}
	$scope.doupdateQuote= function() {
		$scope.updateAfter = true;
		$scope.updateCreateQuote()
	}
	$scope.docreateQuote= function() {
		$scope.updateAfter = true;
		$scope.updateCreateQuote()
	}

	function applyGrayscale(canvas, ctx) {
		ctx.save();
		ctx.globalCompositeOperation = "color";
		ctx.fillStyle = "#808080";  // Gray color
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.restore();
	}

	function revertGrayscale(ctx) {
		ctx.globalCompositeOperation = "source-over";
	}

	function processImage() {
		var canvas = document.getElementById("myCanvas");
		var ctx = canvas.getContext("2d");

		try {
			if (canvas.msToBlob) {
				var blob = canvas.msToBlob()

				applyGrayscale(canvas, ctx);
				var grayBlob = canvas.msToBlob();
				revertGrayscale(ctx);

				editImage(blob, grayBlob)
			} else {
				var _blob
				canvas.toBlob(function(blob) {
					_blob = blob
				}, "image/png", 1.0);

				applyGrayscale(canvas, ctx);
				canvas.toBlob(function(grayBlob) {
					revertGrayscale(ctx);
					editImage(_blob, grayBlob);
				}, "image/png", 1.0);
			}
		} catch (e) {
			alert("Problem saving image for drawing, please consider using Google Chrome.")
		}
	}

	$scope.saveVersion = function(updateQuote, asNew) {
		if (!updateQuote) {
			$scope.updateAfter = false
		} else {
			$scope.updateAfter = true
		}
		$scope.ctx.asNew = asNew
		$scope.ctx.newName = $scope.design.name + " copy"
		toFc5layout();
		if ($scope.ctx.asNew || isEdited(true)) {
			if ($scope.design.application.computeAs != "COM") {
				processImage()
			} else {
				Save()
			}
		}
	}

	function editImage(blob, grayBlob) {
		var parentElem = angular.element(document.querySelector('#drawingsMain'));
		var modalInstance = $uibModal.open({
			templateUrl: 'app/main/drawings/image.cropper.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'ImageCropperController',
			size: 'lg',
			resolve: {
				file: function() {
					return blob;
				},
				grayFile: function() {
					return grayBlob
				}
			}
		});
		modalInstance.result.then(function(result) {
			Save(result.cropped)
		}).catch(function() {
			Save(blob)
		})
		handleModels(modalInstance)
	}
	function toFc5layout() {
		fromFc5layout()
		checkPoint();
		$scope.design.fc5layout.Layout = sortByGuiindent(populateDiameter($scope.design.fc5layout.Layout));
	}
	function fromFc5layout() {
		if ($scope.design.fc5layout && $scope.design.fc5layout.Created) {
			populateDiameter($scope.design.fc5layout.Layout)
		} else if ($scope.design.fc5layout && $scope.design.fc5layout.Date) {
			$scope.design.fc5layout.Created = $scope.design.fc5layout.Date
			populateDiameter($scope.design.fc5layout.Layout)
		} else {
			//set defaults
			if ($scope.design.application.computeAs === 'BWH') {
				$scope.design.fc5layout = {
					ID: "",
					Title: $scope.design.name,
					Created: new Date(),
					Application: $scope.design.application.computeAs,
					ResUnits: $scope.design.units,
					DimMode: $scope.design.units,
					ViewAngle: ($scope.design.viewMode == '2D') ? "Front" : "TopRight",
					Layout: [],
					Advanced: [],
					Intake: IntakeData(),
					Lines: []
				}
			} else if ($scope.design.application.computeAs === 'DRY') {
				$scope.design.fc5layout = {
					ID: "",
					Title: $scope.design.name,
					Created: new Date(),
					Application: $scope.design.application.computeAs,
					ResUnits: $scope.design.units,
					DimMode: $scope.design.units,
					ViewAngle: ($scope.design.viewMode == '2D') ? "Front" : "TopRight",
					Layout: [],
					Advanced: [],
					Intake: IntakeData(),
					DivFactor: 100,
					MaxShaftPres: 0.1,
					ShaftDims: 'CON',
					ComDims: 'CON',
					Lines: []
				};
			} else if ($scope.design.application.computeAs === 'COM') {
				$scope.design.fc5layout = {
					ID: "",
					DimMode: $scope.design.units,
					Application: $scope.design.application.computeAs,
					Title: $scope.design.name,
					Created: new Date(),
					Lines: [],
					Intake: IntakeData(),
					ViewAngle: ($scope.design.viewMode == '2D') ? "Front" : "TopRight"
				}
			} else {
				//blow up
				alert("missing application type!")
			}
		}
		SystemsManager.traverseSystems($scope.design)
	}

	function Save(photo) {
		checkPoint();
		toFc5layout();
		if (!$scope.design.fc5layout.Intake.Dia1) {
			$scope.design.fc5layout.Intake.Dia1 = 0
		}
		if (!$scope.design.fc5layout.Intake.Dia2) {
			$scope.design.fc5layout.Intake.Dia2 = 0
		}
		if (!$scope.design.fc5layout.Intake.Length) {
			$scope.design.fc5layout.Intake.Length = 0
		}
		if (!$scope.design.fc5layout.Intake.L90) {
			$scope.design.fc5layout.Intake.L90 = 0
		}
		if (!$scope.design.fc5layout.Intake.L45) {
			$scope.design.fc5layout.Intake.L45 = 0
		}
		if (!$scope.design.fc5layout.Intake.AddPres) {
			$scope.design.fc5layout.Intake.AddPres = 0
		}
		var payload = _.clone($scope.design);
		if (!$scope.ctx.asNew) {
			payload = _.omit(payload, "application")
		} else {
			payload.application = payload.application._id
			payload.applicationSubtype = payload.applicationSubtype && payload.applicationSubtype._id
			delete payload.__v
			delete payload._id
			delete payload.id
			delete payload.createdAt
			delete payload.updatedAt
			delete payload.createdBy
			delete payload.createdAt
			delete payload.version
		}
		payload.stack = _.clone($scope.design.stack)
		payload.stack.sectionMetas = _.map($scope.design.stack.sectionMetas, function(meta){
			var m = _.clone(meta)
			m.stackRule = Util.reduceId(m.stackRule)
			m.product = Util.reduceId(m.product)
			return m
		})
		payload.stack.accessoryMetas = _.map($scope.design.stack.accessoryMetas, function(meta){
			var m = _.clone(meta)
			m.product = Util.reduceId(m.product)
			return m
		})
		payload.stack.selectionMetas = _.map($scope.design.stack.selectionMetas, function(meta){
			var m = _.clone(meta)
			m.product = Util.reduceId(m.product)
			return m
		})
		payload.items = _.map(payload.items, function(item){
			var m = _.clone(item)
			m.product = Util.reduceId(m.product)
			m.bomRules = _.map(m.bomRules, function(o){
				return Util.reduceId(o)
			})
			m.stackRules = _.map(m.stackRules, function(o){
				return Util.reduceId(o)
			})
			return m
		})
		payload.legacyObject = Util.reduceId(payload.legacyObject)
		if (photo) {
			var fd = new FormData();
			// fd.append('asset', photo, "photo.webp");
			fd.append('asset', photo, "photo.png");
			DesignImage.save({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				designId: $scope.design._id
			}, fd).$promise.then(function(desginImage){
				payload.designImage = desginImage._id
				payload = scrubDesign(payload)
				if (!$scope.ctx.asNew) {
					setPayload(payload)
					DesignService.updateDesign(payload).$promise.then(function(res) {
						return DesignService.getDesign().$promise.then(function(design) {
							$scope.ctx.httpLoading = false;
							$scope.design = loadDesign(design);
							Util.checkSystems($scope.design, $scope.lineitemSources)
							CommandManager.clearTransactions($scope.design)
							CommandManager.createCommand($scope.design, {
								name: "Saved Design"
							},$scope.checkDirty)
							if ($scope.updateAfter) {
								$scope.updateCreateQuote()
							} else {
								checkQuotes()
								LastSave = angular.toJson(checkPoint());
								$scope.canSave = false
								$scope.supplyEdit = false;
							}
						});
					}).catch(function(err) {
						$scope.ctx.httpLoading = false;
					})
				} else {
					payload.name = $scope.ctx.newName
					setPayload(payload)
					DesignService.newDesign(payload).$promise.then(function(res) {
						$scope.ctx.httpLoading = false;
						$state.go("drawing", {
							accountId: $stateParams.accountId,
							jobId: $stateParams.jobId,
							designId: res._id,
							// version: res.version
						}, {
							
						},{
							reload: true,
							inherit: false
						})
					}).catch(function(err) {
						$scope.ctx.httpLoading = false;
					})
				}
			})
		} else {
			payload = scrubDesign(payload)
			if (!$scope.ctx.asNew) {
				setPayload(payload)
				DesignService.updateDesign(payload).$promise.then(function(res) {
					CommandManager.clearTransactions($scope.design)
					return DesignService.getDesign().$promise.then(function(design) {
						$scope.ctx.httpLoading = false;
						$scope.design = loadDesign(design);
						Util.checkSystems($scope.design, $scope.lineitemSources)
						CommandManager.clearTransactions($scope.design)
						if ($scope.updateAfter) {
							$scope.updateCreateQuote()
						} else {
							checkQuotes()
							LastSave = angular.toJson(checkPoint());
							$scope.canSave = false
							$scope.supplyEdit = false;
						}
					});
				}).catch(function(err) {
					$scope.ctx.httpLoading = false;
				})
			} else {
				payload.name = $scope.ctx.newName
				delete payload.__v
				setPayload(payload)
				DesignService.newDesign(payload).$promise.then(function(res) {
					$scope.ctx.httpLoading = false;
					$state.go("drawing", {
						accountId: $stateParams.accountId,
						jobId: $stateParams.jobId,
						designId: res._id,
						// version: res.version
					}, {

					},{
						reload: true,
						inherit: false
					})
				}).catch(function(err) {
					$scope.ctx.httpLoading = false;
				})
			}
		}
	}
	$scope.userPalette = {}
	$scope.defaultPalette = {}
	$scope.userOverrides = []
	$scope.hasUserOverrides = function(element) {
		var check = element.element ? element.element : element
		return _.includes($scope.userOverrides, check)
	}
	$scope.designOverrides = []
	$scope.hasDesignOverrides = function(element) {
		var check = element.element ? element.element : element
		return _.includes($scope.designOverrides, check)
	}
	$scope.userOverrides = []
	$scope.designOverrides = []
	function setColors() {
		Auth.getSession()
			.then(function (result) {
				return _.get(result, 'user.palette') || {}
			})
			.then(function (userPalette) {
				var applicationType = DrawingUtil.setApplicationType($scope.design.application.computeAs)
				var applicationPalette = userPalette[applicationType] || {}
				$scope.userPalette = applicationPalette;

				DrawingUtil.getDefaultPalette().then(function(paletteData) {
					var defaultDesignPreferences = paletteData[applicationType]
					$scope.defaultPalette = defaultDesignPreferences
					// console.log('defaultPalette', $scope.defaultPalette)
					_.each(_.keys(defaultDesignPreferences), function (pref) {
						var preference = defaultDesignPreferences[pref];
						if (applicationPalette[pref] && applicationPalette[pref] !== defaultDesignPreferences[pref]) {
							preference = applicationPalette[pref];
							$scope.userOverrides.push(pref)
						}
						if ($scope.design[pref] !== null && $scope.design[pref] !== undefined && $scope.design[pref] !== defaultDesignPreferences[pref]) {
							preference = $scope.design[pref];
							$scope.designOverrides.push(pref)
						}
						$scope.design[pref] = preference;
						// $scope.defaultPalette[pref] = preference
					});
				})
			});
	}
	$scope.resetDesignElement = function(element){
		var el = element.element ? element.element : element
		var designIndex = $scope.designOverrides.indexOf(el);
		var userIndex = $scope.userOverrides.indexOf(el); 

		if (designIndex !== -1) {
			if (userIndex !== -1) {
				$scope.design[el] = $scope.userPalette[el]
			} else {
				$scope.design[el] = $scope.defaultPalette[el]
			}
			$scope.designOverrides.splice(designIndex, 1);
			paint()
			return;
		}

		if (userIndex !== -1) {
			$scope.userOverrides.splice(userIndex, 1);
			$scope.design[el] = $scope.defaultPalette[el]
			paint()
			return;
		}
	}

	function _setupDesign() {
		$scope.canSave = false;
		// $scope.appliance = {};
		$scope.DrawMode = "DRAW";
		// $scope.zoomLev = 100;
		$scope.selectMode = true;
		$scope.supplyAppl = {};
		$scope.accordian = {}
		panStart = undefined;
		FromPoint = undefined;
		SelBoxStart = undefined;
		setColors()
		loadJquery();
		$q.all([
			Fitting.query({active:true}).$promise,
			Icon.query().$promise,
			VentMaterial.query().$promise,
			BuildType.query().$promise,
			ModType.query().$promise,
			ProductType.query({designator: 'Economizer'}).$promise.then(function(result) {
				return Product.query({
					type: result[0]._id,
					manufacturer: $scope.manufacturer._id
				}).$promise
			})
		]).then(function(result){
			if ($scope.design.application.computeAs != 'COM') {
				var loader = new PxLoader();
				actionImg = loader.addImage('/assets/images/drawing_images/connectorActionImg.png');
				loader.addCompletionListener(function() {
					paint();
				});
				loader.onLoad(function() {
					img.crossOrigin = "anonymous"
				})
				$scope.fittings = _.filter(result[0],function(fitting){
					var application = _.find(fitting.applications,function(as){
						return as.application._id == $scope.design.application._id
					})
					if (application){
						if (application.asset){
							fitting.image = application.asset.asset.url;
						} else {
							fitting.image = application.image
						}
						imageLoader.load(fitting.image, loader)
					}
					return application ? true : false
				})
				$scope.icons = _.filter(result[1],function(icon){
					var existing = _.find(icon.applications,function(app){
						return app._id == $scope.design.application._id
					})
					if (existing){
						if (icon.asset) {
							icon.image = icon.asset.asset.url;
						}
						imageLoader.load(icon.image, loader)
					}
					return (existing) ? true : false;
				})
				loader.start();
			}
			$scope.allVentMaterials = result[2];
			$scope.buildTypes = result[3]
			$scope.modTypes = result[4]
			$scope.economizers = result[5]

			$scope.design.application.defaultVentMaterial = _.find($scope.allVentMaterials, function(o){
				return o._id == $scope.design.application.defaultVentMaterial
			})
		}).then(function() {
			if ($scope.design.calcResult) {
				try {
					toCalcRequest()
					fromCalcResult(false)
					if ($scope.design.bomResponse) {
						toBomRequest()
						fromBomResponse()
					}
				} catch (e) {
					_toSummaries();
					_calcError(e)
				}
			}
			LastSave = angular.toJson(checkPoint());
			$scope.canSave = false
			$scope.updateCanSave = setInterval(function() {
				if ($scope.canSave) {
					return;
				}
				if (isEdited(false)) {
					if (!$scope.canSave) {
						$scope.canSave = true;
						$scope.$apply();
					}
				}
			}, 2000);
			try {
				$scope._toSelectMode()
				setSystems();
				_toSummaries()
				paint();
			} catch(e) {
				_calcError(e)
			}
		})

		$scope.cmbFuel = {
			options: []
		}
		$scope.cmbExhFanVoltage = {
			options: []
		}
		$scope.cmbSupFanVoltage = {
			options: []
		}
		$scope.cmbDamper = {
			options: []
		}

		$scope.cmbSupFans = {
			options: []
		}
		$scope.cmbControls = {
			options: []
		}

		$scope.cmbDiaSel = {
			options: [{
				value: "MAN",
				text: "Manual"
			}, {
				value: "AUT",
				text: "Automatic"
			}]
		}
		$scope.cmbDuctHeater = {
			options: [{
				value: "NO",
				text: "No"
			}, {
				value: "YES",
				text: "Yes"
			}]
		}
		$scope.cmbIntMethod = {
			options: [{
				value: "NORM",
				text: "Normal Calculation"
			}, {
				value: "IFGC",
				text: "IFGC minimum (0.35 CFM per 1000 btu)"
			}, {
				value: "IMC",
				text: "IMC minimum (1 CFM per 2400 btu)"
			}, {
				value: "MANU",
				text: "Manual Volume Entry"
			}]
		}
		$scope.cmbDryIcon = {
			options: [{
				text: '...',
				value: ''
			}, {
				text: 'Dryer',
				value: 'DRY'
			}]
		};
		$scope.selectedCMBDryIcon = $scope.cmbDryIcon.options[0];
		$scope.cmbShaftDimOptions = {
			options: [{
				id: '1',
				text: 'Constant',
				value: 'CON'
			}, {
				id: '2',
				text: 'Tapered',
				value: 'TAP'
			}]
		};
		$scope.cmbComDimOptions = {
			options: [{
				id: '1',
				text: 'No Reduction',
				value: 'CON'
			}, {
				id: '2',
				text: 'Minimize',
				value: 'MIN'
			}]
		};
		$scope.fitting1 = {
			options: []
		}
		$scope.fitting2 = {
			options: []
		}
		$scope.cmbExhFans = {
			options: [],
		};
		$scope.cmbDucting = {
			options: [{
				id: '1',
				value: "NON",
				text: "No Duct"
			}, {
				id: '2',
				value: "ROU",
				text: "Round"
			}, {
				id: '3',
				value: "REC",
				text: "Rectangular"
			}],
		};
		$scope.supplyEdit = false;
		$scope.cmbObj = {};
		if ($scope.design.application.computeAs == 'BWH') {
			$scope.cmbShape = {
				options: [{
					text: "...",
					value: ""
				}, {
					text: "Round",
					value: "ROU"
				}, {
					text: "Square",
					value: "SQU"
				}, {
					text: "Rectangular",
					value: "REC"
				}, {
					text: "Oval",
					value: "OVA"
				}]
			};
		} else {
			$scope.cmbShape = {
				options: [{
					text: "...",
					value: ""
				}, {
					text: "Round",
					value: "ROU"
				}, {
					text: "Rectangular",
					value: "REC"
				}]
			};
		}
		$scope.showSupplyForm = false;
		$scope.perspectiveMenu = false;
		$scope.customRightMenu = [
			['Copy', function($itemScope, $event, modelValue, text, $li) {
				$scope.ClickMenu("COPY")
			}],
			['Cut', function($itemScope, $event, modelValue, text, $li) {
				$scope.ClickMenu("CUT")
			}],
			['Paste', function($itemScope, $event, modelValue, text, $li) {
				$scope.ClickMenu("PASTE")
			}],
			['Remove', function($itemScope, $event, modelValue, text, $li) {
				$scope.ClickMenu("DELETE")
			}],
			['Switch Direction', function($itemScope, $event, modelValue, text, $li) {
				$scope.ClickMenu("SWITCH")
			}],
			['Select All ' + $scope.design.application.properties.appliancesLabel + "s", function($itemScope, $event, modelValue, text, $li) {
				$scope.selectionManager.selectAllAppliances()
				paint()
			}],
			['Select All Joints', function($itemScope, $event, modelValue, text, $li) {
				$scope.selectionManager.selectAllJoints()
				paint()
			}],
			['Select All Sections', function($itemScope, $event, modelValue, text, $li) {
				$scope.selectionManager.selectAllVents()
				paint()
			}],
		];
		$scope.cmbOpt = {
			options: [{
				id: '1',
				text: "...",
				value: ""
			}, {
				id: '2',
				text: "Yes",
				value: "Yes"
			}, {
				id: '3',
				text: "No",
				value: "No"
			}]
		};
		$scope.cmbHeater = {
			options: [{
				value: "NO",
				text: "No"
			}, {
				value: "YES",
				text: "Yes"
			}]
		}
		$scope.cmbUnits = {
			options: [{
				text: "IP - Standard",
				value: "IP"
			}, {
				text: "SI - Metric",
				value: "SI"
			}]
		}
		$scope.viewModeOptions = ["2D", "3D"];

		$scope.perspectiveImage = "/assets/images/drawing_images/3d-icon-front.png";
		$scope.perspectiveText = function() {
			if ($scope.design.fc5layout.ViewAngle === "Front") {
				return "Front";
			} else if ($scope.design.fc5layout.ViewAngle === "TopRight") {
				return "Top Right";
			} else if ($scope.design.fc5layout.ViewAngle === "Left") {
				return "Left";
			} else if ($scope.design.fc5layout.ViewAngle === "Right") {
				return "Right";
			} else if ($scope.design.fc5layout.ViewAngle === "TopLeft") {
				return "Top Left";
			} else {
				return "Top Plan";
			}
			return
		}
		$scope.perspectiveImage = function() {
			if ($scope.design.fc5layout.ViewAngle === "Front") {
				return "/assets/images/drawing_images/3d-icon-front.png";
			} else if ($scope.design.fc5layout.ViewAngle === "TopRight") {
				return "/assets/images/drawing_images/3d-icon-topright.png";
			} else if ($scope.design.fc5layout.ViewAngle === "Left") {
				return "/assets/images/drawing_images/3d-icon-left.png";
			} else if ($scope.design.fc5layout.ViewAngle === "Right") {
				return "/assets/images/drawing_images/3d-icon-right.png";
			} else if ($scope.design.fc5layout.ViewAngle === "TopLeft") {
				return "/assets/images/drawing_images/3d-icon-topleft.png";
			} else {
				return "/assets/images/drawing_images/3d-icon-topplan.png";
			}
		}
		if ($scope.design.application.computeAs == 'COM') {
			$scope.accordian.isOpen2 = true;
			$scope.tblDucting = true;
			$scope.numDia2 = true;
			$scope.divDiaX = true;
		}

		if ($scope.design.fc5layout) {
			fromFc5layout()
			if ($scope.design.fc5layout.Intake == null) {
				$scope.design.fc5layout.Intake = IntakeData()
			};
			// on import, if inline fan image, then no termfan image
			_.each($scope.design.fc5layout.Layout, function(item) {
				if (item.Fit1 === "INL" || item.Fit2 === "INL") {
					for (var i = 0; i < $scope.design.fc5layout.Layout.length; i++) {
						var v = $scope.design.fc5layout.Layout[i];
						v.TermFan = undefined;
					}
				}
			})
			prepLayout()
		} else {
			fromFc5layout()
			// $scope.perspectiveImage = "/assets/images/drawing_images/3d-icon-front.png";
			// $scope.perspectiveText = "Front";

			// $scope.DimYText = Util.dimXText($scope.design);
			// $scope.DimXText = Util.dimYText($scope.design);
			paint();
		}
		SelChanged();
		setDims()
	}
	$scope.faultItemName = Util.faultItemName
	$scope.openFaultItem = function(faultItem, e){
		if (e.type == "appliances"){
			$scope.selectionManager.unselectAll();
			$scope.selectionManager.selectAppliance(faultItem)
			paint()
			openSelectionModal()
		} else if (e.faulttype == "vents"){
			$scope.selectionManager.unselectAll();
			var vent = _.find($scope.design.fc5layout.Layout, function(v){
				return v.GUIidentPath == faultItem.GUIidentPath
			})
			$scope.selectionManager.selectVent(vent)
			paint()
			openSelectionModal()
		} else if (e.type == "vents"){
			$scope.selectionManager.unselectAll();
			$scope.selectionManager.selectVent(faultItem)
			paint()
			openSelectionModal()
		}
	}
	function _calcError(e, apply) {
		$scope.ctx.httpLoading = false;
		if (_.isArray(e)){
			$scope.loadErrors = e;
		} else {
			$scope.loadErrors = [e];
		}
		$scope.drawingValid = false;
		$scope.accordian.isOpen6 = true;
		$scope.accordian.isOpen7 = true;
		_toSummaries();
		if (apply){
			try {
				$scope.$apply()
			}catch(squash){
				console.log("squash _calcError")
			}
		}
	}
	var saveInterval = null,
		backgroundSaveInterval = 15;

	function backgroundSave() {
		saveInterval = setInterval(function() {
			CommandManager.setStorage("design-"+$scope.design._id, angular.toJson($scope.design), false)
		}, backgroundSaveInterval*1000)
	}
	function _loadLocal(design) {
		$scope.design = loadDesign(design);
		try {
			Util.checkSystems($scope.design, $scope.lineitemSources)
			getApplication()
			getApplianceCompanies()
			checkQuotes()
			getStackOptions()
			_setupDesign()
			backgroundSave()
			if ($scope.configs.display_energySavings == 'true') {
				createEnergySavings()
			}
			$scope.selectionManager.updateWindow()
		}catch(e){
			_calcError(e)
		}
	}
	function getApplication() {
		Application.query().$promise.then(function(result){
			$scope.design.application = _.find(result, function(application){
				return application._id == ($scope.design  &&  $scope.design.application._id)
			})
		})
	}
	$scope.stackOptionName = function(id) {
		var stackOption =  _.find($scope.stackOptions, function(o){
			return o._id == id
		})
		return (stackOption) ? stackOption.name : 'unknown'
	}
	function getStackOptions() {
		StackOption.query().$promise.then(function(result){
			$scope.stackOptions = result
			$scope.design.stack.stackOptions = _.map(result, function(stackOption){
				var existing = _.find($scope.design.stack.stackOptions, function(obj){
					return obj.type == "top-level" && obj.stackOption == stackOption._id
				})
				if (existing){
					return existing
				} else {
					return {
						stackOption: stackOption._id,
						type: 'top-level',
						included: true
					}
				}
			})
		})
	}
	function loadLocal(design){
		try {
			var json = CommandManager.getStorage("design-"+design._id, false)
			if (json){
				var existingJson = angular.toJson(design)
				if (json == angular.toJson(design)) {
					_loadLocal(design)
					CommandManager.createCommand(design, {
						name: "Loaded Design"
					},$scope.checkDirty)
					return
				}
				var parsedDesign = JSON.parse(json)
				if (parsedDesign.version != design.version){
					_loadLocal(design)
					CommandManager.createCommand(design, {
						name: "Loaded Design"
					},$scope.checkDirty)
				} else {
					var parentElem = angular.element(document.querySelector('#drawingsMain'));
					var modalInstance = $uibModal.open({
						templateUrl: 'app/main/drawings/loadLocal.html',
						scope: $scope,
						appendTo: parentElem,
						controller: 'LocalLocalController',
						size: 'sm',
						resolve: {
							parsedDesign: function() {
								return parsedDesign
							},
							design: function() {
								return design;
							}
						}
					});
					modalInstance.rendered.then(function() {
						$(".modal-content").draggable();
						var newHeight = $(window).height() * .7;
						$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
					})
					modalInstance.result.then(function(result) {
						_loadLocal(result.design)
						CommandManager.createCommand(design, {
							name: "Loaded Design"
						},$scope.checkDirty)
					}).catch(function() {
						_loadLocal(design)
						CommandManager.createCommand(design, {
							name: "Loaded Design"
						},$scope.checkDirty)
					})
					handleModels(modalInstance)
					return parsedDesign
				}
			} else {
				_loadLocal(design)
				CommandManager.createCommand(design, {
					name: "Loaded Design"
				},$scope.checkDirty)
			}
		} catch(e){
			// console.log("loadLocal() error",e)
			_loadLocal(design)
			CommandManager.createCommand(design, {
				name: "Loaded Design"
			},$scope.checkDirty)
		}
	}
	function getDesign() {
		DesignService.getDesign().$promise.then(function(design) {
			CommandManager.clearTransactions(design)
			loadLocal(design);
		});
	}

	function prepLayout() {
		if ($scope.design.application.computeAs === 'COM') {
			return;
		}
		_.each($scope.design.fc5layout.Layout, function(v) {
			Util.LoadFunctions(v);
		})
		setDrawView($scope.design.fc5layout.ViewAngle);
		// $scope.DimYText = Util.dimYText($scope.design);
		// $scope.DimXText = Util.dimXText($scope.design);
		LastSave = angular.toJson(checkPoint());
		// $scope.ClickMenu("SEL");
		$scope._toSelectMode();
		SelChanged();
		paint();
	}

	function VentSelect(vt) {
		if (vt == "") {
			return false;
		};
		var Found = false;
	}

	function MouseMove(pageX, pageY, button, caller) {
		if (!($scope.design && $scope.design.fc5layout)) {
			return
		}
		var can = document.getElementById("myCanvas");



		mPoint.X = pageX - CanvasOffset.X;
		mPoint.Y = pageY - CanvasOffset.Y;

		var Redraw = false;
		var OldPoint = {
			X: DrawPoint.X,
			Y: DrawPoint.Y
		};
		if ($scope.DrawMode == "SEL") {
			var event = $scope.insertBreakManager.toInsertBreakPoint(pageX, pageY)
			if ($scope.insertBreakManager.canInsertBreak(event)) {
				Redraw = $scope.insertBreakManager.setInsertBreeakPoint(event)
			} else {
				Redraw = $scope.insertBreakManager.setInsertBreeakPoint(null)
			}
			$scope.insertBreakManager.checkCanInsert(event)
		} else {
			Redraw = $scope.insertBreakManager.setInsertBreeakPoint(null)
			$scope.insertBreakManager.checkCanInsert(null)
		}

		switch ($scope.design.fc5layout && $scope.design.fc5layout.ViewAngle) {

			case "TopRight":
			case "TopLeft":
				DrawPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
				DrawPoint.Y = Math.round(Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev) / (50 * Tan30)) * (50 * Tan30));
				SelPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev));
				SelPoint.Y = Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev));
				break;

			default:
				DrawPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
				DrawPoint.Y = Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
				SelPoint.X = ((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev);
				SelPoint.Y = ((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev);
		}

		if ((DrawPoint.X != OldPoint.X) || (DrawPoint.Y != OldPoint.Y)) {
			Redraw = true;
		}

		if (FromPoint != undefined) {
			Redraw = true;
		}

		if (panStart != undefined) {
			Center.X = (panStart.X - mPoint.X / $scope.ZoomLev);
			Center.Y = (panStart.Y - mPoint.Y / $scope.ZoomLev);
			Redraw = true;
		} else if (MoveHandlers.length > 0) {
			ShiftX = Round50(SelPoint.X - MoveStartPoint.X)
			ShiftY = Round50(SelPoint.Y - MoveStartPoint.Y)


			var s3D = getXYZ(ShiftX, ShiftY);
			ShiftX3D = s3D.X;
			ShiftY3D = s3D.Y;
			ShiftZ3D = s3D.Z;

			IsMoving = true;

			var colMH = [];
			var colCP = [];
			for (var i = 0; i < MoveHandlers.length; i++) {
				var v = getSectionById(MoveHandlers[i].VID)
				if (v) {
					var con = false;
					for (var ii = 0; ii < MoveHandlers.length; ii++) {
						var vv = getSectionById(MoveHandlers[ii].VID)
						if (vv) {
							if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) {
								con = true;
								break;
							}
						}
					}
					if (con == false) {
						colMH.push(v)
					};
				};
			}
			_.each($scope.design.fc5layout.Layout, function(v) {
				var inCol = _.find(MoveHandlers, function(mh) {
					var vv = getSectionById(mh.VID)
					if (vv) {
						if (vv.ID == v.ID) {
							return true
						}
					}
					return false
				})
				if (inCol != true) {
					colCP.push(v)
				};
			})



			// _.each(colMH, function(v) {
			// 	var mhXY = getXY(v);
			// 	_.find(colCP, function(vv) {
			// 		var cpXY = getXY(vv);
			// 		if (mhXY.X2 == cpXY.X1 && mhXY.Y2 == cpXY.Y1) {
			// 			ShiftX3D = vv.X1 - v.X2;
			// 			ShiftY3D = vv.Y1 - v.Y2;
			// 			ShiftZ3D = vv.Z1 - v.Z2;
			// 			return true
			// 		}

			// 	})
			// })

			Redraw = true
		} else {
			if (button == 0) {
				if (SelBoxStart != undefined) {
					Redraw = true;
				}
			}
		}

		if (Redraw == true) {
			paint();
		}
	}

	function MouseDown(pageX, pageY, button, caller) {
		console.log("MouseDown", pageX, pageY, button, caller)
		var c = document.getElementById("myCanvas");
		var v = {};
		var X1;
		var X2;
		var Y1;
		var Y2;
		var i = 0;

		if ($scope.DrawMode == "DRAW") {
			if (button == 0) {
				if (FromPoint == undefined) {
					FromPoint = {
						X: DrawPoint.X,
						Y: DrawPoint.Y
					};
				}
			}
			if (button == 2) {
				FromPoint = undefined;
			}
		} else if ($scope.DrawMode == "SEL") {
			if (button == 0) {
				if (IsMoving == false) {
					var XYdiff = 1000000
					var ClickSec = ""

					_.find($scope.design.fc5layout.Layout, function(v) {
						if (v.Selected == true) {
							switch ($scope.design.fc5layout.ViewAngle) {
								case "Plan":
									X1 = v.X1
									X2 = v.X2
									Y1 = v.Z1
									Y2 = v.Z2
									break;

								case "Left":
									X1 = v.Z1
									X2 = v.Z2
									Y1 = v.Y1
									Y2 = v.Y2
									break;

								case "Front":
									X1 = v.X1
									X2 = v.X2
									Y1 = v.Y1
									Y2 = v.Y2
									break;

								case "Right":
									X1 = -v.Z1
									X2 = -v.Z2
									Y1 = v.Y1
									Y2 = v.Y2
									break;

								case "TopRight":
									X1 = v.X1 - v.Z1
									X2 = v.X2 - v.Z2
									Y1 = (v.Y1 * 2 * Tan30) + (v.X1 * Tan30) + (v.Z1 * Tan30)
									Y2 = (v.Y2 * 2 * Tan30) + (v.X2 * Tan30) + (v.Z2 * Tan30)
									break;

								case "TopLeft":
									X1 = v.X1 + v.Z1
									X2 = v.X2 + v.Z2
									Y1 = (v.Y1 * 2 * Tan30) - (v.X1 * Tan30) - (-v.Z1 * Tan30)
									Y2 = (v.Y2 * 2 * Tan30) - (v.X2 * Tan30) - (-v.Z2 * Tan30)
									break;

							}

							// Try end

							if ((X1 != X2) || (Y1 != Y2)) {

								if ((Math.abs(SelPoint.X - X1) < (10 * $scope.ZoomLev)) && (Math.abs(SelPoint.Y - Y1) < (10 * $scope.ZoomLev))) {
									MoveHandlers.push({
										VID: v.ID,
										Vend: "End1"
									})
									MoveStartPoint = {
										X: SelPoint.X,
										Y: SelPoint.Y
									};
									paint();
									return true;
								}

								if ((Math.abs(SelPoint.X - X2) < (10 * $scope.ZoomLev)) && (Math.abs(SelPoint.Y - Y2) < (10 * $scope.ZoomLev))) {
									MoveHandlers.push({
										VID: v.ID,
										Vend: "End2"
									})
									MoveStartPoint = {
										X: SelPoint.X,
										Y: SelPoint.Y
									};
									paint();
									return true;
								}
							}
						}
					})
					_.each($scope.design.fc5layout.Layout, function(v) {
						switch ($scope.design.fc5layout.ViewAngle) {
							case "Plan":
								X1 = v.X1
								X2 = v.X2
								Y1 = v.Z1
								Y2 = v.Z2
								break;

							case "Left":
								X1 = v.Z1
								X2 = v.Z2
								Y1 = v.Y1
								Y2 = v.Y2
								break;

							case "Front":
								X1 = v.X1
								X2 = v.X2
								Y1 = v.Y1
								Y2 = v.Y2
								break;

							case "Right":
								X1 = -v.Z1
								X2 = -v.Z2
								Y1 = v.Y1
								Y2 = v.Y2
								break;

							case "TopRight":
								X1 = v.X1 - v.Z1
								X2 = v.X2 - v.Z2
								Y1 = parseInt((v.Y1 * 2 * Tan30) + (v.X1 * Tan30) + (v.Z1 * Tan30))
								Y2 = parseInt((v.Y2 * 2 * Tan30) + (v.X2 * Tan30) + (v.Z2 * Tan30))
								break;

							case "TopLeft":
								X1 = v.X1 + v.Z1
								X2 = v.X2 + v.Z2
								Y1 = parseInt((v.Y1 * 2 * Tan30) - (v.X1 * Tan30) - (-v.Z1 * Tan30))
								Y2 = parseInt((v.Y2 * 2 * Tan30) - (v.X2 * Tan30) - (-v.Z2 * Tan30))
								break;
						}
						//Try whole section
						if ((X1 != X2) || (Y1 != Y2)) {
							var Slope = 0
							var IntX = 0
							var IntY = 0

							if ((Math.abs(X1 - X2) == 0) && (Math.abs(Y2 - Y1) > 0)) {
								//Section is vertical
								IntX = X1
								IntY = SelPoint.Y
								if ((Y2 > Y1 && Y2 > SelPoint.Y && Y1 < SelPoint.Y) || (Y1 > Y2 && Y1 > SelPoint.Y && Y2 < SelPoint.Y)) {
									if (Math.abs(IntX - SelPoint.X) < (20 * $scope.ZoomLev) && Math.abs(IntY - SelPoint.Y) < (20 * $scope.ZoomLev) && Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y) < XYdiff) {
										ClickSec = v.ID
										XYdiff = Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y)
									}
								}
							} else if (Math.abs(X1 - X2) > 0 && Math.abs(Y2 - Y1) == 0) {
								//Section is horizontal
								IntX = SelPoint.X
								IntY = Y1
								if ((X2 > X1 && X2 > SelPoint.X && X1 < SelPoint.X) || (X1 > X2 && X1 > SelPoint.X && X2 < SelPoint.X)) {
									if (Math.abs(IntX - SelPoint.X) < (20 * $scope.ZoomLev) && Math.abs(IntY - SelPoint.Y) < (20 * $scope.ZoomLev) && Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y) < XYdiff) {
										ClickSec = v.ID
										XYdiff = Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y)
									}
								}
							} else {
								Slope = (Y2 - Y1) / (X2 - X1)
								IntX = X1 + (SelPoint.X - X1)
								IntY = Y1 + (SelPoint.X - X1) * Slope

								if (((X2 > X1 && X2 > SelPoint.X && X1 < SelPoint.X) || (X1 > X2 && X1 > SelPoint.X && X2 < SelPoint.X)) && ((Y2 > Y1 && Y2 > SelPoint.Y && Y1 < SelPoint.Y) || (Y1 > Y2 && Y1 > SelPoint.Y && Y2 < SelPoint.Y))) {
									if (Math.abs(IntX - SelPoint.X) < (20 * $scope.ZoomLev) && Math.abs(IntY - SelPoint.Y) < (20 * $scope.ZoomLev) && Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y) < XYdiff) {
										ClickSec = v.ID
										XYdiff = Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y)
									}
								}
							}
						}
					})

					if (ClickSec != "") {
						v = getSectionById(ClickSec)
						$scope.orderedSelection.push(v)
						if (v.Selected == true) {
							MoveStartPoint = {
								X: SelPoint.X,
								Y: SelPoint.Y
							};
							_.each($scope.design.fc5layout.Layout, function(vv) {
								if (vv.Selected == true) {
									MoveHandlers.push({
										VID: vv.ID,
										Vend: "Full"
									})
								}
							})

							// remove from orderedSelection
							$scope.orderedSelection = $scope.orderedSelection.filter(function(item) {
								return item.ID !== v.ID
							});
						}
					} else {
						SelBoxStart = {
							X: SelPoint.X,
							Y: SelPoint.Y
						};
					}
					paint();
				}
			}

			if (button == 2) {
				FromPoint = undefined;
			}
			paint();

			if (button == 1) {
				panStart = {
					X: Center.X + mPoint.X / $scope.ZoomLev,
					Y: Center.Y + mPoint.Y / $scope.ZoomLev
				};
			}
		}
	}
	$scope._MouseUpNone = function(pageX, pageY, button, ctrlKey, shiftKey, caller, originalSection, firstBreak) {
		$scope._MouseUp(pageX, pageY, button, ctrlKey, shiftKey, caller, originalSection, firstBreak, true)
	}
	$scope.MouseUp = function(pageX, pageY, button, ctrlKey, shiftKey, caller) {
		console.log("MouseUp()", pageX, pageY, button, ctrlKey, shiftKey, caller)
		console.log("DrawMode: " + $scope.DrawMode)
		$scope._MouseUp(pageX, pageY, button, ctrlKey, shiftKey, caller);
		$scope._validate(true);
	}

	$scope._MouseUp = function(pageX, pageY, button, ctrlKey, shiftKey, caller, originalSection, firstBreak, reset) {
		$scope.insertBreakManager.actualPoint = $scope.insertBreakManager.toInsertBreakPoint(pageX,pageY)
		panStart = undefined;
		// checkPoint();
		var canvas = document.getElementById("myCanvas");
		var context = canvas.getContext("2d");

		var X1 = 0;
		var X2 = 0;
		var Y1 = 0;
		var Y2 = 0;
		var Z1 = 0;
		var Z2 = 0;
		var XPoint = {};
		var YPoint = {};
		var ZPoint = {};
		var vv = {};
		var v = {};
		var fX1 = 0;
		var fX2 = 0;
		var fY1 = 0;
		var fY2 = 0;
		var i = 0;
		var mh = {};
		if ($scope.DrawMode == "DRAW") {
			if (button == 0) {
				if (FromPoint != undefined) {
					if (FromPoint.X != DrawPoint.X || FromPoint.Y != DrawPoint.Y) {
						//Create new
						var ToSec = undefined;
						switch ($scope.design.fc5layout.ViewAngle) {
							case "Plan":
								X1 = FromPoint.X
								X2 = DrawPoint.X
								Z1 = FromPoint.Y
								Z2 = DrawPoint.Y
								YPoint = GetY()
								Y1 = YPoint.X
								Y2 = YPoint.Y
								break;
							case "Left":
								Z1 = FromPoint.X
								Z2 = DrawPoint.X
								Y1 = FromPoint.Y
								Y2 = DrawPoint.Y
								XPoint = GetX()
								X1 = XPoint.X
								X2 = XPoint.Y
								break;
							case "Front":
								X1 = FromPoint.X
								X2 = DrawPoint.X
								Y1 = FromPoint.Y
								Y2 = DrawPoint.Y
								ZPoint = GetZ()
								Z1 = ZPoint.X
								Z2 = ZPoint.Y
								break;
							case "Right":
								Z1 = -FromPoint.X
								Z2 = -DrawPoint.X
								Y1 = FromPoint.Y
								Y2 = DrawPoint.Y
								XPoint = GetX()
								X1 = XPoint.X
								X2 = XPoint.Y
								break;
							case "TopRight":
								var Angle = GetAngle(0, 0, FromPoint.X, FromPoint.Y)
								var LengthX = Math.abs(FromPoint.X)
								var LengthY = Math.abs(FromPoint.Y) / Tan30 / 2
								var FromSec = ""
								_.find($scope.design.fc5layout.Layout, function(vv) {
									fX1 = vv.X1 - vv.Z1
									fX2 = vv.X2 - vv.Z2
									fY1 = Math.round((vv.Y1 * 2 * Tan30) + (vv.X1 * Tan30) + (vv.Z1 * Tan30))
									fY2 = Math.round((vv.Y2 * 2 * Tan30) + (vv.X2 * Tan30) + (vv.Z2 * Tan30))
									if (FromPoint.X == fX1 && FromPoint.Y == fY1) {
										X1 = vv.X1
										Y1 = vv.Y1
										Z1 = vv.Z1
										FromSec = vv.ID
										return true;
									}
									if (FromPoint.X == fX2 && FromPoint.Y == fY2) {
										X1 = vv.X2
										Y1 = vv.Y2
										Z1 = vv.Z2
										FromSec = vv.ID
										return true;
									}
								})

								_.find($scope.design.fc5layout.Layout, function(vv) {
									fX1 = vv.X1 - vv.Z1
									fX2 = vv.X2 - vv.Z2
									fY1 = Math.round((vv.Y1 * 2 * Tan30) + (vv.X1 * Tan30) + (vv.Z1 * Tan30))
									fY2 = Math.round((vv.Y2 * 2 * Tan30) + (vv.X2 * Tan30) + (vv.Z2 * Tan30))
									if (DrawPoint.X == fX1 && DrawPoint.Y == fY1) {
										ToSec = {
											X: vv.X1,
											Y: vv.Y1,
											Z: vv.Z1
										};
										return true;
									}
									if (DrawPoint.X == fX2 && DrawPoint.Y == fY2) {
										ToSec = {
											X: vv.X2,
											Y: vv.Y2,
											Z: vv.Z2
										};
										return true;
									}
								})

								if (FromSec == "") {
									var c = getXYZ(FromPoint.X, FromPoint.Y);
									X1 = c.X;
									Y1 = c.Y;
									Z1 = c.Z;

								}

								Angle = GetAngle(FromPoint.X, FromPoint.Y, DrawPoint.X, DrawPoint.Y)
								Angle = Math.round(Angle / 5) * 5
								LengthX = Math.abs(DrawPoint.X - FromPoint.X)
								LengthY = Round50(Math.abs(DrawPoint.Y - FromPoint.Y) / Tan30 / 2)

								switch (Angle) {
									case 0:
										Z2 = Round50(Z1 - LengthX / 2)
										X2 = Round50(X1 + LengthX / 2)
										Y2 = Y1
										break;
									case 30:
										Z2 = Z1
										X2 = Round50(X1 + LengthX)
										Y2 = Y1
										break;
									case 90:
										X2 = X1
										Y2 = Round50(Y1 + LengthY)
										Z2 = Z1
										break;
									case 150:
										Z2 = Round50(Z1 + LengthX)
										X2 = X1
										Y2 = Y1
										break;
									case 180:
										Z2 = Round50(Z1 + LengthX / 2)
										X2 = Round50(X1 - LengthX / 2)
										Y2 = Y1
										break;
									case 210:
										Z2 = Z1
										X2 = Round50(X1 - LengthX)
										Y2 = Y1
										break;
									case 270:
										X2 = X1
										Y2 = Round50(Y1 - LengthY)
										Z2 = Z1
										break;
									case 330:
										Z2 = Round50(Z1 - LengthX)
										X2 = X1
										Y2 = Y1
										break;
									default:
										alert("Please draw lines at angles where the line is black or switch to one of the 2D views.")
										FromPoint = undefined;
										return;
								}
								break;
							case "TopLeft":
								Angle = GetAngle(0, 0, FromPoint.X, FromPoint.Y)
								LengthX = Math.abs(FromPoint.X)
								LengthY = Math.abs(FromPoint.Y) / Tan30 / 2
								FromSec = ""
								_.find($scope.design.fc5layout.Layout, function(vv) {
									fX1 = vv.X1 + vv.Z1
									fX2 = vv.X2 + vv.Z2
									fY1 = Math.round((vv.Y1 * 2 * Tan30) - (vv.X1 * Tan30) - (-vv.Z1 * Tan30))
									fY2 = Math.round((vv.Y2 * 2 * Tan30) - (vv.X2 * Tan30) - (-vv.Z2 * Tan30))
									if (FromPoint.X == fX1 && FromPoint.Y == fY1) {
										X1 = vv.X1
										Y1 = vv.Y1
										Z1 = vv.Z1
										FromSec = vv.ID
										return true;
									}
									if (FromPoint.X == fX2 && FromPoint.Y == fY2) {
										X1 = vv.X2
										Y1 = vv.Y2
										Z1 = vv.Z2
										FromSec = vv.ID
										return true;
									}
								})
								_.find($scope.design.fc5layout.Layout, function(vv) {
									fX1 = vv.X1 + vv.Z1
									fX2 = vv.X2 + vv.Z2
									fY1 = Math.round((vv.Y1 * 2 * Tan30) - (vv.X1 * Tan30) - (-vv.Z1 * Tan30))
									fY2 = Math.round((vv.Y2 * 2 * Tan30) - (vv.X2 * Tan30) - (-vv.Z2 * Tan30))
									if (DrawPoint.X == fX1 && DrawPoint.Y == fY1) {
										ToSec = {
											X: vv.X1,
											Y: vv.Y1,
											Z: vv.Z1
										};
										return true;
									}
									if (DrawPoint.X == fX2 && DrawPoint.Y == fY2) {
										ToSec = {
											X: vv.X2,
											Y: vv.Y2,
											Z: vv.Z2
										};
										return true;
									}
								})

								if (FromSec == "") {
									var c = getXYZ(FromPoint.X, FromPoint.Y);
									X1 = c.X;
									Y1 = c.Y;
									Z1 = c.Z;

								}

								Angle = GetAngle(FromPoint.X, FromPoint.Y, DrawPoint.X, DrawPoint.Y)
								Angle = Math.round(Angle / 5) * 5
								LengthX = Math.abs(DrawPoint.X - FromPoint.X)
								LengthY = Round50(Math.abs(DrawPoint.Y - FromPoint.Y) / Tan30 / 2)

								switch (Angle) {
									case 0:
										X2 = Round50(X1 + LengthX / 2)
										Z2 = Round50(Z1 + LengthX / 2)
										Y2 = Y1
										break;
									case 30:
										X2 = X1
										Z2 = Round50(Z1 + LengthX)
										Y2 = Y1
										break;
									case 90:
										Z2 = Z1
										Y2 = Round50(Y1 + LengthY)
										X2 = X1
										break;
									case 150:
										X2 = Round50(X1 - LengthX)
										Z2 = Z1
										Y2 = Y1
										break;
									case 180:
										X2 = Round50(X1 - LengthX / 2)
										Z2 = Round50(Z1 - LengthX / 2)
										Y2 = Y1
										break;
									case 210:
										X2 = X1
										Z2 = Round50(Z1 - LengthX)
										Y2 = Y1
										break;
									case 270:
										X2 = X1
										Y2 = Round50(Y1 - LengthY)
										Z2 = Z1
										break;
									case 330:
										X2 = Round50(X1 + LengthX)
										Z2 = Z1
										Y2 = Y1
										break;
									default:
										alert("Please draw lines at angles where the line is black or switch to one of the 2D views.")
										FromPoint = undefined;
										return;
								}
								break;
						}

						//Auto rotate if drawn downwards
						// if ((Y1 < Y2 && X1 == X2 && Z1 == Z2) || drawnFromInlet(X1, Y1, Z1)) {
						// 	var X1t = X1;
						// 	var Y1t = Y1;
						// 	var Z1t = Z1;
						// 	X1 = X2;
						// 	Y1 = Y2;
						// 	Z1 = Z2;
						// 	X2 = X1t;
						// 	Y2 = Y1t;
						// 	Z2 = Z1t;
						// }
						var created = null
						if (!originalSection) {
							v = Util.createSection(X1, Y1, X2, Y2, Z1, Z2, $scope.design)
							created = v
						} else {
							v = Util.createSectionFromBreak(X1, Y1, X2, Y2, Z1, Z2, $scope.design, originalSection, firstBreak)
						}

						if (AllowDrop(v, true) == true) {
							DoUndo();
							_.each($scope.design.fc5layout.Layout, function(vv) {
								// vv.Selected = false
								// console.log("AllowDrop unselect", vv.GUIident)
								$scope.selectionManager.unselectVent(vv)
							})
							// console.log("AllowDrop select", v.GUIident)
							// v.Selected = true
							$scope.design.fc5layout.Layout.push(v)
							//Does it end in another?
							$scope.selectionManager.selectVent(v)
							if ($scope.design.fc5layout.ViewAngle == "TopLeft" || $scope.design.fc5layout.ViewAngle == "TopRight") {
								if (ToSec) {
									var col = getUpStream(v);
									var sX = ToSec.X - v.X2;
									var sY = ToSec.Y - v.Y2;
									var sZ = ToSec.Z - v.Z2;
									for (var ii = 0; ii < col.length; ii++) {
										col[ii].X1 += sX;
										col[ii].X2 += sX;
										col[ii].Y1 += sY;
										col[ii].Y2 += sY;
										col[ii].Z1 += sZ;
										col[ii].Z2 += sZ;
									}
								}
							}
							SetSameFittings();
							FromPoint = undefined
							autoFit();
							SelChanged();
							if (created) {
								$scope.sectionManager.onCreated(v)
							}
							CommandManager.createCommand($scope.design, {
								name: "Draw mouse up"
							},$scope.checkDirty)
						}
					}
				}
			}
		} else if ($scope.DrawMode == "SEL") {
			if (button == 0) {
				if (IsMoving == false) {
					var XYdiff = 1000000
					var ClickSec = ""
					var ClickAppl = "";
					_.each($scope.design.fc5layout.Layout, function(v) {
						switch ($scope.design.fc5layout.ViewAngle) {
							case "Plan":
								X1 = v.X1
								X2 = v.X2
								Y1 = v.Z1
								Y2 = v.Z2
								break;
							case "Left":
								X1 = v.Z1
								X2 = v.Z2
								Y1 = v.Y1
								Y2 = v.Y2
								break;
							case "Front":
								X1 = v.X1
								X2 = v.X2
								Y1 = v.Y1
								Y2 = v.Y2
								break;
							case "Right":
								X1 = -v.Z1
								X2 = -v.Z2
								Y1 = v.Y1
								Y2 = v.Y2
								break;
							case "TopRight":
								X1 = v.X1 - v.Z1
								X2 = v.X2 - v.Z2
								Y1 = parseInt((v.Y1 * 2 * Tan30) + (v.X1 * Tan30) + (v.Z1 * Tan30))
								Y2 = parseInt((v.Y2 * 2 * Tan30) + (v.X2 * Tan30) + (v.Z2 * Tan30))
								break;
							case "TopLeft":
								X1 = v.X1 + v.Z1
								X2 = v.X2 + v.Z2
								Y1 = parseInt((v.Y1 * 2 * Tan30) - (v.X1 * Tan30) - (-v.Z1 * Tan30))
								Y2 = parseInt((v.Y2 * 2 * Tan30) - (v.X2 * Tan30) - (-v.Z2 * Tan30))
								break;
						}
						if (X1 != X2 || Y1 != Y2) {
							// appliance click
							var joint_sensitivity = 5
							if (reset) {
								//do nothing
							} else if (v.Fit1 == "STP") {
								joint_sensitivity = parseInt($scope.configs.joint_sensitivity_stp)
								var sen
								if (Math.abs(X1 - SelPoint.X) < (joint_sensitivity * $scope.ZoomLev) && Math.abs(Y1 - SelPoint.Y) < (joint_sensitivity * $scope.ZoomLev)) {
									ClickAppl = v.ID
								}

								// end click
							} else if (v.Fit1 == "ECM") {
								joint_sensitivity = parseInt($scope.configs.joint_sensitivity_ecm)
								if (Math.abs(X1 - SelPoint.X) < (joint_sensitivity * $scope.ZoomLev) && Math.abs(Y1 - SelPoint.Y) < (joint_sensitivity * $scope.ZoomLev)) {
									ClickAppl = v.ID
								}
							} else {
								joint_sensitivity = parseInt($scope.configs.joint_sensitivity_default)
								if (Math.abs(X1 - SelPoint.X) < (joint_sensitivity * $scope.ZoomLev) && Math.abs(Y1 - SelPoint.Y) < (joint_sensitivity * $scope.ZoomLev)) {
									ClickAppl = v.ID
								}
							}
							var Slope = 0
							var IntX = 0
							var IntY = 0
							if (Math.abs(X1 - X2) == 0 && Math.abs(Y2 - Y1) > 0) {
								// Section is vertical
								IntX = X1
								IntY = SelPoint.Y
								if ((Y2 > Y1 && Y2 > SelPoint.Y && Y1 < SelPoint.Y) || (Y1 > Y2 && Y1 > SelPoint.Y && Y2 < SelPoint.Y)) {
									if (Math.abs(IntX - SelPoint.X) < (20 * $scope.ZoomLev) && Math.abs(IntY - SelPoint.Y) < (20 * $scope.ZoomLev) && Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y) < XYdiff) {
										ClickSec = v.ID
										XYdiff = Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y)
									}
								}
							} else if (Math.abs(X1 - X2) > 0 && Math.abs(Y2 - Y1) == 0) {
								// Section is horizontal
								IntX = SelPoint.X
								IntY = Y1

								if ((X2 > X1 && X2 > SelPoint.X && X1 < SelPoint.X) ||
									(X1 > X2 && X1 > SelPoint.X && X2 < SelPoint.X)) {
									if (Math.abs(IntX - SelPoint.X) < (20 * $scope.ZoomLev) && Math.abs(IntY - SelPoint.Y) < (20 * $scope.ZoomLev) && Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y) < XYdiff) {
										ClickSec = v.ID
										XYdiff = Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y)
									}
								}
							} else {
								Slope = (Y2 - Y1) / (X2 - X1)
								IntX = X1 + (SelPoint.X - X1)
								IntY = Y1 + (SelPoint.X - X1) * Slope
								if (((X2 > X1 && X2 > SelPoint.X && X1 < SelPoint.X) || (X1 > X2 && X1 > SelPoint.X && X2 < SelPoint.X)) && ((Y2 > Y1 && Y2 > SelPoint.Y && Y1 < SelPoint.Y) || (Y1 > Y2 && Y1 > SelPoint.Y && Y2 < SelPoint.Y))) {
									if (Math.abs(IntX - SelPoint.X) < (20 * $scope.ZoomLev) && Math.abs(IntY - SelPoint.Y) < (20 * $scope.ZoomLev) && Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y) < XYdiff) {
										ClickSec = v.ID
										XYdiff = Math.abs(IntX - SelPoint.X) + Math.abs(IntY - SelPoint.Y)
									}
								}
							}
						}
					})
					if (ClickAppl != "") {
						if (!ctrlKey && !shiftKey) {
							_.each($scope.design.fc5layout.Layout, function(v) {
								// console.log("unselect ClickAppl not blank", ClickAppl, v.GUIident)
								$scope.selectionManager.unselectVent(v)
								// v.Selected = false;
								if (v.ID != ClickAppl) {
									if (v.Appl) {
										// console.log("unselect ClickAppl not blank unselect appl",ClickAppl,  v.Appl.GUIident)
										$scope.selectionManager.unselectAppliance(v)
										$scope.orderedAppliances = $scope.orderedAppliances.filter(function(item) {
											return item.ID !== v.ID
										});

										// v.Appl.Selected = false
									}
								}
							})
						} else if (shiftKey == true) {
							var selectedElements = getSelectedCol();
						}
						var cl = getSectionById(ClickAppl)
						if (cl.Appl == undefined) {
							// console.log("create Appliance")
							cl.Appl = Util.createAppliance($scope.design)
							$scope.selectionManager.updateWindow()
						}
						// console.log("toggle ClickAppl not blank", ClickAppl, cl.Appl.GUIident)
						$scope.selectionManager.toggleAppliance(cl)
						// cl.Appl.Selected = !cl.Appl.Selected
						SelBoxStart = undefined;
					} else if (ClickSec != "") {
						if (!ctrlKey && !shiftKey) {
							_.each($scope.design.fc5layout.Layout, function(v) {
								if (v.Appl) {
									// console.log("ClickSec not blank unselect appl", ClickSec, v.Appl.GUIident)
									$scope.selectionManager.unselectAppliance(v)
									$scope.orderedAppliances = $scope.orderedAppliances.filter(function(item) {
										return item.ID !== v.ID
									});

									// v.Appl.Selected = false
								}
								if (v.ID != ClickSec) {
									// console.log("ClickSec not blank unselectVent", ClickSec, v.GUIident)
									$scope.selectionManager.unselectVent(v)
									// v.Selected = false
								}
							})
						} else if (shiftKey == true) {
							var selectedElements = getSelectedCol();
						}
						var cl = getSectionById(ClickSec)
						// console.log("ClickSec not blank toggleVent", ClickSec, cl.GUIident)
						$scope.selectionManager.toggleVent(cl)
						// cl.Selected = !cl.Selected;
						SelBoxStart = undefined;
					} else {
						if (SelBoxStart == undefined) {
							_.each($scope.design.fc5layout.Layout, function(v) {
								if (v.Appl) {
									// console.log("not ClickSec or ClickSec unselect appliance", v.Appl.GUIident)
									$scope.selectionManager.unselectAppliance(v)
									$scope.orderedAppliances = $scope.orderedAppliances.filter(function(item) {
										return item.ID !== v.ID
									});

									// v.Appl.Selected = false
								}
								// console.log("not ClickSec or ClickSec unselect vent", v.GUIident)
								$scope.selectionManager.unselectVent(v)
								// v.Selected = false
							})
						}
					}
					SelChanged(caller);
				}

				if (MoveHandlers.length > 0) {
					var Proceed = true
					_.find(MoveHandlers, function(mh) {
						var dsec = getSectionById(mh.VID)
						if (AllowDrop(dsec, true) == false) {
							Proceed = false
							return true;
						}
					})

					var s3D = getXYZ(ShiftX, ShiftY);
					ShiftX3D = s3D.X;
					ShiftY3D = s3D.Y;
					ShiftZ3D = s3D.Z;

					if (Proceed == true) {
						DoUndo();
						for (i = 0; i < MoveHandlers.length; i++) {
							mh = MoveHandlers[i]
							v = getSectionById(mh.VID)
							switch ($scope.design.fc5layout.ViewAngle) {
								case "Plan":
									if (mh.Vend == "Full") {
										v.X1 += ShiftX
										v.X2 += ShiftX
										v.Z1 += ShiftY
										v.Z2 += ShiftY
									} else if (mh.Vend == "End1") {
										v.X1 += ShiftX
										v.Z1 += ShiftY
									} else if (mh.Vend == "End2") {
										v.X2 += ShiftX
										v.Z2 += ShiftY
									}
									break;
								case "Left":
									if (mh.Vend == "Full") {
										v.Y1 += ShiftY
										v.Y2 += ShiftY
										v.Z1 += ShiftX
										v.Z2 += ShiftX
									} else if (mh.Vend == "End1") {
										v.Y1 += ShiftY
										v.Z1 += ShiftX
									} else if (mh.Vend == "End2") {
										v.Y2 += ShiftY
										v.Z2 += ShiftX
									}
									break;
								case "Front":
									if (mh.Vend == "Full") {
										v.X1 += ShiftX
										v.X2 += ShiftX
										v.Y1 += ShiftY
										v.Y2 += ShiftY
									} else if (mh.Vend == "End1") {
										v.X1 += ShiftX
										v.Y1 += ShiftY
									} else if (mh.Vend == "End2") {
										v.X2 += ShiftX
										v.Y2 += ShiftY
									}
									break;

								case "Right":
									if (mh.Vend == "Full") {
										v.Y1 += ShiftY
										v.Y2 += ShiftY
										v.Z1 -= ShiftX
										v.Z2 -= ShiftX
									} else if (mh.Vend == "End1") {
										v.Y1 += ShiftY
										v.Z1 -= ShiftX
									} else if (mh.Vend == "End2") {
										v.Y2 += ShiftY
										v.Z2 -= ShiftX
									}
									break;

								case "TopRight":
									if (mh.Vend == "Full") {
										v.X1 += ShiftX3D
										v.X2 += ShiftX3D
										v.Y1 += ShiftY3D
										v.Y2 += ShiftY3D
										v.Z1 += ShiftZ3D
										v.Z2 += ShiftZ3D
									} else if (mh.Vend == "End1") {
										v.X1 += ShiftX3D
										v.Y1 += ShiftY3D
										v.Z1 += ShiftZ3D
									} else if (mh.Vend == "End2") {
										v.X2 += ShiftX3D
										v.Y2 += ShiftY3D
										v.Z2 += ShiftZ3D
									}
									break;

								case "TopLeft":
									if (mh.Vend == "Full") {
										v.X1 += ShiftX3D
										v.X2 += ShiftX3D
										v.Y1 += ShiftY3D
										v.Y2 += ShiftY3D
										v.Z1 += ShiftZ3D
										v.Z2 += ShiftZ3D
									} else if (mh.Vend == "End1") {
										v.X1 += ShiftX3D
										v.Y1 += ShiftY3D
										v.Z1 += ShiftZ3D
									} else if (mh.Vend == "End2") {
										v.X2 += ShiftX3D
										v.Y2 += ShiftY3D
										v.Z2 += ShiftZ3D
									}
									break;
							}
						}
						ShiftX = 0
						ShiftY = 0
						ShiftX3D = 0
						ShiftY3D = 0
						ShiftZ3D = 0
						MoveHandlers.length = 0;
						MoveStartPoint = undefined
						IsMoving = false
						autoFit();
						SetSameFittings();
					}
				}

				if (SelBoxStart != undefined) {
					//Look for them
					if (!ctrlKey) {
						$scope.selectionManager.unselectAll();
						// _.each($scope.design.fc5layout.Layout, function(v) {
						// 	$scope.selectionManager.unselectVent(v)
						// 	// v.Selected = false
						// 	if (v.Appl) {
						// 		$scope.selectionManager.unselectAppliance(v)
						// 		// v.Appl.Selected = false
						// 	};
						// })
					}

					var sX1 = SelBoxStart.X
					if (SelPoint.X < sX1) {
						sX1 = SelPoint.X
					}
					var sY1 = SelBoxStart.Y
					if (SelPoint.Y < sY1) {
						sY1 = SelPoint.Y
					}
					var sX2 = sX1 + Math.abs(SelBoxStart.X - SelPoint.X)
					var sY2 = sY1 + Math.abs(SelBoxStart.Y - SelPoint.Y)


					var vs = _.filter($scope.design.fc5layout.Layout, function(v) {
						switch ($scope.design.fc5layout.ViewAngle) {
							case "Plan":
								X1 = v.X1
								X2 = v.X2
								Y1 = v.Z1
								Y2 = v.Z2
								break;
							case "Left":
								X1 = v.Z1
								X2 = v.Z2
								Y1 = v.Y1
								Y2 = v.Y2
								break;
							case "Front":
								X1 = v.X1
								X2 = v.X2
								Y1 = v.Y1
								Y2 = v.Y2
								break;
							case "Right":
								X1 = -v.Z1
								X2 = -v.Z2
								Y1 = v.Y1
								Y2 = v.Y2
								break;
							case "TopRight":
								X1 = v.X1 - v.Z1
								X2 = v.X2 - v.Z2
								Y1 = (v.Y1 * 2 * Tan30) + (v.X1 * Tan30) + (v.Z1 * Tan30)
								Y2 = (v.Y2 * 2 * Tan30) + (v.X2 * Tan30) + (v.Z2 * Tan30)
								break;
							case "TopLeft":
								X1 = v.X1 + v.Z1
								X2 = v.X2 + v.Z2
								Y1 = (v.Y1 * 2 * Tan30) - (v.X1 * Tan30) - (-v.Z1 * Tan30)
								Y2 = (v.Y2 * 2 * Tan30) - (v.X2 * Tan30) - (-v.Z2 * Tan30)
								break;
						}
						if ((sX1 < X1 && sX2 > X1 && sY1 < Y1 && sY2 > Y1) || (sX1 < X2 && sX2 > X2 && sY1 < Y2 && sY2 > Y2)) {
							return true
							// v.Selected = true
						};
					})
					var bottomLeftToTopRight = SelPoint.X <SelBoxStart.X && SelPoint.Y < SelBoxStart.Y;
					// console.log("bottomLeftToTopRight: "+bottomLeftToTopRight + " SelBoxStart, SelPoint",SelBoxStart, SelPoint)
					$scope.selectionManager.selectPan(vs, bottomLeftToTopRight)
					SelBoxStart = undefined;
					SelChanged();
				}
			}
		}
		paint();
	}

	function drawnFromInlet(X1, Y1, Z1) {
		var found = _.find($scope.design.fc5layout.Layout, function(v) {
			return (v.X1 == X1 && v.Y1 == Y1 && v.Z1 == Z1)
		})
		return found ? true : false;
	}
	$scope.stopMoving = function() {
		IsMoving = false;
		SelBoxStart = undefined;
		MoveHandlers = [];
	}
	$scope.afterModalDismiss = function() {
		console.log("afterModalDismiss start")
		$scope.stopMoving()
		$scope._toSelectMode()
		console.log("afterModalDismiss done")
	}
	$scope._toSelectMode = function() {
		FromPoint = undefined;
		$scope.DrawMode = "SEL"
		$scope.drawMode = false;
		$scope.selectMode = true;
		// FromPoint = undefined;
	}
	function openSelectionModal() {
		var selected = $scope.selectionManager.getSelected();
		if (selected.hasMixed){
			openError()
			return
		} else if (selected.joints.length > 0){
			openJoints()
			return
		} else if (selected.vents.length > 0){
			openVentModal()
		} else if (selected.appliances.length > 0){
			openApplModal()
		} else {
			openError()
			return
		}
	}
	function setSelectionBtnName() {
		var selected = $scope.selectionManager.getSelected();
		
		if (selected.hasMixed){
			$scope.selectionBtnName = 'Mixed Selection'
		} else if (selected.joints.length > 0){
			$scope.selectionBtnName = 'Joint Details'
		} else if (selected.vents.length > 0){
			$scope.selectionBtnName = 'Vent Details'
		} else if (selected.appliances.length > 0){
			$scope.selectionBtnName = 'Appliance Details'
		} else {
			$scope.selectionBtnName = null
		}
	}
	$scope.openSelectionModal = function() {
		openSelectionModal()
	}
	
	function openJoints() {
		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			// pass things through to the other controller with the resolve method
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/jointModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'JointCtrl',
				size: 'sm',
				backdrop: 'static',
				backdropClass: "clear-backdrop",
				resolve: {
					paint: function() {
						return paint;
					},
					setValue: function() {
						return setValue;
					},
					Auto: function() {
						return Auto;
					},
					checkPoint: function() {
						return checkPoint;
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function() {
				$scope.afterModalDismiss()
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		} else {
			return;
		}
	}
	function openError() {
		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			// pass things through to the other controller with the resolve method
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/errorModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'ErrorCtrl',
				size: 'sm',
				// backdrop: 'static',
				// backdropClass: "clear-backdrop",
				resolve: {
					paint: function() {
						return paint;
					},
					checkPoint: function() {
						return checkPoint;
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function() {
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		} else {
			return;
		}
	}
	function openBreakModal(originalSection, newSection1, newSection2) {
		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			// pass things through to the other controller with the resolve method
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/breakModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'BreakCtrl',
				size: 'sm',
				// backdrop: 'static',
				// backdropClass: "clear-backdrop",
				resolve: {
					paint: function() {
						return paint;
					},
					checkPoint: function() {
						return checkPoint;
					},
					originalSection: function() {
						return originalSection
					},
					newSection1: function() {
						return newSection1;
					},
					newSection2: function() {
						return newSection2
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function() {
				$scope.afterModalDismiss()

				$scope.selectionManager.unselectAll()
				try {
					setSystems()
				} catch(squash) {
					console.log("Squash, ignore", squash)
				}
				FittingMetaUtil.getFittingMeta(newSection1, $scope.design, $scope.fittings)
				FittingMetaUtil.getFittingMeta(newSection2, $scope.design, $scope.fittings)
				try {
					setSystems()
				} catch(squash) {
					console.log("Squash, ignore", squash)
				}
				// order array correctly to allow for drawing in correct order
				$scope.design.fc5layout.Layout = _.sortBy($scope.design.fc5layout.Layout, 'GUIident')
				toLayout()
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		} else {
			return;
		}
	}
	//open modal
	function openApplModal() {
		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			// pass things through to the other controller with the resolve method
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/applianceModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'ApplianceCtrl',
				size: 'md',
				backdrop: 'static',
				backdropClass: "clear-backdrop",
				resolve: {
					paint: function() {
						return paint;
					},
					setValue: function() {
						return setValue;
					},
					Auto: function() {
						return Auto;
					},
					checkPoint: function() {
						return checkPoint;
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function() {
				$scope.afterModalDismiss()
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		} else {
			return;
		}
	}
	$scope.showFixes = function() {
		checkPoint();
		var canvas = document.getElementById("myCanvas");
		var context = canvas.getContext("2d");

		$scope.transformGUIidents($scope.design)

		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/fixWarningsModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'FixWarningsModalCtrl',
				size: 'md',
				backdrop: 'static',
				backdropClass: "clear-backdrop",
				resolve: {
					DoUndo: function() {
						return DoUndo;
					},
					setValue: function() {
						return setValue;
					},
					Auto: function() {
						return Auto;
					},
					context: function() {
						return context;
					},
					paint: function() {
						return paint
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function(shouldCompute) {
				$scope.afterModalDismiss()
				if (shouldCompute) {
					$scope.calculate(null)
				}
				paint()
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		}
	}
	$scope.showEnergyDetails = function() {
		checkPoint();
		var canvas = document.getElementById("myCanvas");
		var context = canvas.getContext("2d");

		$scope.transformGUIidents($scope.design)

		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/energySavings.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'EnergySavingsModalCtrl',
				size: 'md',
				backdrop: 'static',
				backdropClass: "clear-backdrop",
				resolve: {
					DoUndo: function() {
						return DoUndo;
					},
					setValue: function() {
						return setValue;
					},
					Auto: function() {
						return Auto;
					},
					context: function() {
						return context;
					},
					paint: function() {
						return paint
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function(shouldCompute) {
				$scope.afterModalDismiss()
				if (shouldCompute) {
					$scope.calculate(null)
				}
				paint()
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		}
	}
	function openVentModal() {
		checkPoint();
		var canvas = document.getElementById("myCanvas");
		var context = canvas.getContext("2d");

		$scope.transformGUIidents($scope.design)

		if (!$scope.modalOpen) {
			var parentElem = angular.element(document.querySelector('#drawingsMain'));
			var modalInstance = $uibModal.open({
				templateUrl: 'app/main/drawings/ventDataModal.html',
				scope: $scope,
				appendTo: parentElem,
				controller: 'VentDataCtrl',
				size: 'md',
				backdrop: 'static',
				backdropClass: "clear-backdrop",
				resolve: {
					DoUndo: function() {
						return DoUndo;
					},
					setValue: function() {
						return setValue;
					},
					Auto: function() {
						return Auto;
					},
					context: function() {
						return context;
					},
					paint: function() {
						return paint
					}
				}
			});
			modalInstance.rendered.then(function() {
				$(".modal-content").draggable();
				var newHeight = $(window).height() * .7;
				$('.modal-content .tab-content').css('max-height', newHeight + 'px').css('overflow-y','scroll')
			})
			modalInstance.result.then(function(selectedConnections) {
				// $scope.MouseUp(0, 0, 0, false, false, "mouse")
				$scope.afterModalDismiss()
				if (selectedConnections.length > 1) {
					return
				} else {
					_.each($scope.design.fc5layout.Layout, function(v) {
						if (v.ID === selectedConnections[0].ID) {
							v.Selected = true
						}
					})

				}
				paint()
			}).catch(function() {
				$scope._toSelectMode()
			})
			handleModels(modalInstance)
		}
	}
	function paint(xTarget, xDrawbg) {
		if (!$scope.design) {
			return;
		}
		if ($scope.design && $scope.design.application.computeAs == 'COM'){
			return;
		}
		var target = "myCanvas";
		var drawbg = true;
		var selectedAppliances = $scope.selectionManager.selectedAppliances();
		var selectedConnections = $scope.selectionManager.selectedVents();
		var selected = $scope.selectionManager.getSelected()
		$scope.appliancesSelected = selected.appliances.length > 0
		$scope.ventsSelected = selected.vents.length > 0
		$scope.jointsSelected = selected.joints.length > 0
		setSelectionBtnName()


		var elements = [];
		var X1 = 0
		var X2 = 0
		var Y1 = 0
		var Y2 = 0
		var v = {}
		var mh = {}
		var i = 0;
		var t = 0;
		var actionImageDrawn = undefined;

		if (xTarget) {
			target = xTarget
		};
		if (xDrawbg) {
			drawbg = xDrawbg
		};

		var canvas = document.getElementById(target);
		var context = canvas.getContext("2d");


		CanvasOffset = setOffset(canvas)

		context.clearRect(0, 0, canvas.width, canvas.height);
		canvas.width = canvas.width;

		context.beginPath();
		context.rect(0, 0, canvas.width, canvas.height);


		if (drawbg) {
			if ($scope.design.fc5layout.ViewAngle == "3D") {
				context.fillStyle = $scope.design.backgroundColor;
			} else {
				context.fillStyle = $scope.design.backgroundColor;
			}

			context.fill();
			context.closePath();

			if ($scope.design.fc5layout.ViewAngle == "3D") {
				switch ($scope.design.fc5layout.ViewAngle) {
					case "Plan":
						break;
					case "Front":
					case "Right":
					case "Left":
						context.beginPath();
						context.linewidth = 2;
						context.fillStyle = $scope.design.backgroundColor;
						context.rect(0, 0, canvas.width, canvas.height / 2);
						context.fill();
						context.closePath();

						context.beginPath();
						context.rect(0, canvas.height / 2, canvas.width, canvas.height / 8 - 2);
						context.fill();
						context.closePath();
						break;

					case "TopRight":
					case "TopLeft":
						context.beginPath();
						context.fillStyle = $scope.design.backgroundColor;
						context.rect(0, 0, canvas.width, canvas.height / 4);
						context.fill();
						context.closePath();

						context.beginPath();
						context.rect(0, canvas.height / 4, canvas.width, canvas.height / 8 - 2);
						context.fill();
						context.closePath();
						break;
				}
			}


			// Gridlines
			context.translate(canvas.width / 2 - Center.X * $scope.ZoomLev, canvas.height / 2 - Center.Y * $scope.ZoomLev);

			var xTranslation = canvas.width / 2 - Center.X * $scope.ZoomLev;
			var yTranslation = canvas.height / 2 - Center.Y * $scope.ZoomLev + 68;

			var LineLen = Math.sqrt(Math.pow(canvas.width + Math.abs(Center.X), 2) + Math.pow(canvas.height + Math.abs(Center.Y), 2)) / $scope.ZoomLev;

			context.fillStyle = $scope.design.gridDotColor;
			context.strokeStyle = "#111111";
			context.lineWidth = 0.25;

			switch ($scope.design.fc5layout.ViewAngle) {


				case "Plan":
				case "Front":
				case "Right":
				case "Left":
					// this draws the points of the grid system, but cant get lines to show without killing load times on the page completely

					for (t = 0; t >= -LineLen; t -= 50 * $scope.ZoomLev) {
						for (var tt = 0; tt >= -LineLen; tt -= 50 * $scope.ZoomLev) {
							context.fillRect(t - 1, tt - 1, 2, 2);
						}
						for (var tt = 0; tt <= LineLen; tt += 50 * $scope.ZoomLev) {
							context.fillRect(t - 1, tt - 1, 2, 2);
						}

					}
					for (t = 0; t <= LineLen; t += 50 * $scope.ZoomLev) {
						for (var tt = 0; tt >= -LineLen; tt -= 50 * $scope.ZoomLev) {
							context.fillRect(t - 1, tt - 1, 2, 2);
						}
						for (var tt = 0; tt <= LineLen; tt += 50 * $scope.ZoomLev) {
							context.fillRect(t - 1, tt - 1, 2, 2);
						}
					}

					break;

				case "TopRight":
				case "TopLeft":
					for (t = 0; t >= -LineLen; t -= 57.73502692 * $scope.ZoomLev) {
						for (var tt = 0; tt >= -LineLen; tt -= 50 * $scope.ZoomLev) {
							context.fillRect(tt - 1, t - tt / 50 * 57.73502692 / 2 - 1, 2, 2)
						}
						for (var tt = 0; tt <= LineLen; tt += 50 * $scope.ZoomLev) {
							context.fillRect(tt - 1, t - tt / 50 * 57.73502692 / 2 - 1, 2, 2)
						}
					}
					for (t = 0; t <= LineLen; t += 57.73502692 * $scope.ZoomLev) {
						for (var tt = 0; tt >= -LineLen; tt -= 50 * $scope.ZoomLev) {
							context.fillRect(tt - 1, t - tt / 50 * 57.73502692 / 2 - 1, 2, 2)
						}
						for (var tt = 0; tt <= LineLen; tt += 50 * $scope.ZoomLev) {
							context.fillRect(tt - 1, t - tt / 50 * 57.73502692 / 2 - 1, 2, 2)
						}
					}
			}
		} else {
			context.closePath();
		}


		//Draw Lines
		_.each($scope.design.fc5layout.Layout, function(v) {
			var cords = getXY(v);
			X1 = cords.X1;
			X2 = cords.X2;
			Y1 = cords.Y1;
			Y2 = cords.Y2;

			if (X1 != X2 || Y1 != Y2) {
				LineDraw(context, X1, Y1, X2, Y2, v.Fit1, v.Fit2, v);
			}
		})

		context.textAlign = "center";
		context.textBaseline = "middle";

		//Draw Line Details
		if ($scope.design.displayDiameter || $scope.design.displayLength) {
			_.each($scope.design.fc5layout.Layout, function(v) {
				var cords = getXY(v);
				X1 = cords.X1;
				X2 = cords.X2;
				Y1 = cords.Y1;
				Y2 = cords.Y2;

				if (X1 != X2 || Y1 != Y2) {
					drawInfoLine(context, X1, Y1, X2, Y2, v.Fit1, v.Fit2, v);
				}
			})
		}

		// Draw Connections
		_.each($scope.design.fc5layout.Layout, function(v) {
			var cords = getXY(v);
			X1 = cords.X1;
			X2 = cords.X2;
			Y1 = cords.Y1;
			Y2 = cords.Y2;


			if (X1 != X2 || Y1 != Y2) {
				$scope.DrawEnds(context, X1, Y1, X2, Y2, v.Fit1, v.Fit2, v);
			}
		})

		//Draw ends
		context.fillStyle = "blue"
		context.lineWidth = 0.25;
		context.strokeStyle = "#000000";

		_.each($scope.design.fc5layout.Layout, function(v) {
			switch ($scope.design.fc5layout.ViewAngle) {
				case "Plan":
					if (v.Z1 == v.Z2 && v.X1 == v.X2) {
						context.beginPath();
						context.arc(v.X1 * $scope.ZoomLev, v.Z1 * $scope.ZoomLev, 4 * $scope.ZoomLev, 0, 2 * Math.PI, true)
						context.closePath();
						//context.fill();
						context.stroke();
					}
					break;
				case "Left":
					if (v.Y1 == v.Y2 && v.Z1 == v.Z2) {
						context.beginPath();
						context.arc(v.Z1 * $scope.ZoomLev, v.Y1 * $scope.ZoomLev, 4 * $scope.ZoomLev, 0, 2 * Math.PI, true)
						context.closePath();
						//context.fill();
						context.stroke();
					}
					break;
				case "Front":
					if (v.Y1 == v.Y2 && v.X1 == v.X2) {
						context.beginPath();
						context.arc(v.X1 * $scope.ZoomLev, v.Y1 * $scope.ZoomLev, 4 * $scope.ZoomLev, 0, 2 * Math.PI, true)
						context.closePath();
						context.fill();
						context.stroke();
					}
					break;
				case "Right":
					if (v.Y1 == v.Y2 && v.Z1 == v.Z2) {
						context.beginPath();
						context.arc(-v.Z1 * $scope.ZoomLev, v.Y1 * $scope.ZoomLev, 4 * $scope.ZoomLev, 0, 2 * Math.PI, true)
						context.closePath();
						//context.fill();
						context.stroke();
					}
					break;
			}
		})

		var drewAction = false

		_.each($scope.design.fc5layout.Layout, function(v) {
			if (v.Selected == true) {
				var selectedItem = v,
				SelNo = getSelectedNo();

				var cords = getXY(v);
				X1 = cords.X1;
				X2 = cords.X2;
				Y1 = cords.Y1;
				Y2 = cords.Y2;

				elements.push({
					X1: X1 + xTranslation,
					X2: X2 + xTranslation,
					Y1: Y1 + yTranslation,
					Y2: Y2 + yTranslation
				});
				// selected line color, style, words
				if (X1 != X2 || Y1 != Y2) {

					context.lineWidth = 0.5 * $scope.ZoomLev;
					context.fillStyle = $scope.design.selectionColor;
					context.strokeStyle = $scope.design.selectionColor;
					context.beginPath();
					context.lineWidth=3;
					context.moveTo(X1, Y1);
					context.lineTo(X2, Y2);
					context.closePath();
					context.stroke();

					context.beginPath();
					context.rect(X1 - 3 * $scope.ZoomLev, Y1 - 3 * $scope.ZoomLev, 6 * $scope.ZoomLev, 6 * $scope.ZoomLev);
					context.rect(X2 - 3 * $scope.ZoomLev, Y2 - 3 * $scope.ZoomLev, 6 * $scope.ZoomLev, 6 * $scope.ZoomLev);
					context.closePath();
					context.fill();

					if (SelNo == 1) {
						var E1 = "";
						var E2 = "";

						// this is the logic that sometimes did the flipping - Util is brought in from ../components/services/util.js

						// if (Util.Ais1(v, $scope.design) == true) {
						// 	E1 = "1";
						// 	E2 = "2";
						// } else {
						// 	E2 = "1";
						// 	E1 = "2";
						// }
						if ($scope.hasMultipleSystems()) {
							E1 = v.GUIidentPath + ".1";
							E2 = v.GUIidentPath + ".2";
						} else {
							E1 = v.GUIident + ".1";
							E2 = v.GUIident + ".2";
						}

						context.beginPath();
						context.fillStyle = $scope.design.selectionColor;
						context.strokeStyle = $scope.design.selectionColor;
						context.font = "bold 16px HelveticaNeue"
						context.fillText(E1, X1 - 15 * Math.cos(GetAngle(X1, Y1, X2, Y2) * Math.PI / 180), Y1 - 15 * Math.sin(GetAngle(X1, Y1, X2, Y2) * Math.PI / 180)) //- E1w.width / 2
						context.fillText(E2, X2 + 15 * Math.cos(GetAngle(X1, Y1, X2, Y2) * Math.PI / 180), Y2 + 15 * Math.sin(GetAngle(X1, Y1, X2, Y2) * Math.PI / 180)) //- E2w.width / 2
						context.closePath();
						context.fill();
					}
				}

				// single item select
				if (SelNo === 1) {
					if (!drewAction) {
						drewAction = true
						if (X1 < X2 && Y1 === Y2) {
							// right horizontal
							drawAndClick(0, 20, selectedItem, selectedAppliances, selectedConnections, false)
						} else if (X1 > X2 && Y1 === Y2) {
							// left horizontal
							drawAndClick(0, -40, selectedItem, selectedAppliances, selectedConnections, false)
						} else if (Y1 > Y2 && X1 === X2) {
							// vertical up
							drawAndClick(20, 0, selectedItem, selectedAppliances, selectedConnections, false)
						} else if (Y1 < Y2 && X1 === X2) {
							// vertical down
							drawAndClick(-40, 0, selectedItem, selectedAppliances, selectedConnections, false)
						} else if (X1 < X2 && Y1 > Y2) {
							// quad 1
							drawAndClick(20, 20, selectedItem, selectedAppliances, selectedConnections, false)
						} else if (X1 > X2 && Y1 > Y2) {
							// quad 2
							drawAndClick(20, -40, selectedItem, selectedAppliances, selectedConnections, false)
						} else if (X1 > X2 && Y1 < Y2) {
							// quad 3
							drawAndClick(-40, -40, selectedItem, selectedAppliances, selectedConnections, false)
						} else {
							// quad 4
							drawAndClick(-40, 40, selectedItem, selectedAppliances, selectedConnections, false)
						}
					}
					drawTriangle(context, v)
					drawApplianceIndicators(context, v)
					// multi item select
				} else if (SelNo > 1) {
					if (!drewAction) {
						drewAction = true
						var firstSection = _.find($scope.orderedSelection, function(v){
							return _.find(selectedConnections)
						})
						if (firstSection) {
							console.log("orderedSection got first ", firstSection.GUIidentPath)
						} else {
							firstSection = selectedConnections[0]
							console.log("orderedSection empty, got first selectedConnection ", firstSection.GUIidentPath)
						}
						var firstItem = getXY(firstSection)

						X1 = firstItem.X1;
						X2 = firstItem.X2;
						Y1 = firstItem.Y1;
						Y2 = firstItem.Y2;

						if (X1 < X2 && Y1 === Y2) {
							// right horizontal
							drawAndClick(0, 20, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else if (X1 > X2 && Y1 === Y2) {
							// left horizontal
							drawAndClick(0, -40, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else if (Y1 > Y2 && X1 === X2) {
							// vertical up
							drawAndClick(20, 0, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else if (Y1 < Y2 && X1 === X2) {
							// vertical down
							drawAndClick(-40, 0, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else if (X1 < X2 && Y1 > Y2) {
							// quad 1
							drawAndClick(20, 20, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else if (X1 > X2 && Y1 > Y2) {
							// quad 2
							drawAndClick(20, -40, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else if (X1 > X2 && Y1 < Y2) {
							// quad 3
							drawAndClick(-40, -40, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						} else {
							// quad 4
							drawAndClick(-40, 40, selectedItem, selectedAppliances, selectedConnections, true, firstItem)
						}
					}
					// _.each(selectedConnections, function(v){
						drawTriangle(context, v)
						drawApplianceIndicators(context, v)
					// })
				}
			}
			if (v.Appl) {
				var ClickAppl = v.ID;

				if (v.Appl.Selected == true) {
					var cords = getXY(v);

					X1 = cords.X1;
					X2 = cords.X2;
					Y1 = cords.Y1;
					Y2 = cords.Y2;


					// selected appliance blue square
					if (X1 != X2 || Y1 != Y2) {
						context.lineWidth = 1 * $scope.ZoomLev;
						context.fillStyle = "#379FF0";

						if (v.Fit1 === "STP") {
							// rectangle
							context.strokeStyle = "#379FF0";
							context.beginPath();
							context.rect(X1 - 18 * $scope.ZoomLev, Y1 - 34 * $scope.ZoomLev, 36 * $scope.ZoomLev, 68 * $scope.ZoomLev);
							context.closePath();
							context.stroke();

							// small select rectangles
							context.strokeStyle = "white";
							context.beginPath();
							context.rect(X1 - 20 * $scope.ZoomLev, Y1 - 4 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);
							context.rect(X1 + 16 * $scope.ZoomLev, Y1 - 4 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);
							context.rect(X1 - 3 * $scope.ZoomLev, Y1 - 36 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);
							context.rect(X1 - 3 * $scope.ZoomLev, Y1 + 32 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);

							context.closePath();
							context.fill();
							context.stroke();

						} else {
							// rectangle
							context.strokeStyle = "#379FF0";
							context.beginPath();
							context.rect(X1 - 10 * $scope.ZoomLev, Y1 - 10 * $scope.ZoomLev, 20 * $scope.ZoomLev, 20 * $scope.ZoomLev);
							context.closePath();
							context.stroke();

							// small select rectangles
							context.strokeStyle = "white";
							context.beginPath();
							context.rect(X1 - 13 * $scope.ZoomLev, Y1 - 4 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);
							context.rect(X1 + 8 * $scope.ZoomLev, Y1 - 4 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);
							context.rect(X1 - 3 * $scope.ZoomLev, Y1 - 13 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);
							context.rect(X1 - 3 * $scope.ZoomLev, Y1 + 8 * $scope.ZoomLev, 5 * $scope.ZoomLev, 5 * $scope.ZoomLev);

							context.closePath();
							context.fill();
							context.stroke();
						}
						var firstItem = getXY($scope.orderedAppliances[0])
						if (firstItem) {
							X1 = firstItem.X1;
							X2 = firstItem.X2;
							Y1 = firstItem.Y1;
							Y2 = firstItem.Y2;
						}
						if (!drewAction) {
							drewAction = true
							openApplianceModal(canvas, v, cords, ClickAppl, xTranslation, yTranslation, selectedAppliances);
						}
					}
				}
			}
		})

		function drawAndClick(xChange, yChange, selectedItem, selectedAppliances, selectedConnections, multi, firstItem, isAppliance) {
			if (actDoubleClick && $scope.DrawMode != 'DRAW'){
				actDoubleClick = false
				SelBoxStart = undefined
				openSelectionModal();
				return
			}

			if (multi === false) {
				$scope.addEventListenerMouseup = function actionImgClick(e) {
					var x = e.clientX;
					var y = e.clientY;

					var found = false;
					_.each(elements, function(element) {
						var imgX1 = element.X1;
						var imgX2 = element.X2;
						var imgY1 = element.Y1;
						var imgY2 = element.Y2;

						if (x > imgX1 + ((imgX2 - imgX1) / 2) + xChange &&
							x < imgX1 + ((imgX2 - imgX1) / 2) + xChange + 30 &&
							y > imgY1 + ((imgY2 - imgY1) / 2) + yChange &&
							y < imgY1 + ((imgY2 - imgY1) / 2) + yChange + 23) {

							if (!$scope.modalOpen) {
								found = true
								SelBoxStart = undefined
								if ($scope.DrawMode === 'DRAW') {
									return
								} else {
									openSelectionModal()
								}
							}
						}
					})
					$scope.addEventListenerMouseup = null;
					return found;
				}
			} else {
				$scope.addEventListenerMouseup = function actionImgClick(e) {
					var x = e.clientX - xTranslation;
					var y = e.clientY - yTranslation;

					var imgX1 = firstItem.X1;
					var imgX2 = firstItem.X2;
					var imgY1 = firstItem.Y1;
					var imgY2 = firstItem.Y2;
					var found = false;

					if (x > imgX1 + ((imgX2 - imgX1) / 2) + xChange &&
						x < imgX1 + ((imgX2 - imgX1) / 2) + xChange + 30 &&
						y > imgY1 + ((imgY2 - imgY1) / 2) + yChange &&
						y < imgY1 + ((imgY2 - imgY1) / 2) + yChange + 23) {

						if (!$scope.modalOpen) {
							found = true
							SelBoxStart = undefined
							if ($scope.DrawMode === 'DRAW') {
								return
							} else {
								openSelectionModal()
							}
						}
					}
					$scope.addEventListenerMouseup = null;
					return found
				}
			}
		}

		function openApplianceModal(canvas, v, cords, ClickAppl, xTranslation, yTranslation, selectedAppliances) {
			if (actDoubleClick) {
				actDoubleClick = false;
				openSelectionModal();
				return;
			}
			var cords = getXY(v);
			$scope.addEventListenerMouseup = function actionImgClick(e) {
				var x = e.clientX - xTranslation;
				var y = e.clientY - yTranslation;

				var X1 = cords.X1;
				var X2 = cords.X2;
				var Y1 = cords.Y1;
				var Y2 = cords.Y2;
				var found = false
				if (
					x > X1 + 22 &&
					x < X1 + 52 &&
					y > Y1 + 20 &&
					y < Y1 + 42
				) {
					found = true;
					SelBoxStart = undefined
					openSelectionModal();
				}
				$scope.addEventListenerMouseup = null;
				return found;
			}
		}

		//DRAW line
		if ($scope.DrawMode == "DRAW" && FromPoint != undefined) {
			// var LineColor = "#000000";
			var LineColor = drawLineColor;
			if ($scope.design.fc5layout.ViewAngle == "TopRight" || $scope.design.fc5layout.ViewAngle == "TopLeft") {
				var Angle = GetAngle(FromPoint.X, FromPoint.Y, DrawPoint.X, DrawPoint.Y);
				Angle = Math.round(Angle / 5) * 5;
				switch (Angle) {
					case 0:
					case 30:
					case 90:
					case 150:
					case 180:
					case 210:
					case 270:
					case 330:
						break;
					default:
						LineColor = "#FF0000"
				}
			}
			context.beginPath();
			context.lineWidth = 2;
			context.strokeStyle = LineColor;
			context.moveTo(FromPoint.X * $scope.ZoomLev, FromPoint.Y * $scope.ZoomLev);
			context.lineTo(DrawPoint.X * $scope.ZoomLev, DrawPoint.Y * $scope.ZoomLev);
			context.stroke();
		}

		if ($scope.DrawMode == "SEL" && SelBoxStart != undefined) {
			if (SelBoxStart.X != SelPoint.X && SelBoxStart.Y != SelPoint.Y) {
				X1 = SelBoxStart.X
				if (SelPoint.X < X1) {
					X1 = SelPoint.X
				}
				Y1 = SelBoxStart.Y
				if (SelPoint.Y < Y1) {
					Y1 = SelPoint.Y
				}
				X2 = Math.abs(SelBoxStart.X - SelPoint.X)
				Y2 = Math.abs(SelBoxStart.Y - SelPoint.Y)

				context.beginPath();
				context.lineWidth = 0.5;
				context.strokeStyle = "#000000";
				context.strokeRect(X1 * $scope.ZoomLev, Y1 * $scope.ZoomLev, X2 * $scope.ZoomLev, Y2 * $scope.ZoomLev);
			}
		}

		if ($scope.DrawMode == "DRAW") {
			context.beginPath();
			context.lineWidth = 1;
			context.strokeStyle = "#000000";
			context.moveTo(DrawPoint.X * $scope.ZoomLev - 5, DrawPoint.Y * $scope.ZoomLev - 5);
			context.lineTo(DrawPoint.X * $scope.ZoomLev + 5, DrawPoint.Y * $scope.ZoomLev + 5);
			context.moveTo(DrawPoint.X * $scope.ZoomLev - 5, DrawPoint.Y * $scope.ZoomLev + 5);
			context.lineTo(DrawPoint.X * $scope.ZoomLev + 5, DrawPoint.Y * $scope.ZoomLev - 5);
			context.stroke();
		}

		context.setTransform(1, 0, 0, 1, 0, 0);

		if (target == "myCanvas" && printStats) {
			var strInf = "",
				strDenom = getStrDenom(),
				TotX = 0,
				TotY = 0,
				TotZ = 0,
				ang2 = "N/A";

			if ($scope.design.fc5layout.ViewAngle == "3D") {
				strInf = "Height (Y): " + Util.formatLength(TotY, null, $scope.design) + strDenom + "	Width (X): " + Util.formatLength(TotX, null, $scope.design) + strDenom + "	Depth (Z): " + Util.formatLength(TotZ, null, $scope.design) + strDenom + "	Angle: " + ang2 //+ " " + ShiftX3D + " " + ShiftY3D + " " + ShiftZ3D
			} else {
				strInf = "Vertical: " + Util.formatLength(TotY, null, $scope.design) + strDenom + "	Horizontal: " + Util.formatLength(TotX, null, $scope.design) + strDenom
			}

			context.font = "11px HelveticaNeue";
			context.textAlign = "left";
			context.fillStyle = "black";
			context.fillText(strInf, 10, canvas.height - 15);
		}
		if ($scope.insertBreakManager.mouseMovePoint) {
			context.fillStyle = "red"
			context.lineWidth = 0.25;
			context.strokeStyle = "#000000";
			context.moveTo($scope.insertBreakManager.mouseMovePoint.clientX, $scope.insertBreakManager.mouseMovePoint.clientY)
			context.save();
			context.beginPath();
			context.arc($scope.insertBreakManager.mouseMovePoint.clientX, $scope.insertBreakManager.mouseMovePoint.clientY, 4, 0, Math.PI * 2, true);
			context.closePath();
			context.fill();
			context.stroke();
		}

	};

	// function drawCircle(context, txtX, txtY, txtW, GUIident) {
	// 	context.fillStyle = "#E02C4D";
	// 	context.strokeStyle = "#E02C4D";
	// 	context.beginPath();
	// 	context.arc(txtX, txtY, txtW * 1.2 * $scope.ZoomLev, 0, 2 * Math.PI, false)
	// 	context.stroke();
	// 	context.closePath();
	// 	context.fill();
	// 	context.fillStyle = "#F5F6F7";
	// 	context.font = "11px HelveticaNeue";
	// 	context.fillText(GUIident, txtX, txtY);
	// }

	function hasIncludedAccessories(v) {
		var inclusionAccessories = _.filter($scope.design.stack.accessoryMetas, function(am){
			return v.GUIidentPath == am.GUIidentPath && am.product != null
		})
		var accessories = Util.getSubtypes("Accessory", $scope.allProductSubtypes, $scope.design)
		accessories = _.filter(accessories, function(productSubtype){
			return productSubtype.activeProducts
		})
		var productSubtypes = _.filter(accessories, function(subtype){
			var existing = _.find($scope.design.stack.accessoryMetas, function(ac){
				return ac.productSubtype == subtype._id && v.GUIidentPath == ac.GUIidentPath
			})
			if (existing && existing.quantity > 0) {
				return existing
			}
		})

		return inclusionAccessories.concat(productSubtypes).length > 0 ? true : false
	}

	function hasExcludedAccessories(v) {
		var excludeMeta = ExcludeMetaUtil.getExcludeMeta(v, $scope.design)
		var exclusionAccessories = _.filter($scope.allProductSubtypes, function(productSubtype){
			var found = _.find(excludeMeta.excludeSubtypes, function(excludeSubtype){
				return excludeSubtype == productSubtype._id
			})
			var result = _.clone(productSubtype)
			result.selected = (found) ? true : false
			if (result.selected) {
				return result
			}
		})
		return exclusionAccessories.length > 0 ? true : false
	}

	function drawIndicator(context, v, includes) {
		var cords = getXY(v)
		var X1 = cords.X1;
		var X2 = cords.X2;
		var Y1 = cords.Y1;
		var Y2 = cords.Y2;
		var txtX = X1 + (X2 - X1) / 5
		var txtY = Y1 + (Y2 - Y1) / 5
		if (includes) {
			txtX = X1 + (X2 - X1) / 3
			txtY = Y1 + (Y2 - Y1) / 3
		}
		
		var color = includes ? 'green' : 'red'
		var radius = 6 * $scope.ZoomLev
		context.fillStyle = color;
		context.beginPath();
		context.arc(txtX, txtY, radius, 0, 2 * Math.PI, false)
		context.closePath();
		context.fill();

		context.strokeStyle = 'white';
		context.lineWidth = 3;
		context.beginPath();
		context.moveTo(txtX - radius, txtY);
		context.lineTo(txtX + radius, txtY);
		if (includes) {
			context.moveTo(txtX, txtY - radius);
			context.lineTo(txtX, txtY + radius);
		}
		context.stroke();
		context.closePath();
		
	}

	function drawApplianceIndicators(context, v) {
		if (hasIncludedAccessories(v)) {
			drawIndicator(context, v, true)
		}
		if (hasExcludedAccessories(v)) {
			drawIndicator(context, v, false)
		}
	}

	function zoomFontString(str, zoomMultiplier) {
		var numberPart = parseFloat(str);
		var zoomedNumber = Math.round(numberPart * zoomMultiplier);
		return str.replace(numberPart, zoomedNumber);
	}

	function drawTriangle(context, v, selected) {
		var SelNo = null
		if (selected) {
			SelNo = selected
		}
		var cords = getXY(v)
		var triColor = null;
		var X1 = cords.X1;
		var X2 = cords.X2;
		var Y1 = cords.Y1;
		var Y2 = cords.Y2;
		var rotateAngle = (GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180;
		var txtX = X1 + (X2 - X1) / 2
		var txtY = Y1 + (Y2 - Y1) / 2
		var txtW = ($scope.hasMultipleSystems()) ? context.measureText(v.GUIidentPath).width : context.measureText(v.GUIident).width;
		var txtH = 14
		var length = $scope.ZoomLev * $scope.design.triangleLength/2;
		var offest = $scope.ZoomLev * $scope.design.triangleLength/8 ;

		if (X1 != X2 || Y1 != Y2) {
			if (v.warning) {
				triColor = $scope.design.warningColor
			} else {
				triColor = $scope.design.lineTriangleColor;
			}
			if (v.GUIident != "") {
				context.font = zoomFontString($scope.design.triangleFont, $scope.ZoomLev);
				context.translate(txtX, txtY);
				context.rotate(rotateAngle)
				context.lineJoin = "round";
				context.lineWidth = 4;
				context.strokeStyle = $scope.design.triangleBorderColor;
				context.beginPath();
				context.moveTo(0, length-offest);
				context.lineTo(length, length-offest);
				context.lineTo(0, -1*length-offest);
				context.lineTo(-1*length, length-offest)
				context.closePath();
				context.stroke();
				context.fillStyle = triColor;
				context.fill();
				context.rotate(-rotateAngle)
				context.beginPath();
				context.fillStyle = $scope.design.triangleFontColor;
				context.font = zoomFontString($scope.design.triangleFont, $scope.ZoomLev);

				var path = ($scope.hasMultipleSystems()) ? v.GUIidentPath : v.GUIident;
				if (!path) {
					path = ""
				}
				if (v.Selected || SelNo > 1) {
					context.fillText(path, 0, 0);
				} else {
					if (path.length === 1) {
						context.fillText(path, -3, 3);
					} else if (path.length > 1) {
						context.fillText(path, -6, 3);
					}
				}
				context.closePath();
				context.rotate(rotateAngle)
				context.rotate(-rotateAngle)
				context.translate(-txtX, -txtY);
				// if (v.Fit1=="STP" && v.Appl && v.Appl.GUIident != "") {
				// 	var txt = v.Appl.GUIident;
				// 	txtW = context.measureText(txt).width;
				// 	txtH = 14
				// 	context.beginPath();
				// 	context.font = "11px HelveticaNeue";
				// 	context.fillStyle = "black";
				// 	var top = Y1 + 45;
				// 	context.fillText(txt, X1 - 2, top);
				// 	context.closePath();
				// }
			} else {
				context.translate(X1 + (X2 - X1) / 2, Y1 + (Y2 - Y1) / 2);
				context.rotate((GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180)
				context.lineJoin = "round";
				context.lineWidth = 4;
				context.strokeStyle = $scope.design.triangleBorderColor;
				context.beginPath();
				context.moveTo(0, length-offest);
				context.lineTo(length, length-offest);
				context.lineTo(0, -1*length-offest);
				context.lineTo(-1*length, length-offest)
				context.closePath();
				context.stroke();
				context.fillStyle = triColor;
				context.fill();
				context.rotate(-(GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180)
				context.translate(-(X1 + (X2 - X1) / 2), -(Y1 + (Y2 - Y1) / 2));
			}
		}
	}

	function drawInfoLineEnds(X1, Y1, X2, Y2, v, context) {
		var SelNo = null
		var rotateAngle = (GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180;
		var txtX = X1 + (X2 - X1) / 2
		var txtY = Y1 + (Y2 - Y1) / 2
		var txtW = ($scope.hasMultipleSystems()) ? context.measureText(v.GUIidentPath).width : context.measureText(v.GUIident).width;
		var txtH = 14
		var length = 3;

		if (X1 != X2 || Y1 != Y2) {
			context.fillStyle = $scope.design.detailLineColor
			context.translate(X1, Y1);
			context.rotate((GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180)
			context.lineWidth = 1;
			context.beginPath();
			context.moveTo(0, 0);
			context.lineTo(-length, -2*length);
			context.lineTo(length, -2*length);
			context.lineTo(0, 0)
			context.fill()
			context.closePath();
			context.stroke();
			context.rotate(-(GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180)
			context.translate(-X1, -Y1);

			context.translate(X2, Y2);
			context.rotate((GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180)
			context.lineWidth = 1;
			context.beginPath();
			context.moveTo(0, 0);
			context.lineTo(-length, 2*length);
			context.lineTo(length, 2*length);
			context.moveTo(0, 0);
			context.fill();
			context.closePath();
			context.stroke();
			context.rotate(-(GetAngle(X1, Y1, X2, Y2) + 90) * Math.PI / 180)
			context.translate(-X2, -Y2);
		}
	}

	function drawInfoLine(context, X1, Y1, X2, Y2, fit1, fit2, Sec) {
		var slope = (Y2 - Y1) / (X2 - X1);
		var angleMain = Math.atan(slope);

		var originalPoint = {
			X1: X1,
			Y1: Y1,
			X2: X2,
			Y2: Y2
		}

		var perpSlope = -1 / slope;
		var perpAngle = Math.atan(perpSlope);
		var midPointX = (X2 + X1) / 2;
		var midPointY = (Y2 + Y1) / 2;
		var xShift = Math.cos(perpAngle);
		var yShift = Math.sin(perpAngle);

		context.beginPath();
		context.lineWidth =  1 * $scope.ZoomLev;
		context.strokeStyle = $scope.design.detailLineColor;
		context.fillStyle = $scope.design.detailLineColor;

		var offset = $scope.design.detailLineOffset * $scope.ZoomLev

		if (X1 === X2 && Y1 > Y2) {
			X1 = X1 - offset;
			X2 = X2 - offset;
		} else if (X1 === X2 && Y1 < Y2) {
			X1 = X1 + offset;
			X2 = X2 + offset;
		} else if (Y1 === Y2 && X1 < X2) {
			Y1 = Y1 - offset;
			Y2 = Y2 - offset;
		} else if (Y1 === Y2 && X1 > X2) {
			Y1 = Y1 + offset;
			Y2 = Y2 + offset;
		} else if (Y1 < Y2) {
			X1 = X1 + offset * xShift;
			X2 = X2 + offset * xShift;
			Y1 = Y1 + offset * yShift;
			Y2 = Y2 + offset * yShift;
		} else {
			X1 = X1 - offset*xShift;
			X2 = X2 - offset*xShift;
			Y1 = Y1 - offset*yShift;
			Y2 = Y2 - offset*yShift;
		}

		var angle = X1 > X2 ? GetAngle(X1, Y1, X2, Y2) + 180 : GetAngle(X1, Y1, X2, Y2)
		var isOverride = Sec.Length ? true : false
		var length = $scope.design.displayLength ? LengthUtil.getLengthFromVAsString(Sec, $scope.design, isOverride) : ''
		var diameterString = LengthUtil.getDiameterString(Sec)
		var diameter = $scope.design.displayDiameter ? "ϴ" +diameterString  : ''
		var space = $scope.design.displayLength && $scope.design.displayDiameter ? ' ' : ''
		var textVal = length + space + diameter
		var textWidth = context.measureText(textVal).width + 2;
		var textHeight = 14
		var midPoint = {
			X: X1 + (X2 - X1) / 2,
			Y: Y1 + (Y2 - Y1) / 2
		}
		// line
		context.moveTo(originalPoint.X1, originalPoint.Y1);
		context.lineTo(X1, Y1);
		context.lineTo(midPoint.X, midPoint.Y)
		context.lineTo(X2, Y2);
		context.lineTo(originalPoint.X2, originalPoint.Y2);
		context.stroke();
		context.closePath();

		// text box
		context.translate(midPoint.X, midPoint.Y)
		context.rotate(angle * Math.PI / 180)
		context.fillStyle = $scope.design.backgroundColor
		context.fillRect(-textWidth / 2, -textHeight / 2, textWidth, 14);
		context.fillStyle = $scope.design.detailLineFontColor
		context.font = zoomFontString($scope.design.detailLineFont, $scope.ZoomLev)
		context.fillText(textVal, 0, 0);
		context.rotate(-angle * Math.PI / 180)
		context.translate(-midPoint.X, -midPoint.Y)

		drawInfoLineEnds(X1, Y1, X2, Y2, Sec, context)
	}

	function LineDraw(context, X1, Y1, X2, Y2, fit1, fit2, Sec) {
		var slope = (Y2 - Y1) / (X2 - X1);
		var angleMain = Math.atan(slope);

		// gradient vars
		var perpSlope = -1 / slope;
		var perpAngle = Math.atan(perpSlope);
		var midPointX = (X2 + X1) / 2;
		var midPointY = (Y2 + Y1) / 2;
		var xShift = Math.cos(perpAngle);
		var yShift = Math.sin(perpAngle);

		// stripe vars
		var stripeLength = -10 * $scope.ZoomLev;
		var yInt = Y1 - slope * X1;
		var diagSlope = (-slope - 1) / (slope - 1);
		var angleBetween = Math.atan(diagSlope);
		var deltaX = stripeLength * Math.cos(angleBetween);
		var deltaY = stripeLength * Math.sin(angleBetween);
		var distanceBetweenDiags = 5 * Math.cos(angleMain);


		// horizontal, right gradient
		if (Y1 === Y2 && X1 < X2) {
			var gradient = context.createLinearGradient(0, Y1 - 3, 0, Y1 + 9);

			// horizontal, left gradient
		} else if (Y1 === Y2 && X1 > X2) {
			var gradient = context.createLinearGradient(0, Y1 + 3, 0, Y1 - 9);

			// vertical, up gradient
		} else if (X1 === X2 && Y1 < Y2) {
			var gradient = context.createLinearGradient(X1 + 3, 0, X1 - 9, 0);

			// vertical, down gradient
		} else if (X1 === X2 && Y1 > Y2) {
			var gradient = context.createLinearGradient(X1 - 3, 0, X1 + 9, 0);

			// quad 1 and quad 2 gradient
		} else if (Y1 > Y2) {
			var gradient = context.createLinearGradient(
				midPointX - (3 * xShift),
				midPointY - (3 * yShift),
				midPointX + (9 * xShift),
				midPointY + (9 * yShift)
			);
			// quad 3 and quad 4 gradient
		} else {
			var gradient = context.createLinearGradient(
				midPointX + (3 * xShift),
				midPointY + (3 * yShift),
				midPointX - (9 * xShift),
				midPointY - (9 * yShift)
			);
		}
		
		// gradient.addColorStop(0, "rgba(233,233,233,0.70");
		gradient.addColorStop(0, "#E9E9E9");
		gradient.addColorStop(1, "#747474");


		// draws the gradient
		context.beginPath();
		context.lineWidth = 10 * $scope.ZoomLev;
		context.strokeStyle = gradient;
		context.moveTo(X1, Y1);
		context.lineTo(X2, Y2);
		context.stroke();
		context.closePath();


		var diagSlope = (slope - 1) / (slope + 1);
		var angleBetween = Math.atan(diagSlope);
		var deltaX = -stripeLength * Math.cos(angleBetween);
		var deltaY = -stripeLength * Math.sin(angleBetween);
		var distanceBetweenDiags = 5 * Math.cos(angleMain);


		// draw stripes
		context.beginPath();
		context.lineWidth = 0.5 * $scope.ZoomLev;
		context.strokeStyle = "#AEAFAE";

		if (X1 < X2 && Y1 > Y2) {
			// quad 1

			var startX = X1 + 5;

			var drawStripe = function() {
				var startY = (slope * startX) + yInt;
				context.moveTo(startX, startY + (5 * $scope.ZoomLev));
				context.lineTo(startX + deltaX, startY + deltaY + (3 * $scope.ZoomLev));
				startX += distanceBetweenDiags;
			};

			while (startX < X2) {
				drawStripe();
			}

		} else if (X1 > X2 && Y1 > Y2) {
			// quad 2

			var startX = X1 - 5;

			var drawStripe = function() {
				var startY = (slope * startX) + yInt;
				context.moveTo(startX - 5, startY + (5 * $scope.ZoomLev));
				context.lineTo(startX + deltaX - 5, startY + deltaY + (3 * $scope.ZoomLev));
				startX -= distanceBetweenDiags;
			};

			while (startX > X2) {
				drawStripe();
			}

		} else if (X1 > X2 && Y1 < Y2) {
			// quad 3
			var startX = X1 - 5;
			var drawStripe = function() {
				var startY = (slope * startX) + yInt;
				context.moveTo(startX, startY + (5 * $scope.ZoomLev));
				context.lineTo(startX + deltaX, startY + deltaY + (3 * $scope.ZoomLev));
				startX -= distanceBetweenDiags;
			};
			while (startX - 5 > X2) {
				drawStripe();
			}
		} else if (X1 < X2 && Y1 < Y2) {
			// quad 4
			var startX = X1 + 5;
			var drawStripe = function() {
				var startY = (slope * startX) + yInt;
				context.moveTo(startX - 3, startY + (3 * $scope.ZoomLev));
				context.lineTo(startX + deltaX - 3, startY + deltaY + $scope.ZoomLev);
				startX += distanceBetweenDiags;
			};
			while (startX + 2 < X2) {
				drawStripe();
			}
		} else if (Y1 === Y2) {
			// right horizontal
			if (X1 < X2) {
				var startX = X1 + 5;
				var drawStripe = function() {
					var startY = (slope * startX) + yInt;
					context.moveTo(startX, startY - (5 * $scope.ZoomLev));
					context.lineTo(startX + deltaX, startY - deltaY - (3 * $scope.ZoomLev));
					startX += distanceBetweenDiags;
				};
				while (startX < X2) {
					drawStripe();
				}
			}
			// left horizontal
			if (X1 > X2) {
				var startX = X1 - 5;
				var drawStripe = function() {
					var startY = (slope * startX) + yInt;

					context.moveTo(startX, startY + (5 * $scope.ZoomLev));
					context.lineTo(startX + deltaX, startY + deltaY + (3 * $scope.ZoomLev));

					startX -= distanceBetweenDiags;
				};
				while (startX > X2) {
					drawStripe();
				}
			}
		} else if (X1 === X2) {
			// vertical up
			if (Y1 > Y2) {
				var startVX = X1 + 5;
				var endVX = X1 - 5;
				var startVY = Y1 - 5;
				var endVY = startVY - 5;
				var drawStripe = function() {
					context.moveTo(startVX, startVY);
					context.lineTo(endVX, endVY);
					startVY -= 5;
					endVY -= 5;
				};
				while (endVY >= Y2) {
					drawStripe();
				}
			}

			// vertical down
			if (Y1 < Y2) {
				var startVX = X1 - 5;
				var endVX = X1 + 5;
				var startVY = Y1 + 5;
				var endVY = startVY + 5;
				var drawStripe = function() {
					context.moveTo(startVX, startVY);
					context.lineTo(endVX, endVY);

					startVY += 5;
					endVY += 5;
				};
				while (endVY <= Y2) {
					drawStripe();
				}
			}
		}
		context.stroke();
		context.closePath();

		// draws the redLine
		context.beginPath();
		context.lineWidth = 2 * $scope.ZoomLev;

		if (Sec.warning) {
			context.strokeStyle = $scope.design.warningColor;
		} else {
			context.strokeStyle = $scope.design.lineColor;
		}

		if (X1 === X2 && Y1 > Y2) {
			context.moveTo(X1 - 6, Y1);
			context.lineTo(X2 - 6, Y2);
		} else if (X1 === X2 && Y1 < Y2) {
			context.moveTo(X1 + 6, Y1);
			context.lineTo(X2 + 6, Y2);
		} else if (Y1 === Y2 && X1 < X2) {
			context.moveTo(X1, Y1 - 6);
			context.lineTo(X2, Y2 - 6);
		} else if (Y1 === Y2 && X1 > X2) {
			context.moveTo(X1, Y1 + 6);
			context.lineTo(X2, Y2 + 6);
		} else if (Y1 < Y2) {
			context.moveTo(X1 + 6 * xShift, Y1 + 6 * yShift);
			context.lineTo(X2 + 6 * xShift, Y2 + 6 * yShift);
		} else {
			context.moveTo(X1 - 6 * xShift, Y1 - 6 * yShift);
			context.lineTo(X2 - 6 * xShift, Y2 - 6 * yShift);
		}
		context.stroke();
		context.closePath();
		drawTriangle(context, Sec)
		drawApplianceIndicators(context, Sec)
	};
	function _findIn(col, val){
		return _.find(col, function(v){
			return v == val;
		})
	}
	function jointImageForFitment(code){
		var fitting = _.find($scope.fittings, function(f){
			return f.code == code
		})
		if (!fitting){
			fitting = _.find($scope.fittings, function(f){
				return f.code == ""
			})
		}
		if (fitting){
			return imageLoader.get(fitting.image)
		}

	}
	function imageForApplianceIcon(code){
		var icon = _.find($scope.icons, function(i){
			return i.code == code && i.iconType == "Appliance"
		})
		if (!icon){
			icon = _.find($scope.icons, function(i){
				return i.isDefault && i.iconType == "Appliance"
			})
		}
		if (icon){
			return imageLoader.get(icon.image)
		}

	}
	function imageForTerm(code){
		var icon = _.find($scope.icons, function(i){
			return i.code == code && i.iconType == "Fan"
		})
		if (!icon){
			icon = _.find($scope.icons, function(i){
				return i.isDefault && i.iconType == "Fan"
			})
		}
		if (icon){
			return imageLoader.get(icon.image)
		}

	}
	$scope.DrawEnds = function(context, X1, Y1, X2, Y2, fit1, fit2, Sec) {
		var f = {};
		var radgrad = {};

		var fit1S = 0;
		var fit2S = 0;

		_.each($scope.design.fc5layout.Layout, function(s2) {
			if (Sec.X1 == s2.X2 && Sec.Y1 == s2.Y2 && Sec.Z1 == s2.Z2 && Sec.ID != s2.ID) {
				fit1S++
			}
			if (Sec.X2 == s2.X1 && Sec.Y2 == s2.Y1 && Sec.Z2 == s2.Z1 && Sec.ID != s2.ID) {
				fit2S++
			}
		})

		if (typeof Sec.Fit1 === 'object' && Sec.Fit1 != null) {
			Sec.Fit1 = Sec.Fit1.code;
		}

		if (typeof Sec.Fit2 === 'object' && Sec.Fit2 != null) {
			Sec.Fit2 = Sec.Fit2.code;
		}


		if (fit1S == 0) {
			if (Sec.Fit1 == "STP") {
				var thisimg = imageForApplianceIcon(Sec.Appl && Sec.Appl.Icon)
				if (thisimg){
					// Check if the image is ready
				    if (!thisimg.complete || thisimg.naturalWidth === 0) {
				        return; // Exit the function if the image is not ready
				    }
					context.translate(X1, Y1);
					if (Sec.Appl && Sec.Appl.warning){
						context.fillStyle = $scope.design.warningColor;
						context.fillRect(- 18 * $scope.ZoomLev, - 34 * $scope.ZoomLev, 36 * $scope.ZoomLev, 68 * $scope.ZoomLev);
					}
					context.drawImage(thisimg, -thisimg.width * $scope.ZoomLev / 2, -thisimg.height * $scope.ZoomLev / 2, thisimg.width * $scope.ZoomLev, thisimg.height * $scope.ZoomLev);
					context.translate(-X1, -Y1);
					if (Sec.Appl) {
						var txt = ($scope.hasMultipleSystems()) ? Sec.Appl.GUIidentPath : Sec.Appl.GUIident;
						context.beginPath();
						context.fillStyle = "black";
						var top = Y1+ (10 * $scope.ZoomLev);
						context.fillText(txt, X1 - 2, top);
						context.closePath();
					}
				}
			}
		} else {
			if (fit1 != "") {
				var jointImage = jointImageForFitment(Sec.Fit1);
				if (jointImage){
					drawJoint(context, X2, Y2, jointImage, X1, Y1, Sec.Fit1)
				}

			} else {
				var jointImage = jointImageForFitment("");
				if (jointImage) {
					drawJoint(context, X2, Y2, jointImage, X1, Y1, "")
				}
			}
		}
		if (fit2S == 0) {
			var stackOnly = Util.isStackOnly($scope.design)
			if (stackOnly) {
				setTermFan("None")
			}
			if (Sec.TermFan) {
				var imgFan = imageForTerm(Sec.TermFan)
				if (imgFan){
					if (!imgFan.complete || imgFan.naturalWidth === 0) {
				        return; // Exit the function if the image is not ready
				    }
					context.translate(X2, Y2);
					context.rotate((GetAngle(X1, Y1, X2, Y2) - 180) * Math.PI / 180)
					context.drawImage(imgFan, -imgFan.width * $scope.ZoomLev / 2, -imgFan.height * $scope.ZoomLev / 2, imgFan.width * $scope.ZoomLev, imgFan.height * $scope.ZoomLev);
					context.rotate(-(GetAngle(X1, Y1, X2, Y2) - 180) * Math.PI / 180)
					context.translate(-X2, -Y2);
				}
			}
		} else {
			// if (Sec.Fit2 === "LT4" || Sec.Fit2 === "LAT" || Sec.Fit2 === "DL4" || Sec.Fit2 === "SQT") {
			// 	drawJoint(context, X2, Y2, teeJointHot, X2, Y2, Sec.Fit2)
			// } else if (Sec.Fit2 === "90L" || Sec.Fit2 === "45L" || Sec.Fit2 === "30L" || Sec.Fit2 === "15L") {
			// 	drawJoint(context, X2, Y2, elbowJointHot, X2, Y2, Sec.Fit2)
			// } else if (Sec.Fit2 === "INL"){
			// 	drawJoint(context, X2, Y2, img_INL, X1, Y1, Sec.Fit2)
			// } else {
			// 	drawJoint(context, X2, Y2, blankJoint, X2, Y2, Sec.Fit2)
			// }
		}
	};

	function drawJoint(context, X2, Y2, img, X1, Y1, fitment) {
	    if (!img.complete || img.naturalWidth === 0) {
	        return;
	    }
		var zoom = $scope.ZoomLev
		if (_findIn(["INL"],fitment)) {
			var angle = (GetAngle(X1, Y1, X2, Y2)) * Math.PI / 180;
			context.translate(X1, Y1);
			context.rotate(angle)
			context.drawImage(img, -img.width * $scope.ZoomLev / 2, -img.height * $scope.ZoomLev / 2, img.width * $scope.ZoomLev, img.height * $scope.ZoomLev);
			context.rotate(-angle)
			context.translate(-X1, -Y1)
		} else if (_findIn(["ECM","ECO"],fitment)) {
			var angle = (GetAngle(X1, Y1, X2, Y2) - 180) * Math.PI / 180;
			context.translate(X1, Y1);
			context.rotate(angle)
			context.drawImage(img, -img.width * $scope.ZoomLev / 2, -img.height * $scope.ZoomLev / 2, img.width * $scope.ZoomLev, img.height * $scope.ZoomLev);
			context.rotate(-angle)
			context.translate(-X1, -Y1)
		} else {
			var angle = (GetAngle(X1, Y1, X2, Y2) - 180) * Math.PI / 180;
			context.moveTo(X1, Y1)
			context.save();
			context.beginPath();
			context.arc(X1, Y1, 24 * $scope.ZoomLev, 0, Math.PI * 2, true);
			context.closePath();
			context.clip();
			context.drawImage(img, X1 - 10*zoom, Y1 - 10*zoom, 20*zoom, 20*zoom);
			context.restore();
		}
	}

	function getXY(v) {
		var mh = {};
		var cords = {};
		var X1 = 0;
		var X2 = 0;
		var Y1 = 0;
		var Y2 = 0;
		if (!v) {
			return
		}
		switch ($scope.design.fc5layout.ViewAngle) {
			case "Plan":
				if (v.Z1 != v.Z2 || v.X1 != v.X2) {
					mh = GetMoveHandlerById(v.ID)
					if (mh != undefined) {
						if (mh.Vend == "Full") {
							X1 = (v.X1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Z1 + ShiftY) * $scope.ZoomLev
							X2 = (v.X2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Z2 + ShiftY) * $scope.ZoomLev
						} else if (mh.Vend == "End1") {
							X1 = (v.X1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Z1 + ShiftY) * $scope.ZoomLev
							X2 = v.X2 * $scope.ZoomLev
							Y2 = v.Z2 * $scope.ZoomLev
						} else if (mh.Vend == "End2") {
							X1 = v.X1 * $scope.ZoomLev
							Y1 = v.Z1 * $scope.ZoomLev
							X2 = (v.X2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Z2 + ShiftY) * $scope.ZoomLev
						}
					} else {
						X1 = v.X1 * $scope.ZoomLev
						Y1 = v.Z1 * $scope.ZoomLev
						X2 = v.X2 * $scope.ZoomLev
						Y2 = v.Z2 * $scope.ZoomLev
					}
				}
				break;
			case "Left":
				if (v.Y1 != v.Y2 || v.Z1 != v.Z2) {
					mh = GetMoveHandlerById(v.ID)
					if (mh != undefined) {
						if (mh.Vend == "Full") {
							X1 = (v.Z1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Y1 + ShiftY) * $scope.ZoomLev
							X2 = (v.Z2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Y2 + ShiftY) * $scope.ZoomLev
						} else if (mh.Vend == "End1") {
							X1 = (v.Z1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Y1 + ShiftY) * $scope.ZoomLev
							X2 = v.Z2 * $scope.ZoomLev
							Y2 = v.Y2 * $scope.ZoomLev
						} else if (mh.Vend == "End2") {
							X1 = v.Z1 * $scope.ZoomLev
							Y1 = v.Y1 * $scope.ZoomLev
							X2 = (v.Z2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Y2 + ShiftY) * $scope.ZoomLev
						}
					} else {
						X1 = v.Z1 * $scope.ZoomLev
						Y1 = v.Y1 * $scope.ZoomLev
						X2 = v.Z2 * $scope.ZoomLev
						Y2 = v.Y2 * $scope.ZoomLev
					}
				}
				break;
			case "Front":
				if (v.Y1 != v.Y2 || v.X1 != v.X2) {
					mh = GetMoveHandlerById(v.ID)
					if (mh != undefined) {
						if (mh.Vend == "Full") {
							X1 = (v.X1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Y1 + ShiftY) * $scope.ZoomLev
							X2 = (v.X2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Y2 + ShiftY) * $scope.ZoomLev
						} else if (mh.Vend == "End1") {
							X1 = (v.X1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Y1 + ShiftY) * $scope.ZoomLev
							X2 = v.X2 * $scope.ZoomLev
							Y2 = v.Y2 * $scope.ZoomLev
						} else if (mh.Vend == "End2") {
							X1 = v.X1 * $scope.ZoomLev
							Y1 = v.Y1 * $scope.ZoomLev
							X2 = (v.X2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Y2 + ShiftY) * $scope.ZoomLev
						}
					} else {
						X1 = v.X1 * $scope.ZoomLev
						Y1 = v.Y1 * $scope.ZoomLev
						X2 = v.X2 * $scope.ZoomLev
						Y2 = v.Y2 * $scope.ZoomLev
					}
				}
				break;
			case "Right":
				if (v.Y1 != v.Y2 || v.Z1 != v.Z2) {
					mh = GetMoveHandlerById(v.ID)
					if (mh != undefined) {
						if (mh.Vend == "Full") {
							X1 = (-v.Z1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Y1 + ShiftY) * $scope.ZoomLev
							X2 = (-v.Z2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Y2 + ShiftY) * $scope.ZoomLev
						} else if (mh.Vend == "End1") {
							X1 = (-v.Z1 + ShiftX) * $scope.ZoomLev
							Y1 = (v.Y1 + ShiftY) * $scope.ZoomLev
							X2 = -v.Z2 * $scope.ZoomLev
							Y2 = v.Y2 * $scope.ZoomLev
						} else if (mh.Vend == "End2") {
							X1 = -v.Z1 * $scope.ZoomLev
							Y1 = v.Y1 * $scope.ZoomLev
							X2 = (-v.Z2 + ShiftX) * $scope.ZoomLev
							Y2 = (v.Y2 + ShiftY) * $scope.ZoomLev
						}
					} else {
						X1 = -v.Z1 * $scope.ZoomLev
						Y1 = v.Y1 * $scope.ZoomLev
						X2 = -v.Z2 * $scope.ZoomLev
						Y2 = v.Y2 * $scope.ZoomLev
					}
				}
				break;
			case "TopRight":
				mh = GetMoveHandlerById(v.ID)
				if (mh != undefined) {
					if (mh.Vend == "Full") {
						X1 = ((v.X1 + ShiftX3D) - (v.Z1 + ShiftZ3D)) * $scope.ZoomLev
						X2 = ((v.X2 + ShiftX3D) - (v.Z2 + ShiftZ3D)) * $scope.ZoomLev
						Y1 = (((v.Y1 + ShiftY3D) * 2 * Tan30) + ((v.X1 + ShiftX3D) * Tan30) + ((v.Z1 + ShiftZ3D) * Tan30)) * $scope.ZoomLev
						Y2 = (((v.Y2 + ShiftY3D) * 2 * Tan30) + ((v.X2 + ShiftX3D) * Tan30) + ((v.Z2 + ShiftZ3D) * Tan30)) * $scope.ZoomLev
					} else if (mh.Vend == "End1") {
						X1 = ((v.X1 + ShiftX3D) - (v.Z1 + ShiftZ3D)) * $scope.ZoomLev
						Y1 = (((v.Y1 + ShiftY3D) * 2 * Tan30) + ((v.X1 + ShiftX3D) * Tan30) + ((v.Z1 + ShiftZ3D) * Tan30)) * $scope.ZoomLev
						X2 = (v.X2 - v.Z2) * $scope.ZoomLev
						Y2 = ((v.Y2 * 2 * Tan30) + (v.X2 * Tan30) + (v.Z2 * Tan30)) * $scope.ZoomLev
					} else if (mh.Vend == "End2") {
						X1 = (v.X1 - v.Z1) * $scope.ZoomLev
						Y1 = ((v.Y1 * 2 * Tan30) + (v.X1 * Tan30) + (v.Z1 * Tan30)) * $scope.ZoomLev
						X2 = ((v.X2 + ShiftX3D) - (v.Z2 + ShiftZ3D)) * $scope.ZoomLev
						Y2 = (((v.Y2 + ShiftY3D) * 2 * Tan30) + ((v.X2 + ShiftX3D) * Tan30) + ((v.Z2 + ShiftZ3D) * Tan30)) * $scope.ZoomLev
					}
				} else {
					X1 = (v.X1 - v.Z1) * $scope.ZoomLev
					X2 = (v.X2 - v.Z2) * $scope.ZoomLev
					Y1 = ((v.Y1 * 2 * Tan30) + (v.X1 * Tan30) + (v.Z1 * Tan30)) * $scope.ZoomLev
					Y2 = ((v.Y2 * 2 * Tan30) + (v.X2 * Tan30) + (v.Z2 * Tan30)) * $scope.ZoomLev
				}



				// these values are rounded up elsewhere - need consistency
				if (Y1 < 0) {
					Y1 = -Math.round(Math.abs(Y1))
				}
				if (Y2 < 0) {
					Y2 = -Math.round(Math.abs(Y2))
				}

				break;
			case "TopLeft":
				mh = GetMoveHandlerById(v.ID)
				if (mh != undefined) {
					if (mh.Vend == "Full") {
						X1 = ((v.X1 + ShiftX3D) + (v.Z1 + ShiftZ3D)) * $scope.ZoomLev
						X2 = ((v.X2 + ShiftX3D) + (v.Z2 + ShiftZ3D)) * $scope.ZoomLev
						Y1 = (((v.Y1 + ShiftY3D) * 2 * Tan30) - ((v.X1 + ShiftX3D) * Tan30) - ((-v.Z1 - ShiftZ3D) * Tan30)) * $scope.ZoomLev
						Y2 = (((v.Y2 + ShiftY3D) * 2 * Tan30) - ((v.X2 + ShiftX3D) * Tan30) - ((-v.Z2 - ShiftZ3D) * Tan30)) * $scope.ZoomLev
					} else if (mh.Vend == "End1") {
						X1 = ((v.X1 + ShiftX3D) + (v.Z1 + ShiftZ3D)) * $scope.ZoomLev
						Y1 = (((v.Y1 + ShiftY3D) * 2 * Tan30) - ((v.X1 + ShiftX3D) * Tan30) - ((-v.Z1 - ShiftZ3D) * Tan30)) * $scope.ZoomLev
						X2 = (v.X2 + v.Z2) * $scope.ZoomLev
						Y2 = ((v.Y2 * 2 * Tan30) - (v.X2 * Tan30) - (-v.Z2 * Tan30)) * $scope.ZoomLev
					} else if (mh.Vend == "End2") {
						X1 = (v.X1 + v.Z1) * $scope.ZoomLev
						Y1 = ((v.Y1 * 2 * Tan30) - (v.X1 * Tan30) - (-v.Z1 * Tan30)) * $scope.ZoomLev
						X2 = ((v.X2 + ShiftX3D) + (v.Z2 + ShiftZ3D)) * $scope.ZoomLev
						Y2 = (((v.Y2 + ShiftY3D) * 2 * Tan30) - ((v.X2 + ShiftX3D) * Tan30) - ((-v.Z2 - ShiftZ3D) * Tan30)) * $scope.ZoomLev
					}
				} else {
					X1 = (v.X1 + v.Z1) * $scope.ZoomLev
					X2 = (v.X2 + v.Z2) * $scope.ZoomLev
					Y1 = ((v.Y1 * 2 * Tan30) - (v.X1 * Tan30) - (-v.Z1 * Tan30)) * $scope.ZoomLev
					Y2 = ((v.Y2 * 2 * Tan30) - (v.X2 * Tan30) - (-v.Z2 * Tan30)) * $scope.ZoomLev
				}

				// these values are rounded up elsewhere - need consistency
				if (Y1 < 0) {
					Y1 = -Math.round(Math.abs(Y1))
				}
				if (Y2 < 0) {
					Y2 = -Math.round(Math.abs(Y2))
				}

				break;
		}
		cords.X1 = parseInt(X1);
		cords.X2 = parseInt(X2);
		cords.Y1 = parseInt(Y1);
		cords.Y2 = parseInt(Y2);
		return cords;
	}

	function getXYZ(x2D, y2D) {
		var cords = {};
		var X = 0;
		var Y = 0;
		var Z = 0;

		switch ($scope.design.fc5layout.ViewAngle) {
			case "TopRight":
				if (x2D == 0) {
					X = 0;
					Y = Round50(y2D / Tan30 / 2)
					Z = 0;
				} else if (y2D == 0) {
					X = Round50(x2D / 2)
					Z = Round50(x2D / -2)
					Y = 0;
				} else if (x2D > 0 && y2D > 0) {
					X = Round50(x2D)
					Y = Round50((y2D - x2D * Tan30) / Tan30 / 2)
					Z = 0;
				} else if (x2D > 0 && y2D < 0) {
					X = 0;
					Y = Round50((y2D + x2D * Tan30) / Tan30 / 2)
					Z = -x2D
				} else if (x2D < 0 && y2D > 0) {
					X = 0;
					Y = Round50((y2D + x2D * Tan30) / Tan30 / 2)
					Z = -x2D
				} else if (x2D < 0 && y2D < 0) {
					X = x2D
					Y = Round50((y2D - x2D * Tan30) / Tan30 / 2)
					Z = 0;
				}
				break;
			case "TopLeft":
				if (x2D == 0) {
					X = 0;
					Y = Round50(y2D / Tan30 / 2)
					Z = 0;
				} else if (y2D == 0) {
					X = Round50(x2D / 2)
					Z = Round50(x2D / 2)
					Y = 0;
				} else if (x2D > 0 && y2D > 0) {
					X = 0;
					Y = Round50((y2D - x2D * Tan30) / Tan30 / 2)
					Z = Round50(x2D);
				} else if (x2D > 0 && y2D < 0) {
					X = Round50(x2D);
					Y = Round50((y2D + x2D * Tan30) / Tan30 / 2)
					Z = 0
				} else if (x2D < 0 && y2D > 0) {
					X = x2D;
					Y = Round50((y2D + x2D * Tan30) / Tan30 / 2)
					Z = 0;
				} else if (x2D < 0 && y2D < 0) {
					X = 0;
					Y = Round50((y2D - x2D * Tan30) / Tan30 / 2)
					Z = x2D;
				}
				break;
		}
		cords.X = parseInt(X);
		cords.Y = parseInt(Y);
		cords.Z = parseInt(Z);

		return cords;
	}

	function getUpStream(start) {
		var col = [];
		col.push(start);
		var found = true;
		while (found == true) {
			found = false;
			_.find(col, function(v) {
				return _.find($scope.design.fc5layout.Layout, function(vv) {
					if (vv.X2 == v.X1 && vv.Y2 == v.Y1 && vv.Z2 == v.Z1 && secIn(col, vv) == false) {
						col.push(vv)
						found = true
						return true
					}
				})
			})
		}
		return col;
	}

	function secIn(col, v) {
		var f = false;
		for (var i = 0; i < col.length; i++) {
			if (col[i].ID == v.ID) {
				f = true;
				break;
			}
		}
		return f
	}

	function roundValue(val){
		if (val < 0) {
			val = -(Math.round(Math.abs(val)))
		} else {
			val = Math.round(val)
		}
		return val
	}

	function checkBreakLocation(section1, section2) {
		if (section1.Fit2 === 'TER' || section2.Fit1 === 'TER') {
			return 'invalid'
		} else {
			return 'valid'
		}
	}

	$scope.insertBreakManager = {
		mouseMovePoint: null,
		actualPoint: null,
		setInsertBreeakPoint: function(event) {
			if (!event) {
				if (this.mouseMovePoint) {
					this.mouseMovePoint = event
					return true
				} else {
					return false
				}
			} else if (!this.mouseMovePoint) {
				this.mouseMovePoint = event
				return true
			} else if (this.mouseMovePoint.clientX != event.clientX && this.mouseMovePoint.clientY != event.clientY) {
				this.mouseMovePoint = event
				return true
			} else {
				return false
			}
		},
		toInsertBreakPoint: function(pageX, pageY) {
			return {
				clientX: pageX - CanvasOffset.X,
				clientY: pageY - CanvasOffset.Y,
				pageX: pageX,
				pageY: pageY
			}
		},
		toPoint: function(event) {
			var pageX = event.pageX,
				pageY = event.pageY;

			var can = document.getElementById("myCanvas");
			var mPoint = {}
			mPoint.X = pageX - CanvasOffset.X;
			mPoint.Y = pageY - CanvasOffset.Y;
			var newPoint = {}
			switch ($scope.design.fc5layout && $scope.design.fc5layout.ViewAngle) {
				case "TopRight":
				case "TopLeft":
					newPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
					newPoint.Y = Math.round(Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev) / (50 * Tan30)) * (50 * Tan30));
					break;

				default:
					newPoint.X = Math.round(((mPoint.X - can.width / 2 + (Center.X * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
					newPoint.Y = Math.round(((mPoint.Y - can.height / 2 + (Center.Y * $scope.ZoomLev)) / $scope.ZoomLev) / 50) * 50;
			}
			// console.log("toPoint", event, newPoint)
			return newPoint
		},
		pointBetween: function(event, sectionStart, sectionEnd) {
			var point = this.toPoint(event)
			var x = point.X,
				y = point.Y;

			var x1 = sectionStart.X,
				x2 = sectionEnd.X,
				y1 = sectionStart.Y,
				y2 = sectionEnd.Y;
			var maxX = Math.max(x1, x2)
			var minX = Math.min(x1, x2)
			var maxY = Math.max(y1, y2)
			var minY = Math.min(y1, y2)
			if (x < minX) {
				// console.log("x " + x + " < " + minX, maxX)
			} else if (x > maxX) {
				// console.log("x "+ x + " > " + maxX, minX)
			} else if (y < minY) {
				// console.log("y "+ y + " < " + minY, maxY)
			} else if (y > maxY) {
				// console.log("y "+ y + " > " + maxY, minY)
			} else {
				// console.log("pass y " + minY + " < " + y + " < " + maxY+ ", x " + minX + " < " + x + " < " + maxX)
				return true
			}
		},
		checkCanInsert: function(event) {
			$scope.customRightMenu = $scope.customRightMenu.filter(function(arr) {
				return arr[0] !== 'Insert Break';
			})
			if (this.canInsertBreak(event)) {
				// console.log("can insert")
				$scope.customRightMenu.push(
					['Insert Break', function($itemScope, $event, modelValue, text, $li) {
						var v = _.find($scope.design.fc5layout.Layout, function(v){
							return v.Selected
						})
						// $scope.insertBreakManager.insertBreak($itemScope, $scope.insertBreakManager.actualPoint, v)
						$scope.insertBreakManager.insertBreak($itemScope, $scope.insertBreakManager.mouseMovePoint, v)
						$scope.insertBreakManager.actualPoint = null
						$scope.insertBreakManager.mouseMovePoint = null
					}]
				)
			} else {
				// console.log("cannot insert")
			}
		},
		canInsertBreak: function(event) {
			if (!event) {
				return false
			}
			if ($scope.ZoomLev != 1) {
				// console.log("ecluding for non 100% zoom", $scope.ZoomLev)
				return false
			}
			if (!$scope.selectionManager.hasSingleSectionSelected()) {
				// console.log("muliple sections/types selected")
				return false
			}
			var canvas = document.getElementById("myCanvas");
			var context = canvas.getContext("2d");
			var xTranslation = canvas.width / 2 - Center.X;
			// var yTranslation = canvas.height / 2 - Center.Y + 68;
			var yTranslation = canvas.height / 2 - Center.Y;
			var viewAngle = $scope.design.fc5layout.ViewAngle
			var yRound = (100 * Tan30) / 2
			var workingAngles = [0, 30, 90, 150, 180, 210, 270, 330]
			var xClick = event.clientX - xTranslation
			var yClick = event.clientY - yTranslation
			var xSplit = Math.round(xClick / 50) * 50
			var ySplit = null

			if (viewAngle != "TopRight" && viewAngle != "TopLeft") {
				ySplit = Math.round(yClick / 50) * 50
			} else {
				if (yClick < 0) {
					yClick = Math.abs(yClick)
					ySplit = -(Math.round(Math.round(yClick / yRound) * yRound))
				} else {
					ySplit = Math.round(Math.round(yClick / yRound) * yRound)
				}
			}
			var canInsert = false
			_.each($scope.design.fc5layout.Layout, function(item) {
				var cords = getXY(item)
				if (item.Selected == true) {
					var originalSection = item
					var sectionStart, sectionEnd, sectionSplit
					if (viewAngle == 'Front' || viewAngle == 'Left' || viewAngle == 'Right' || viewAngle == 'Plan') {
						sectionStart = {
							X: roundValue(cords.X1 / $scope.ZoomLev),
							Y: roundValue(cords.Y1 / $scope.ZoomLev),
						}
						sectionEnd = {
							X: roundValue(cords.X2 / $scope.ZoomLev),
							Y: roundValue(cords.Y2 / $scope.ZoomLev),
						}
						sectionSplit = {
							X: xSplit,
							Y: ySplit
						}
					} else if (viewAngle == 'TopRight') {
						sectionStart = {
							X: roundValue(cords.X1 / $scope.ZoomLev),
							Y: roundValue(((item.Y1 * 2 * Tan30) + (item.X1 * Tan30) + (item.Z1 * Tan30)))
						}
						sectionEnd = {
							X: roundValue(cords.X2 / $scope.ZoomLev),
							Y: roundValue(((item.Y2 * 2 * Tan30) + (item.X2 * Tan30) + (item.Z2 * Tan30)))
						}
						sectionSplit = {
							X: xSplit,
							Y: ySplit
						}
					} else if (viewAngle == 'TopLeft') {
						sectionStart = {
							X: roundValue(cords.X1 / $scope.ZoomLev),
							Y: roundValue(((item.Y1 * 2 * Tan30) - (item.X1 * Tan30) - (-item.Z1 * Tan30)))
						}
						sectionEnd = {
							X: roundValue(cords.X2 / $scope.ZoomLev),
							Y: roundValue(((item.Y2 * 2 * Tan30) - (item.X2 * Tan30) - (-item.Z2 * Tan30)))
						}
						sectionSplit = {
							X: xSplit,
							Y: ySplit
						}
					}

					if ($scope.design.fc5layout.ViewAngle === 'TopRight' || $scope.design.fc5layout.ViewAngle === 'TopLeft') {
						var Angle1 = Math.round(GetAngle(sectionStart.X, sectionStart.Y, sectionSplit.X, sectionSplit.Y) / 5) * 5
						var Angle2 = Math.round(GetAngle(sectionSplit.X, sectionSplit.Y, sectionEnd.X, sectionEnd.Y) / 5) * 5

						// is split going to create acceptable angle?
						var angleFound1 = _.indexOf(workingAngles, Angle1)
						var angleFound2 = _.indexOf(workingAngles, Angle2)
						if (angleFound1 === -1 || angleFound2 === -1) {
							// alert("The section cannot be split at an accepted angle. Please select a different break point")
							return;
						}
					}

					if ($scope.insertBreakManager.pointBetween(event, sectionStart, sectionEnd)) {
						canInsert = true
						// console.log('point between')
					} else {
						// console.log('point NOT between')
					}
				}
			})
			return canInsert
		},
		insertBreak: function($itemScope, $event) {
			var canvas = document.getElementById("myCanvas");
			var context = canvas.getContext("2d");
			var xTranslation = canvas.width / 2 - Center.X;
			// var yTranslation = canvas.height / 2 - Center.Y + 68;
			var yTranslation = canvas.height / 2 - Center.Y;
			var viewAngle = $scope.design.fc5layout.ViewAngle
			var yRound = (100 * Tan30) / 2
			var workingAngles = [0, 30, 90, 150, 180, 210, 270, 330]
			var xClick = $event.clientX - xTranslation
			var yClick = $event.clientY - yTranslation
			var xSplit = Math.round(xClick / 50) * 50
			var ySplit = null

			if (viewAngle != "TopRight" && viewAngle != "TopLeft") {
				ySplit = Math.round(yClick / 50) * 50
			} else {
				if (yClick < 0) {
					yClick = Math.abs(yClick)
					ySplit = -(Math.round(Math.round(yClick / yRound) * yRound))
				} else {
					ySplit = Math.round(Math.round(yClick / yRound) * yRound)
				}
			}
			var inserted = []
			_.each($scope.design.fc5layout.Layout, function(item) {
				var cords = getXY(item)
				if (item.Selected == true) {
					var originalSection = item
					var sectionStart, sectionEnd, sectionSplit
					if (viewAngle == 'Front' || viewAngle == 'Left' || viewAngle == 'Right' || viewAngle == 'Plan') {
						sectionStart = {
							X: roundValue(cords.X1 / $scope.ZoomLev),
							Y: roundValue(cords.Y1 / $scope.ZoomLev),
						}
						sectionEnd = {
							X: roundValue(cords.X2 / $scope.ZoomLev),
							Y: roundValue(cords.Y2 / $scope.ZoomLev),
						}
						sectionSplit = {
							X: xSplit,
							Y: ySplit
						}
					} else if (viewAngle == 'TopRight') {
						sectionStart = {
							X: roundValue(cords.X1 / $scope.ZoomLev),
							Y: roundValue(((item.Y1 * 2 * Tan30) + (item.X1 * Tan30) + (item.Z1 * Tan30)))
						}
						sectionEnd = {
							X: roundValue(cords.X2 / $scope.ZoomLev),
							Y: roundValue(((item.Y2 * 2 * Tan30) + (item.X2 * Tan30) + (item.Z2 * Tan30)))
						}
						sectionSplit = {
							X: xSplit,
							Y: ySplit
						}
					} else if (viewAngle == 'TopLeft') {
						sectionStart = {
							X: roundValue(cords.X1 / $scope.ZoomLev),
							Y: roundValue(((item.Y1 * 2 * Tan30) - (item.X1 * Tan30) - (-item.Z1 * Tan30)))
						}
						sectionEnd = {
							X: roundValue(cords.X2 / $scope.ZoomLev),
							Y: roundValue(((item.Y2 * 2 * Tan30) - (item.X2 * Tan30) - (-item.Z2 * Tan30)))
						}
						sectionSplit = {
							X: xSplit,
							Y: ySplit
						}
					}

					if ($scope.design.fc5layout.ViewAngle === 'TopRight' || $scope.design.fc5layout.ViewAngle === 'TopLeft') {
						var Angle1 = Math.round(GetAngle(sectionStart.X, sectionStart.Y, sectionSplit.X, sectionSplit.Y) / 5) * 5
						var Angle2 = Math.round(GetAngle(sectionSplit.X, sectionSplit.Y, sectionEnd.X, sectionEnd.Y) / 5) * 5

						// is split going to create acceptable angle?
						var angleFound1 = _.indexOf(workingAngles, Angle1)
						var angleFound2 = _.indexOf(workingAngles, Angle2)
						if (angleFound1 === -1 || angleFound2 === -1) {
							alert("The section cannot be split at an accepted angle. Please select a different break point")
							return;
						}
					}


					// remove old section
					$scope.design.fc5layout.Layout = _.filter($scope.design.fc5layout.Layout, function(section) {
						return section.ID != item.ID
					})

					// insert two new sections
					$scope.DrawMode = "DRAW"
					var newSection1 = undefined
					var newSection2 = undefined
					for (var i = 0; i < 2; i++) {
						if (i === 0){
							FromPoint = sectionStart
							DrawPoint = sectionSplit
							MouseDown(0, 0, 0)
							$scope._MouseUp(0, 0, 0, false, false, 'mouse', originalSection, true)
							newSection1 = $scope.design.fc5layout.Layout[$scope.design.fc5layout.Layout.length - 1]
						} else if (i === 1){
							FromPoint = sectionSplit
							DrawPoint = sectionEnd
							MouseDown(0, 0, 0)
							$scope._MouseUp(0, 0, 0, false, false, 'mouse', originalSection, false)
							newSection2 = $scope.design.fc5layout.Layout[$scope.design.fc5layout.Layout.length - 1]
						}
					}

					if (checkBreakLocation(newSection1, newSection2) === 'invalid') {
						//remove two new sections
						$scope.design.fc5layout.Layout = _.filter($scope.design.fc5layout.Layout, function(section) {
							if (section.ID != newSection1.ID && section.ID != newSection2.ID) {
								return section
							}
						})
						//put old section back
						$scope.design.fc5layout.Layout.push(originalSection)
						inserted.push(originalSection)
						alert("The section cannot be split at an accepted angle. Please select a different break point")
					} else {
						openBreakModal(originalSection, newSection1, newSection2)
						SetSameFittings();
						autoFit();
					}

				}
			})
			// console.log("insertBreak mouse up")
			$scope.MouseUp(0, 0, 0, false, false, "mouse")
			$scope.selectionManager.unselectAll()
			$scope.DrawMode = "SEL"

			// order array correctly to allow for drawing in correct order
			$scope.design.fc5layout.Layout = _.sortBy($scope.design.fc5layout.Layout, 'GUIident')
			paint()
			// console.log("insertBreak paint")
		}
	}

	function SelChanged(ClickSec, ClickAppl) {
		var v = {};
		var vv = {};
		var ventSel = false;
		var drySel = false;
		var setEdit = true;

		_.each($scope.design.fc5layout.Layout, function(v) {
			if (v.Selected == true) {
				ventSel = true
			}
			if (v.Appl) {
				if (v.Appl.Selected == true) {
					drySel = true
				}
			}
		})

		if (ventSel == false) {
			$scope.customRightMenu = $scope.customRightMenu.filter(function(arr) {
				return arr[0] !== 'Insert Break';
			})
		}

		if (ventSel == true && drySel == false) {
			var SelNo = getSelectedNo();
			if (SelNo == 1) {
				$scope.insertBreakManager.checkCanInsert()

				v = getSelected();
				$scope.fitting1.options = Util.getFittings($scope.design.fc5layout.Layout, v, $scope.design, $scope.fittings, true);
				$scope.fitting2.options = Util.getFittings($scope.design.fc5layout.Layout, v, $scope.design, $scope.fittings, false);
				Auto = false;

				if ($scope.design.fc5layout.ViewAngle == "3D") {
					var GreenX = (v.X1 != v.X2);
					var GreenY = (v.Y1 != v.Y2);
					var GreenZ = (v.Z1 != v.Z2);

					//Set Dim Background color

					if (GreenX) {
						$scope.greenX = true;
					};
					if (GreenY) {
						$scope.greenY = true;
					};
					if (GreenZ) {
						$scope.greenZ = true;
					};
				}

				setEdit = true;

			} else if (SelNo > 1) {
				$scope.customRightMenu = $scope.customRightMenu.filter(function(arr) {
					return arr[0] !== 'Insert Break';
				})


				var col = getSelectedCol();
				var showIn = true;
				var showOut = true;
				for (var i = 0; i < col.length; i++) {
					v = col[i];
					var col1 = Util.ComingTo(v.X1, v.Y1, v.Z1, $scope.design.fc5layout.Layout);
					var col2 = Util.ComingTo(v.X2, v.Y2, v.Z2, $scope.design.fc5layout.Layout);
					var Lea = Util.LeavingFrom(v.X2, v.Y2, v.Z2, $scope.design.fc5layout.Layout);
					if (col1.length > 0) {
						showIn = false
					};
					if (col2.length > 1 || Lea != null) {
						showOut = false
					};
				}

				var colIn = [];
				var colOut = [];
				for (var i = 0; i < $scope.fittings.length; i++) {
					var fit = $scope.fittings[i]
					if (fit.code == "STP") {
						colIn.push(fit);
					}
					if (fit.code == "TER") {
						colOut.push(fit);
					}
				}
				$scope.fitting1.options = colOut;
				$scope.fitting2.options = colIn;

				setEdit = false;
			}
		}
	};

	function compareXYZ(a, b) {
		if ((a.X() + a.Y() + a.Z()) < (b.X() + b.Y() + b.Z())) {
			return -1;
		}
		if ((a.X() + a.Y() + a.Z()) > (b.X() + b.Y() + b.Z())) {
			return 1;
		}
		return 0;
	}

	function compareX(a, b) {
		if (a.X() < b.X()) {
			return -1;
		}
		if (a.X() > b.X()) {
			return 1;
		}
		return 0;
	}

	function compareY(a, b) {
		if (a.Y() < b.Y()) {
			return -1;
		}
		if (a.Y() > b.Y()) {
			return 1;
		}
		return 0;
	}

	function compareZ(a, b) {
		if (a.Z() < b.Z()) {
			return -1;
		}
		if (a.Z() > b.Z()) {
			return 1;
		}
		return 0;
	}

	function compareXdesc(a, b) {
		if (a.X() > b.X()) {
			return -1;
		}
		if (a.X() < b.X()) {
			return 1;
		}
		return 0;
	}

	function compareID(a, b) {
		if (a.GUIident < b.GUIident) {
			return -1;
		}
		if (a.GUIident > b.GUIident) {
			return 1;
		}
		return 0;
	}

	function compareApplID(a, b) {
		if (a.ApplGUIident < b.ApplGUIident) {
			return -1;
		}
		if (a.ApplGUIident > b.ApplGUIident) {
			return 1;
		}
		return 0;
	}

	function Round50(entry) {
		return Math.round(entry / 50) * 50;
	};

	function round2(num, dec) {
		var result = Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
		return result;
	}

	function getSectionById(id) {
		return _.find($scope.design.fc5layout.Layout, function(v) {
			return v.ID == id
		})
	};

	function DoUndo() {
		var str = JSON.stringify($scope.design.fc5layout.Layout);
		Undo.push(str);
		if (Undo.length > 10) {
			Undo.shift();
		}
	}

	function DoRedo() {
		var str = JSON.stringify($scope.design.fc5layout.Layout);
		Redo.push(str);
		if (Redo.length > 10) {
			Redo.shift();
		}
	}

	function GetMoveHandlerById(id) {
		var i = 0;
		for (i = 0; i < MoveHandlers.length; i++) {
			if (MoveHandlers[i].VID == id) {
				return MoveHandlers[i];
			}
		}
		return undefined;
	};

	function SetAngle(Angle) {
		switch (true) {
			case Angle < 15:
				{
					Angle = 0;
					break;
				}
			case Angle < 60:
				{
					Angle = 30;
					break;
				}
			case Angle < 120:
				{
					Angle = 90;
					break;
				}
			case Angle < 165:
				{
					Angle = 150;
					break;
				}
			case Angle < 195:
				{
					Angle = 180;
					break;
				}
			case Angle < 240:
				{
					Angle = 210;
					break;
				}
			case Angle < 300:
				{
					Angle = 270;
					break;
				}
			case Angle < 345:
				{
					Angle = 330;
					break;
				}
			case Angle < 360:
				{
					Angle = 0;
					break;
				}
		}
		return Angle;
	};

	function GetAngle(X1, Y1, X2, Y2) {
		var DimX = 0
		var DimY = 0
		var dimR = 0
		var GradA = 0

		DimX = X1 - X2
		DimY = Y1 - Y2
		if (DimX == 0 && DimY == 0) {
			return 0
		}
		dimR = Math.sqrt(Math.pow(DimX, 2) + Math.pow(DimY, 2))
		if (Y2 < Y1) {
			GradA = (Math.acos((X2 - X1) / dimR)) * 180 / Math.PI
		} else {
			GradA = ((360 * Math.PI / 180) - (Math.acos((X2 - X1) / dimR))) * 180 / Math.PI
		}
		return Math.abs(360 - GradA)
	};

	function GetAngleRad(X1, Y1, X2, Y2) {
		var DimX = 0
		var DimY = 0
		var dimR = 0

		DimX = X1 - X2
		DimY = Y1 - Y2
		if (DimX == 0 && DimY == 0) {
			return 0
		}
		dimR = Math.sqrt(Math.pow(DimX, 2) + Math.pow(DimY, 2))
		return Math.acos((X2 - X1) / dimR)
	};

	function handle(delta) {
		if (delta < 0) {
			$scope.ClickMenu('ZOOMIN');
			// $scope.ZoomLev += 0.1;

			// if ($scope.ZoomLev > 1.5) {
			// 	$scope.ZoomLev = 1.5;
			// 	// $scope.zoomLev = $scope.zoomLev;
			// 	$scope.$apply();
			// } else {
			// 	// $scope.zoomLev = $scope.zoomLev + 10;
			// 	$scope.$apply();
			// }
			// paint();
		} else {
			$scope.ClickMenu('ZOOMOUT');
			// $scope.ZoomLev -= 0.1;
			// if ($scope.ZoomLev < 0.5) {
			// 	$scope.ZoomLev = 0.5
			// 	// $scope.zoomLev = $scope.zoomLev;
			// 	$scope.$apply();
			// } else {
			// 	// $scope.zoomLev = $scope.zoomLev - 10;
			// 	$scope.$apply();
			// }
			// paint();
		}
	}

	function wheel(event) {
		var delta = 0;
		if (!event) event = window.event;
		if (event.wheelDelta) {
			delta = event.wheelDelta / 120;
		} else if (event.detail) {
			delta = -event.detail / 3;
		}
		if (delta)
			handle(delta);
		if (event.preventDefault)
			event.preventDefault();
		event.returnValue = false;
	}

	function AllowDrop(d, Warn) {
		var TroubleSec = ""
		var MovH = "Full"
		var i = 0;
		var v = {};

		if (MoveHandlers.length > 0) {
			var mh = MoveHandlers[0]
			MovH = mh.Vend;
		}

		if (MovH != "Full") {
			switch ($scope.design.fc5layout.ViewAngle) {
				case "Plan":
					switch (MovH) {
						case "End1":
							if (d.X2 == d.X1 + ShiftX && d.Z2 == d.Z1 + ShiftY) {
								TroubleSec = d.ID
							}
							break;
						case "End2":
							if (d.X1 == d.X2 + ShiftX && d.Z1 == d.Z2 + ShiftY) {
								TroubleSec = d.ID
							}
							break;
					}
					break;
				case "Front":
					switch (MovH) {
						case "End1":
							if (d.X2 == d.X1 + ShiftX && d.Y2 == d.Y1 + ShiftY) {
								TroubleSec = d.ID
							}
							break;
						case "End2":
							if (d.X1 == d.X2 + ShiftX && d.Y1 == d.Y2 + ShiftY) {
								TroubleSec = d.ID
							}
							break;
					}
					break;
				case "Right":
				case "Left":
					switch (MovH) {
						case "End1":
							if (d.Z2 == d.Z1 + ShiftX && d.Y2 == d.Y1 + ShiftY) {
								TroubleSec = d.ID
							}
							break;
						case "End2":
							if (d.Z1 == d.Z2 + ShiftX && d.Y1 == d.Y2 + ShiftY) {
								TroubleSec = d.ID
							}
							break;
					}
					break;
				case "TopRight":
					switch (MovH) {
						case "End1":
							if (d.X2 == d.X1 + ShiftX3D && d.Y2 == d.Y1 + ShiftY3D && d.Z2 == d.Z1 + ShiftZ3D) {
								TroubleSec = d.ID
							}
							break;
						case "End2":
							if (d.X1 == d.X2 + ShiftX3D && d.Y1 == d.Y2 + ShiftY3D && d.Z1 == d.Z2 + ShiftZ3D) {
								TroubleSec = d.ID
							}
							break;
					}
					break;
				case "TopLeft":
					switch (MovH) {
						case "End1":
							if (d.X2 == d.X1 + ShiftX3D && d.Y2 == d.Y1 + ShiftY3D && d.Z2 == d.Z1 + ShiftZ3D) {
								TroubleSec = d.ID
							}
							break;
						case "End2":
							if (d.X1 == d.X2 + ShiftX3D && d.Y1 == d.Y2 + ShiftY3D && d.Z1 == d.Z2 + ShiftZ3D) {
								TroubleSec = d.ID
							}
							break;
					}
					break;
			}
			if (TroubleSec != "") {
				if (Warn == true) {
					alert("Start and end of a section cannot be the same point.")
				}
				return false;
			}
		}

		_.each($scope.design.fc5layout.Layout, function(v) {
			if (d.ID != v.ID) {
				switch ($scope.design.fc5layout.ViewAngle) {
					case "Plan":
						switch (MovH) {
							case "Full":
								if ((v.X1 == d.X1 + ShiftX && v.Y1 == d.Y1 && v.Z1 == d.Z1 + ShiftY && v.X2 == d.X2 + ShiftX && v.Y2 == d.Y2 && v.Z2 == d.Z2 + ShiftY) || (v.X2 == d.X1 + ShiftX && v.Y2 == d.Y1 && v.Z2 == d.Z1 + ShiftY && v.X1 == d.X2 + ShiftX && v.Y1 == d.Y2 && v.Z1 == d.Z2 + ShiftY)) {
									TroubleSec = v.ID
								}
								break;
							case "End1":
								if ((v.X1 == d.X1 + ShiftX && v.Y1 == d.Y1 && v.Z1 == d.Z1 + ShiftY && v.X2 == d.X2 && v.Y2 == d.Y2 && v.Z2 == d.Z2) || (v.X2 == d.X1 + ShiftX && v.Y2 == d.Y1 && v.Z2 == d.Z1 + ShiftY && v.X1 == d.X2 && v.Y1 == d.Y2 && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
							case "End2":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 && v.Z1 == d.Z1 && v.X2 == d.X2 + ShiftX && v.Y2 == d.Y2 && v.Z2 == d.Z2 + ShiftY) || (v.X2 == d.X1 && v.Y2 == d.Y1 && v.Z2 == d.Z1 && v.X1 == d.X2 + ShiftX && v.Y1 == d.Y2 && v.Z1 == d.Z2 + ShiftY)) {
									TroubleSec = v.ID
								}
								break;
						}
						break;
					case "Front":
						switch (MovH) {
							case "Full":
								if ((v.X1 == d.X1 + ShiftX && v.Y1 == d.Y1 + ShiftY && v.Z1 == d.Z1 && v.X2 == d.X2 + ShiftX && v.Y2 == d.Y2 + ShiftY && v.Z2 == d.Z2) || (v.X2 == d.X1 + ShiftX && v.Y2 == d.Y1 + ShiftY && v.Z2 == d.Z1 && v.X1 == d.X2 + ShiftX && v.Y1 == d.Y2 + ShiftY && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
							case "End1":
								if ((v.X1 == d.X1 + ShiftX && v.Y1 == d.Y1 + ShiftY && v.Z1 == d.Z1 && v.X2 == d.X2 && v.Y2 == d.Y2 && v.Z2 == d.Z2) || (v.X2 == d.X1 + ShiftX && v.Y2 == d.Y1 + ShiftY && v.Z2 == d.Z1 && v.X1 == d.X2 && v.Y1 == d.Y2 && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
							case "End2":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 && v.Z1 == d.Z1 && v.X2 == d.X2 + ShiftX && v.Y2 == d.Y2 + ShiftY && v.Z2 == d.Z2) || (v.X2 == d.X1 && v.Y2 == d.Y1 && v.Z2 == d.Z1 && v.X1 == d.X2 + ShiftX && v.Y1 == d.Y2 + ShiftY && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
						}
						break;
					case "Right":
					case "Left":
						switch (MovH) {
							case "Full":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 + ShiftY && v.Z1 == d.Z1 + ShiftX && v.X2 == d.X2 && v.Y2 == d.Y2 + ShiftY && v.Z2 == d.Z2 + ShiftX) || (v.X2 == d.X1 && v.Y2 == d.Y1 + ShiftY && v.Z2 == d.Z1 + ShiftX && v.X1 == d.X2 && v.Y1 == d.Y2 + ShiftY && v.Z1 == d.Z2 + ShiftX)) {
									TroubleSec = v.ID
								}
								break;
							case "End1":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 + ShiftY && v.Z1 == d.Z1 + ShiftX && v.X2 == d.X2 && v.Y2 == d.Y2 && v.Z2 == d.Z2) || (v.X2 == d.X1 && v.Y2 == d.Y1 + ShiftY && v.Z2 == d.Z1 + ShiftX && v.X1 == d.X2 && v.Y1 == d.Y2 && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
							case "End2":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 && v.Z1 == d.Z1 && v.X2 == d.X2 && v.Y2 == d.Y2 + ShiftY && v.Z2 == d.Z2 + ShiftX) || (v.X2 == d.X1 && v.Y2 == d.Y1 && v.Z2 == d.Z1 && v.X1 == d.X2 && v.Y1 == d.Y2 + ShiftY && v.Z1 == d.Z2 + ShiftX)) {
									TroubleSec = v.ID
								}
								break;
						}
						break;
					case "TopRight":
						switch (MovH) {
							case "Full":
								if ((v.X1 == d.X1 + ShiftX3D && v.Y1 == d.Y1 + ShiftY3D && v.Z1 == d.Z1 + ShiftZ3D && v.X2 == d.X2 + ShiftX3D && v.Y2 == d.Y2 + ShiftY3D && v.Z2 == d.Z2 + ShiftZ3D) || (v.X2 == d.X1 + ShiftX3D && v.Y2 == d.Y1 + ShiftY3D && v.Z2 == d.Z1 + ShiftZ3D && v.X1 == d.X2 + ShiftX3D && v.Y1 == d.Y2 + ShiftY3D && v.Z1 == d.Z2 + ShiftZ3D)) {
									TroubleSec = v.ID
								}
								break;
							case "End1":
								if ((v.X1 == d.X1 + ShiftX3D && v.Y1 == d.Y1 + ShiftY3D && v.Z1 == d.Z1 + ShiftZ3D && v.X2 == d.X2 && v.Y2 == d.Y2 && v.Z2 == d.Z2) || (v.X2 == d.X1 + ShiftX3D && v.Y2 == d.Y1 + ShiftY3D && v.Z2 == d.Z1 + ShiftZ3D && v.X1 == d.X2 && v.Y1 == d.Y2 && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
							case "End2":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 && v.Z1 == d.Z1 && v.X2 == d.X2 + ShiftX3D && v.Y2 == d.Y2 + ShiftY3D && v.Z2 == d.Z2 + ShiftZ3D) || (v.X2 == d.X1 && v.Y2 == d.Y1 && v.Z2 == d.Z1 && v.X1 == d.X2 + ShiftX3D && v.Y1 == d.Y2 + ShiftY3D && v.Z1 == d.Z2 + ShiftZ3D)) {
									TroubleSec = v.ID
								}
								break;
						}
						break;
					case "TopLeft":
						switch (MovH) {
							case "Full":
								if ((v.X1 == d.X1 + ShiftX3D && v.Y1 == d.Y1 + ShiftY3D && v.Z1 == d.Z1 + ShiftZ3D && v.X2 == d.X2 + ShiftX3D && v.Y2 == d.Y2 + ShiftY3D && v.Z2 == d.Z2 + ShiftZ3D) || (v.X2 == d.X1 + ShiftX3D && v.Y2 == d.Y1 + ShiftY3D && v.Z2 == d.Z1 + ShiftZ3D && v.X1 == d.X2 + ShiftX3D && v.Y1 == d.Y2 + ShiftY3D && v.Z1 == d.Z2 + ShiftZ3D)) {
									TroubleSec = v.ID
								}
								break;
							case "End1":
								if ((v.X1 == d.X1 + ShiftX3D && v.Y1 == d.Y1 + ShiftY3D && v.Z1 == d.Z1 + ShiftZ3D && v.X2 == d.X2 && v.Y2 == d.Y2 && v.Z2 == d.Z2) || (v.X2 == d.X1 + ShiftX3D && v.Y2 == d.Y1 + ShiftY3D && v.Z2 == d.Z1 + ShiftZ3D && v.X1 == d.X2 && v.Y1 == d.Y2 && v.Z1 == d.Z2)) {
									TroubleSec = v.ID
								}
								break;
							case "End2":
								if ((v.X1 == d.X1 && v.Y1 == d.Y1 && v.Z1 == d.Z1 && v.X2 == d.X2 + ShiftX3D && v.Y2 == d.Y2 + ShiftY3D && v.Z2 == d.Z2 + ShiftZ3D) || (v.X2 == d.X1 && v.Y2 == d.Y1 && v.Z2 == d.Z1 && v.X1 == d.X2 + ShiftX3D && v.Y1 == d.Y2 + ShiftY3D && v.Z1 == d.Z2 + ShiftZ3D)) {
									TroubleSec = v.ID
								}
								break;
						}
						break;
				}
				if (TroubleSec != "") {
					if (Warn == true) {
						alert("You cannot have two sections with identical coordinates.")
					}
					return false;
				}
			}
		})
		return true;
	};

	function GetZ() {
		var ZPoint = {
			X: -1000000,
			Y: -1000000
		};
		var i = 0;
		var v = {};
		_.each($scope.design.fc5layout.Layout, function(v) {
			if ((FromPoint.X == v.X1 && FromPoint.Y == v.Y1) && v.Z1 > ZPoint.X) {
				ZPoint.X = v.Z1;
			}
			if ((FromPoint.X == v.X2 && FromPoint.Y == v.Y2) && v.Z2 > ZPoint.X) {
				ZPoint.X = v.Z2;
			}
		})

		_.each($scope.design.fc5layout.Layout, function(v) {
			if ((DrawPoint.X == v.X1 && DrawPoint.Y == v.Y1) && v.Z1 > ZPoint.Y) {
				ZPoint.Y = v.Z1
			}
			if ((DrawPoint.X == v.X2 && DrawPoint.Y == v.Y2) && v.Z2 > ZPoint.Y) {
				ZPoint.Y = v.Z2
			}
		})
		if (ZPoint.X == -1000000 && ZPoint.Y == -1000000) {
			return {
				X: 0,
				Y: 0
			};
		} else {
			if (ZPoint.X == -1000000) {
				ZPoint.X = ZPoint.Y
			}
			if (ZPoint.Y == -1000000) {
				ZPoint.Y = ZPoint.X
			}
		}
		return ZPoint
	};

	function GetX() {
		var XPoint = {
			X: -1000000,
			Y: -1000000
		}
		var v = {};
		var i = 0;

		if ($scope.design.fc5layout.ViewAngle == "Right") {
			_.each($scope.design.fc5layout.Layout, function(v) {
				if ((-FromPoint.X == v.Z1 && FromPoint.Y == v.Y1) && v.X1 > XPoint.X) {
					XPoint.X = v.X1
				}
				if ((-FromPoint.X == v.Z2 && FromPoint.Y == v.Y2) && v.X2 > XPoint.X) {
					XPoint.X = v.X2
				}
			})
			_.each($scope.design.fc5layout.Layout, function(v) {
				if ((-DrawPoint.X == v.Z1 && DrawPoint.Y == v.Y1) && v.X1 > XPoint.Y) {
					XPoint.Y = v.X1
				}
				if ((-DrawPoint.X == v.Z2 && DrawPoint.Y == v.Y2) && v.X2 > XPoint.Y) {
					XPoint.Y = v.X2
				}
			})
			if (XPoint.X == -1000000 && XPoint.Y == -1000000) {
				return {
					X: 0,
					Y: 0
				}
			} else {
				if (XPoint.X == -1000000) {
					XPoint.X = XPoint.Y
				}
				if (XPoint.Y == -1000000) {
					XPoint.Y = XPoint.X
				}
			}

		} else {
			XPoint.X = 1000000
			XPoint.Y = 1000000
			_.each($scope.design.fc5layout.Layout, function(v) {
				if ((FromPoint.X == v.Z1 && FromPoint.Y == v.Y1) && v.X1 < XPoint.X) {
					XPoint.X = v.X1
				}
				if ((FromPoint.X == v.Z2 && FromPoint.Y == v.Y2) && v.X2 < XPoint.X) {
					XPoint.X = v.X2
				}
			})
			_.each($scope.design.fc5layout.Layout, function(v) {
				if ((DrawPoint.X == v.Z1 && DrawPoint.Y == v.Y1) && v.X1 < XPoint.Y) {
					XPoint.Y = v.X1
				}
				if ((DrawPoint.X == v.Z2 && DrawPoint.Y == v.Y2) && v.X2 < XPoint.Y) {
					XPoint.Y = v.X2
				}
			})
			if (XPoint.X == 1000000 && XPoint.Y == 1000000) {
				return {
					X: 0,
					Y: 0
				}
			} else {
				if (XPoint.X == 1000000) {
					XPoint.X = XPoint.Y
				}
				if (XPoint.Y == 1000000) {
					XPoint.Y = XPoint.X
				}
			}
		}
		return XPoint
	}

	function GetY() {
		var YPoint = {
			X: 1000000,
			Y: 1000000
		}
		var v = {};
		var i = 0;

		_.each($scope.design.fc5layout.Layout, function(v) {
			if ((FromPoint.X == v.X1 && FromPoint.Y == v.Z1) && v.Y1 < YPoint.X) {
				YPoint.X = v.Y1
			}
			if ((FromPoint.X == v.X2 && FromPoint.Y == v.Z2) && v.Y2 < YPoint.X) {
				YPoint.X = v.Y2
			}
		})

		_.each($scope.design.fc5layout.Layout, function(v) {
			if ((DrawPoint.X == v.X1 && DrawPoint.Y == v.Z1) && v.Y1 < YPoint.Y) {
				YPoint.Y = v.Y1
			}
			if ((DrawPoint.X == v.X2 && DrawPoint.Y == v.Z2) && v.Y2 < YPoint.Y) {
				YPoint.Y = v.Y2
			}
		})
		if (YPoint.X == 1000000 && YPoint.Y == 1000000) {
			return {
				X: 0,
				Y: 0
			}
		} else {
			if (YPoint.X == 1000000) {
				YPoint.X = YPoint.Y
			}
			if (YPoint.Y == 1000000) {
				YPoint.Y = YPoint.X
			}
		}
		return YPoint
	}

	function setOffset(obj) {
		var top = 0;
		var left = 0;
		try {
			while (obj.tagName != "BODY") {
				top += obj.offsetTop;
				left += obj.offsetLeft;
				obj = obj.offsetParent;
			}
			return {
				Y: top,
				X: left
			};
		} catch (err) {
			return {
				Y: 0,
				X: 0
			};
		}
	}

	function setDrawView(dw) {
		$scope.design.fc5layout.ViewAngle = dw;
		Center = GetCenter();
	}

	function GetCenter() {
		if ($scope.design.fc5layout.Layout.length == 0) {
			return {
				X: 0,
				Y: 0
			};
		}
		var Hx = -1000000;
		var Hy = -1000000;
		var Hz = -1000000;
		var Lx = 1000000;
		var Ly = 1000000;
		var Lz = 1000000;
		var v = {};
		_.each($scope.design.fc5layout.Layout, function(v) {
			if (v.X() > Hx) {
				Hx = v.X()
			}
			if (v.Y() > Hy) {
				Hy = v.Y();
			}
			if (v.Z() > Hz) {
				Hz = v.Z()
			}
			if (v.X() < Lx) {
				Lx = v.X()
			}
			if (v.Y() < Ly) {
				Ly = v.Y();
			}
			if (v.Z() < Lz) {
				Lz = v.Z()
			}
		})

		switch ($scope.design.fc5layout.ViewAngle) {
			case "Plan":
				return {
					X: Math.round(Lx + (Hx - Lx) / 2),
					Y: Math.round(Lz + (Hz - Lz) / 2)
				};
				break;
			case "Left":
			case "Right":
				return {
					X: Math.round(Lz + (Hz - Lz) / 2),
					Y: Math.round(Ly + (Hy - Ly) / 2)
				};
				break;
			case "Front":
				return {
					X: Math.round(Lx + (Hx - Lx) / 2),
					Y: Math.round(Ly + (Hy - Ly) / 2)
				};
				break;
			case "TopRight":
				return {
					X: Math.round((Lx + (Hx - Lx) / 2) - (Lz + (Hz - Lz) / 2)),
					Y: Math.round(((Ly + (Hy - Ly) / 2) + ((Lx + (Hx - Lx) / 2) * Tan30) + ((Lz + (Hz - Lz) / 2) * Tan30)))
				};
				break;
			case "TopLeft":
				return {
					X: Math.round((Lx + (Hx - Lx) / 2) + (Lz + (Hz - Lz) / 2)),
					Y: Math.round(((Ly + (Hy - Ly) / 2) - ((Lx + (Hx - Lx) / 2) * Tan30) - (-(Lz + (Hz - Lz) / 2) * Tan30)))
				};
				break;
		}

		return {
			X: 0,
			Y: 0
		};
	}

	function resetProcess() {
		_.each($scope.design.fc5layout.Layout, function(v) {
			v.ProcessCheck = false;
		})
	}
	$scope.validationManager = {
		faults: [],
		status: 'unknown',
		clearError: function() {
			this.faults = [];
			$scope.loadErrors = null;
			this.status = 'unknown';
			$scope.accordian.isOpen6 = true;
			$scope.accordian.isOpen7 = true;
			$scope.drawingValid = true;
		},
		clearWarnings: function() {
			_.each($scope.design.fc5layout.Layout, function(v) {
				v.warning = false;
				if (v.Appl){
					v.Appl.warning = false;
				}
			})
		},
		toFixMessages: function() {
			var result =  _.map(this.faults, function(fault){
				if (fault.fixes) {
					var faultItem = null
					if (fault.faultItem) {
						faultItem = fault.faultItem
					} else if (fault.faultItems && fault.faultItems.length == 1) {
						faultItem = fault.faultItems[0]
					}
					return {
						faultItem: faultItem,
						fixes: fault.fixes
					}
				}
			})
			return _.compact(result)
		},
		addFault:function(obj){
			var self = this;

			if (obj.faultItems && obj.faultItems.length > 0){
				_.each(obj.faultItems, function(faultItem){
					if (obj.type == "appliances" && faultItem.Appl){
						faultItem.Appl.warning = true;
						faultItem.Appl.subtype = obj.subtype
					}
					if (obj.type == "vents") {
						faultItem.warning = true
						faultItem.subtype = obj.subtype
					}
				})
				if (obj.consolidate) {
					self.faults.push({
						message: obj.message,
						type: obj.type,
						faultItems: obj.faultItems,
						messageType: obj.messageType || "error",
						subtype: obj.subtype || "validation",
						consolidate: obj.consolidate,
						fixes: obj.fixes
					})
				} else {
					_.each(obj.faultItems, function(faultItem){
						self.faults.push({
							message: obj.message,
							type: obj.type,
							faultItem: faultItem,
							messageType: obj.messageType || "error",
							subtype: obj.subtype || "validation",
							consolidate: obj.consolidate ? true : false,
							fixes: obj.fixes
						})
					})
				}
			} else {
				self.faults.push({
					message: obj.message,
					type: obj.type,
					messageType: obj.messageType || "error",
					subtype: obj.subtype || "validation",
					consolidate: obj.consolidate ? true : false,
					fixes: obj.fixes
				})
			}
			this.status = 'invalid';
		},
		hasBlockingFaults: function() {
			var filtered = _.filter(this.faults, function(f) {
				return f.messageType == "error"
			})
			return (filtered.length > 0) ? true : false
		},
		toDesignFaults: function() {
			return _.clone(this.faults)
			// return _.map(this.faults, function(f) {
			// 	var obj = _.omit(f, "faultItem", "faultItems")
			// 	if (f.faultItem && f.faultItem.GUIident) {
			// 		if (f.type == "appliances") {
			// 			obj.GUIident = f.faultItem.Appl && f.faultItem.Appl.GUIident
			// 		} else if (f.type == "vents") {
			// 			obj.GUIident = f.faultItem.GUIident
			// 		} else {
			// 			//unknown
			// 		}
			// 	} else if (f.faultItems) {
			// 		obj.GUIidents = _.map(f.faultItems, function(faultItem){
			// 			if (f.type == "appliances") {
			// 				return faultItem.Appl.GUIident
			// 			} else if (f.type == "vents") {
			// 				return faultItem.GUIident
			// 			} else {
			// 				//unknown
			// 			}
			// 		})
			// 	}
			// 	return obj
			// })
		},
		toSummaries: function() {
			return _.clone(this.faults)
		},
		pass:function(){
			this.status = 'valid';
		}
	}

	function checkInlineFanForDecreaser() {
		if ($scope.configs.INLINE_FAN_TRANSITION_MODE.value == 'none') {
			return []
		}
		if (!Util.isExhaustOnly($scope.design)) {
			//todo cleanup on existing
			return []
		}
		// var selectedFan = _.find($scope.fans, function(fan) {
		// 	return fan.code == $scope.design.exhFan
		// })
		var selectedFanProduct = $scope.getInlinFanProduct($scope.design.exhFan)
		
		var decreaserNeeded = _.filter($scope.design.fc5layout.Layout, function(v) {
			if (v.Fit1 == 'INL' && selectedFanProduct){
				return selectedFanProduct.centerPointA.dim1 > getDim1(v)
			}
			if (v.Fit2 == 'INL' && selectedFanProduct){
				return selectedFanProduct.centerPointA.dim1 < getDim1(v)
			}
		})
		return decreaserNeeded
	}
	function checkInlineFanForIncreaser() {
		if ($scope.configs.INLINE_FAN_TRANSITION_MODE.value == 'none') {
			return []
		}
		if (!Util.isExhaustOnly($scope.design)) {
			//todo cleanup on existing
			return []
		}
		// var selectedFan = _.find($scope.fans, function(fan) {
		// 	return fan.code == $scope.design.exhFan
		// })
		var selectedFanProduct = $scope.getInlinFanProduct($scope.design.exhFan)

		var increaserNeeded = _.filter($scope.design.fc5layout.Layout, function(v, index) {
			if (v.Fit2 == 'INL' && selectedFanProduct){
				return selectedFanProduct.centerPointA.dim1 > getDim1(v)
			}
			if (v.Fit1 == 'INL' && selectedFanProduct){
				return selectedFanProduct.centerPointB.dim1 < getDim1(v)
			}
		})
		return increaserNeeded
	}
	function checkHorizontalRule(){
		var termSectionHeight = _.map(_.filter($scope.design.fc5layout.Layout, function(v) {
			return (v.Fit1 == 'TER' || v.Fit2 == 'TER')
		}), function(v2) {
			return v2.DimY
		})[0]

		var horizontalSum = _.map($scope.design.fc5layout.Layout, function(v) {
			return v.DimX + v.DimZ
		}).reduce(function(a, b) { return a + b}, 0)

		if (horizontalSum > (termSectionHeight * .75)) {
			return _.filter($scope.design.fc5layout.Layout, function(v){
				return v.DimX + v.DimZ > 0
			})
		} else {
			return []
		}
	}
	function checkInlineFans(){
		var inlineSections = _.filter($scope.design.fc5layout.Layout, function(v) {
			if (v.Fit1 == 'INL'){
				return (v.VentType == 'B-Vent')
			}
		})
		return inlineSections;
	}
	function checkMasonryChimney(){
		//TODO: all this does is check to that the sections are greater than 1.  it doesn't check if its lined.
		var sections = _.filter($scope.design.fc5layout.Layout, function(v) {
			return (v.VentType == 'Masonry Chimney')
		})
		return sections
	}
	function checkApplianceCats(){
		var cat1 = false
		var other = false
		var sections = _.map($scope.design.fc5layout.Layout, function(v) {
			if (v.Appl && v.Fit1 == "STP") {
				return (v.Appl.Cat)
			}
		})

		_.each(sections, function(section) {
			if (section === 'I' || section === 'I - Fan Assisted' || section === 'I - Direct Connect' || section === 'I - Draft Hood') {
				cat1 = true
			} else if (section != undefined){
				other = true
			}
		})


		if (cat1 && other) {
			return _.filter($scope.design.fc5layout.Layout, function (v){
				return v.Appl && v.Fit1 == "STP"
			})
		} else {
			return []
		}
	}
	function checkOutletDiameter(){
		var outDims = _.filter( _.map($scope.design.fc5layout.Layout, function(v) {
			return Util.getOutletDiameter(v, $scope.design)
		}), function(sec) {
			return sec != undefined
		})

		var smallestOutDim = _.min(outDims)

		var vertVentHeight = _.filter($scope.design.fc5layout.Layout, function(v) {
			return (v.Fit2 == 'TER')
		})[0].DimY

		if (smallestOutDim * 7 > vertVentHeight){
			return _.filter($scope.design.fc5layout.Layout, function(v) {
				return Util.getOutletDiameter(v, $scope.design) == smallestOutDim
			})
		} else {
			return []
		}
	}
	function checkSizingTables(){
		return _.filter($scope.design.fc5layout.Layout, function(section) {
			if (!(section.Fit1 == "STP" && section.Appl)) {
				return false
			}

			var height = section.DimY
			var diameter = Util.getOutletDiameter(section, $scope.design)

			if ((3 <= diameter <= 10) && (height < 12)){
				return section
			} else if ((12 <= diameter <= 18) && (height < 24)){
				return section
			} else if ((diameter >= 20) && (height < 36)) {
				return section
			}
		})
	}
	function checkVentSystemSizing(){
		var outDims = _.filter( _.map($scope.design.fc5layout.Layout, function(v) {
			return Util.getOutletDiameter(v, $scope.design)
		}), function(sec) {
			return sec != undefined
		})

		var smallestOutDim = _.min(outDims)

		var horizontalSum = _.map($scope.design.fc5layout.Layout, function(v) {
			return v.DimX
		}).reduce(function(a, b) { return a + b}, 0)

		if (horizontalSum > (smallestOutDim * 18)){
			return _.filter($scope.design.fc5layout.Layout,function(v){
				return Util.getOutletDiameter(v, $scope.design) == smallestOutDim
			})
		} else {
			return []
		}
	}
	function doesWarn(warningType) {
		if ($scope.design.include.exhaust) {
			switch (warningType ) {
				case "checkCodes":
				case "minimumVents":
				case "dims":
				case "applianceCategory":
				case "directionDim":
				case "missingDim":
				case "missingMaterial":
				case "incorrectMaterial":
				case "economizer":
				case "missingDiameter":
				case "dimParam":
				case "stp":
				case "applianceDiameter":
				case "supply":
				case "termination":
				case "overlap":
				case "applianceProps":
				case "compatableFitting":
				case "allow90ElbowPureVertical":
				case "checkIncludes":
					return true
				case "centerpointAssignements":
				case "centerpointAssignements2way":
				case "validateFittingMeta":
				case "validateVariableMode":
					return $scope.design.include.stack
				case "notOptimize":
				default:
					return false
			}
		} else {
			switch (warningType) {
				case "dims":
				case "notOptimize":
				case "minimumVents":
				case "directionDim":
				case "missingDim":
				case "missingMaterial":
				case "missingDiameter":
				case "dimParam":
				case "supply":
				case "termination":
				case "overlap":
				case "allow90ElbowPureVertical":
				case "checkIncludes":
					return true
				case "centerpointAssignements":
				case "centerpointAssignements2way":
				case "validateVariableMode":
				case "validateFittingMeta":
					return $scope.design.include.stack
				case "applianceProps":
				case "checkCodes":
				case "applianceCategory":
				case "incorrectMaterial":
				case "economizer":
				case "stp":
				case "applianceDiameter":
				case "compatableFitting":
				default:
					return false
			}

		}
	}
	$scope.rerunViolations = function() {
		setSystems()
		paint()
	}
	function closestDiameterAbove(dim) {
		var diameter = _.min($scope.diameters, function(diameter) {
			if (dim > diameter.value) {
				return 9999999
			} else {
				return diameter.value - dim
			}
			
		})
		return diameter.value
	}
	function closestDiameterBelow(dim) {
		var diameter = _.min($scope.diameters, function(diameter) {
			if (dim < diameter.value) {
				return 9999999
			} else {
				return dim - diameter.value
			}
			
		})
		return diameter.value
	}
	function closestDiameter(dim, fromInline) {
		if (fromInline) {
			return closestDiameterAbove(dim)
		} else {
			return closestDiameterBelow(dim)
		}
	}
	function setSystems(_target) {
		var v = {},
			vv = {},
			verbose = true,
			ThisSec = {},
			STPSec = [],
			EndsIn = [],
			SysNo = 0,
			NotPro = [],
			Systems = [],
			faultItems = null;

		//init target for use in stack
		var target = null
		if ($scope.convertContext && $scope.convertContext.state) {
			target = $scope.convertContext.state
			$scope.convertContext.state = null
		} else {
			target = _target
		}

		$scope.validationManager.clearError();
		if ($scope.design.application.computeAs == 'COM') {
			//TODO: put validation warnings in for COM
			return true;
		}
		_LoadFunctions();
		$scope.validationManager.clearWarnings();
		if ($scope.design.fc5layout.Layout.length == 0 && doesWarn("minimumVents")) {
			$scope.validationManager.addFault({
				message: "There must be at least one vent section to calculate",
				type: "all"
			})
		}
		//Remove zero length sections.
		$scope.design.fc5layout.Layout = _.filter($scope.design.fc5layout.Layout, function(v) {
			return !((v.X1 == v.X2 && v.Y1 == v.Y2 && v.Z1 == v.Z2))
		})
		var hasFan = $scope.design.exhFan && $scope.design.exhFan != 'None' && $scope.design.exhFan != ''
		var codeviolations_warn = $scope.configs.codeviolations_warn == "true" && $scope.design.codeViolationMode != 'hideIfFanPresent'
		var checkCodes = Util.hasCat($scope.design, ["I - Direct Connect","I - Draft Hood","I - Fan Assisted"]) && (!hasFan || codeviolations_warn)
		var codeFailures = 0

		FittingMetaUtil.getFittingMetas($scope.design, $scope.fittings, target)
		SelectionMetaUtil.getSelectionMetas($scope.design, $scope.allProductTypes)
		
		//check includes
		if (doesWarn("checkIncludes")) {
			if (!$scope.design.application.active) {
				$scope.validationManager.addFault({
					message: "You've selected an application that is not active",
					type: "all"
				})
			}
			try {
				Util.validateInclude($scope.design, $scope.design.application)
			} catch(e) {
				$scope.validationManager.addFault({
					message: e.message,
					type: "all"
				})
			}
		}

		faultItems = checkInlineFanForDecreaser()
		if (faultItems.length > 0) {
			var selectedFan = _.find($scope.fans, function(fan) {
				return fan.code == $scope.design.exhFan
			})
			_.each(faultItems, function(v){
				// var halfway = selectedFan.FanDia - (selectedFan.FanDia - getDim1(v)) / 2
				var fromInline = v.Fit1 == 'INL'
				var fanInletOutlet = fromInline ? 'outlet' : 'inlet'
				// var decreaserDiameter = closestDiameterAbove(halfway, fromInline)
				// var decreaserDiameter = closestDiameter(halfway, fromInline)
				var fanProduct = $scope.getInlinFanProduct($scope.design.exhFan)
				var fanDiameter = fromInline ? fanProduct.centerPointA.dim1 : fanProduct.centerPointB.dim1
				var decreaserDiameter = fanDiameter
				switch ($scope.configs.INLINE_FAN_TRANSITION_MODE.value) {
					case "warn":
						$scope.validationManager.addFault({
							message: "" + decreaserDiameter + "\" diameter section needed for Inline Fan " + fanInletOutlet + ".  Please redraw sections or contact Enervex Support",
							faultItems: [v],
							type: "vents",
						})
						break;
					case "error":
						if (v.INLTransition) {
							$scope.validationManager.addFault({
								message: "" + decreaserDiameter + "\" Decreaser needed for Inline Fan " + fanInletOutlet +" "+ selectedFan.FanDia + '"',
								faultItems: [v],
								type: "vents",
								fixes:[{
									type: 'setDiameterOptimize',
									message: "Update " + decreaserDiameter + "\" decreaser to account for fan and vent diameter mismatch.",
									diameter: decreaserDiameter,
								}]
							})
						} else if (!Util.canInsertLine(v)) {
							$scope.validationManager.addFault({
								message: "" + decreaserDiameter + "\" Decreaser needed for Inline Fan " + fanInletOutlet +" "+ selectedFan.FanDia + '" but cannot insert line because sections are too close together.  Please redraw sections.',
								faultItems: [v],
								type: "vents",
							})
						} else {
							$scope.validationManager.addFault({
								message: "" + decreaserDiameter + "\" Decreaser needed for Inline Fan " + fanInletOutlet +" "+ selectedFan.FanDia + '"',
								faultItems: [v],
								type: "vents",
								fixes:[{
									type: 'decreaserNeeded',
									message: "Add " + decreaserDiameter + "\" decreaser to account for fan and vent diameter mismatch.",
									decreaserDiameter: decreaserDiameter,
									fromInline: fromInline,
								}]
							})
						}
						break;
					default:
						//do nothing
				}
			})
		}
		faultItems = checkInlineFanForIncreaser()
		if (faultItems.length > 0) {
			var selectedFan = _.find($scope.fans, function(fan) {
				return fan.code == $scope.design.exhFan
			})
			_.each(faultItems, function(v){
				// var halfway = selectedFan.FanDia - (selectedFan.FanDia - getDim1(v)) / 2
				var fromInline = v.Fit1 == 'INL'
				var fanInletOutlet = fromInline ? 'outlet' : 'inlet'
				// var increaserDiameter = closestDiameterBelow(halfway, fromInline)
				var fanProduct = $scope.getInlinFanProduct($scope.design.exhFan)
				var fanDiameter = fromInline ? fanProduct.centerPointA.dim1 : fanProduct.centerPointB.dim1
				var increaserDiameter = fanDiameter
				switch ($scope.configs.INLINE_FAN_TRANSITION_MODE.value) {
					case "warn":
						$scope.validationManager.addFault({
							message: "" + increaserDiameter + "\" diameter section needed for Inline Fan " + fanInletOutlet +".  Please redraw sections or contact Enervex Support",
							faultItems: [v],
							type: "vents",
						})
						break;
					case "error":
						if (v.INLTransition) {
							$scope.validationManager.addFault({
								message: "" + increaserDiameter + "\" Increaser needed for Inline Fan " + fanInletOutlet +" "+ selectedFan.FanDia + '"',
								faultItems: [v],
								type: "vents",
								fixes:[{
									type: 'setDiameterOptimize',
									message: "Update " + increaserDiameter + "\" increaser to account for fan and vent diameter mismatch.",
									diameter: increaserDiameter,
								}]
							})
						} else if (!Util.canInsertLine(v)) {
							$scope.validationManager.addFault({
								message: "" + increaserDiameter + "\" Increaser needed for Inline Fan " + fanInletOutlet +" "+ selectedFan.FanDia + '" but cannot insert line because sections are too close together.  Please redraw sections.',
								faultItems: [v],
								type: "vents",
							})
							return
						} else {
							$scope.validationManager.addFault({
								message: "" + increaserDiameter + "\" Increaser needed for Inline Fan " + fanInletOutlet +" "+ selectedFan.FanDia + '"',
								faultItems: [v],
								type: "vents",
								fixes:[{
									type: 'increaserNeeded',
									message: "Add " + increaserDiameter + "\" increaser to account for fan and vent diameter mismatch.",
									increaserDiameter: increaserDiameter,
									fromInline: fromInline,
								}]
							})
						}
						break;
					default:
						//do nothing
				}
			})
		}

		//7 deadly sins
		if (checkCodes && doesWarn("checkCodes")) {
			faultItems = checkHorizontalRule();
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "The total horizontal distance of a vent serving draft hood equipped appliances must not be greater than 75% of the vertical height of the vent.",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}

			faultItems = checkMasonryChimney()
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "10.5.4: Warning: Masonry Chimneys must be lined.",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}

			faultItems = checkInlineFans()
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "10.3.3: Cannot have a B-Vent connector after an inline fan.",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}

			faultItems = checkApplianceCats()
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "Vent connectors serving Cat 1 appliances shall not be connected to those serving other Category Appliances(III, IV, etc.)",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}
			faultItems = checkOutletDiameter()
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "The flow area of the largest section of vertical or chimney shall not exceed seven times the smallest listed appliance categorized vent areas.",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}

			faultItems = checkSizingTables()
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "Z223 Sizing Tables Error: Vertical Connections not proportional to diameter outlet of Appliance.",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}

			faultItems = checkVentSystemSizing()
			if (faultItems.length > 0) {
				codeFailures = codeFailures + 1
				$scope.validationManager.addFault({
					message: "Max vent connector horizontal length shall be 18 in./in. of smallest connector diameter",
					type: "vents",
					faultItems: faultItems,
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}
			if (codeFailures > 0 && codeviolations_warn && hasFan) {
				$scope.validationManager.addFault({
					message: $scope.configs.codeviolations_warn_copy,
					type: "all",
					messageType: 'warning',
					subtype: 'codes',
					consolidate: true
				})
			}
		}
		if ($scope.design.application.computeAs == "BWH" && doesWarn("applianceCategory")) {
			var availableVentMaterials = Util.availableVentMaterials($scope.applianceCategories, $scope.design, $scope.allVentMaterials);
			faultItems = _.filter($scope.design.fc5layout.Layout, function(v){
				return v.Fit1 == "STP" && !(v.Appl && v.Appl.Cat && v.Appl.Cat != '');
			})
			if ((availableVentMaterials.length == 0 || faultItems.length > 0)) {
				$scope.validationManager.addFault({
					message: "Please configure an appropriate cateogry on",
					faultItems: faultItems,
					type: "appliances"
				})
			}
		}
		if (doesWarn('allow90ElbowPureVertical')) {
			var application = $scope.design.application
			faultItems = _.filter($scope.design.fc5layout.Layout, function(v){
				if (v.Fit1 == "90L" && v.DimY >0 && v.DimX == 0 && v.DimZ == 0) {
					if (!LayoutUtil.allowsPureVertical(v, $scope.design)){
						return true
					} else {
						return false
					}
				}
			})
			if (faultItems.length > 0) {
				$scope.validationManager.addFault({
					message: "Cannot use 90L on pure vertical section",
					faultItems: faultItems,
					type: "vents"
				})
			}
		}
		if (doesWarn("directionDim")) {
			_.each($scope.design.fc5layout.Layout, function(v){
				if (v.DimX != 0 || v.DimY != 0 || v.DimZ != 0) {
					return;
				}
				if (Util.direction(v, $scope.design).x && (v.DimX == 0)) {
					v.warning = true;
					$scope.validationManager.addFault({
						message: "Please set " +Util.dimXText($scope.design) + " on",
						faultItems: [v],
						type: "vents"
					})
				}
				if (Util.direction(v, $scope.design).y && (v.DimY == 0)) {
					v.warning = true;
					$scope.validationManager.addFault({
						message: "Please set " +Util.dimYText($scope.design) + " on",
						faultItems: [v],
						type: "vents"
					})
				}
				if (Util.direction(v, $scope.design).z && (v.DimZ == 0)) {
					v.warning = true;
					$scope.validationManager.addFault({
						message: "Please set " +Util.dimZText($scope.design) + " on",
						faultItems: [v],
						type: "vents"
					})
				}
			})
		}
		if (doesWarn("missingDim")) {
			faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
				if (v.Dim1 == "" && v.Optimize != "Yes" && doesWarn("dims")) {
					v.warning = true;
					return true;
				}
			})
			if (faultItems && faultItems.length > 0) {
				$scope.validationManager.addFault({
					message: "Please set the diameter or change to the Optimize options to 'Yes' on",
					faultItems: faultItems,
					type: "vents"
				})
			}
		}
		// boilers
		if ($scope.design.application.computeAs == "BWH") {
			faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
				if (v.VentType == "" && doesWarn("missingMaterial")) {
					v.warning = true;
					return true
				}
			})
			if (faultItems && faultItems.length > 0 ) {
				$scope.validationManager.addFault({
					message: "Please set the material on",
					faultItems: faultItems,
					type: "vents"
				})
			}


			faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
				var material = _.find(availableVentMaterials, function(a) {
					return a.code == v.VentType
				})
				if (!material && doesWarn("incorrectMaterial")) {
					v.warning = true;
					return true
				}
			})
			if (faultItems && faultItems.length > 0 ) {
				_.each(faultItems, function(v){
					$scope.validationManager.addFault({
						message: "Incompatable material " + v.VentType + " on",
						faultItems: [v],
						type: "vents"
					})
				})
			}
		}
		faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
			if ((v.Fit1 == "ECM") && (!v.ECM || !v.ECM.MaxT|| !v.ECM.Dia || !v.ECM.KValue || !v.ECM.Dia) && doesWarn("economizer")) {
				v.warning = true;
				return true
			}
		})
		if (faultItems && faultItems.length > 0) {
			$scope.validationManager.addFault({
				message: "Please click the economizer and set all the parameters on",
				faultItems: faultItems,
				type: "vents"
			})
		}

		populateDiameter($scope.design.fc5layout.Layout)

		faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
			if ((v.Dim1 == "" || parseInt(v.Dim1) < 3) && v.Optimize != "Yes" && doesWarn("missingDiameter")) {
				v.warning = true;
				return true
			}
		})
		if (faultItems && faultItems.length > 0) {
			$scope.validationManager.addFault({
				message: "Please set the diameter or change to the Optimize options to 'Yes' on",
				faultItems: faultItems,
				type: "vents"
			})
		}

		faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
			if ((v.Shape == "REC" || v.Shape == "OVA") && (v.Dim2 == "" || !v.Dim2) && v.Optimize == "No" && doesWarn("dimParam")) {
				v.warning = true;
				return true;
			}
		})
		if (faultItems && faultItems.length > 0 ) {
			$scope.validationManager.addFault({
				message: "Please set the Side 2 dimension on",
				faultItems: faultItems,
				type: "vents"
			})
		}

		faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
			if (v.Optimize == "Yes" && doesWarn("notOptimize")) {
				v.warning = true;
				return true;
			}
		})
		if (faultItems && faultItems.length > 0 ) {
			_.each(faultItems,function(v){
				var diameter =  v.result && v.result.Diameter
				if (diameter) {
					$scope.validationManager.addFault({
						message: "Optimize not allowed on",
						faultItems: [v],
						type: "vents",
						fixes:[{
							type: 'setDiameterOptimize',
							message: "Set optimize to 'No' and diameter to " + diameter,
							diameter: diameter
						}]
					})
				} else {
					$scope.validationManager.addFault({
						message: "Optimize not allowed on",
						faultItems: [v],
						type: "vents"
					})
				}
			})
		}
		setAutoFittings(target);
		faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
			if (v.Fit1 == "STP" && !v.Appl && doesWarn("stp")) {
				v.warning = true;
				return true;
			}
		})
		if (faultItems && faultItems.length > 0) {
			$scope.validationManager.addFault({
				message: "Please provide a definition on",
				faultItems: faultItems,
				type: "appliances"
			})
		}
		faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
			if (v.Fit1 == "STP" && v.Appl && doesWarn("applianceDiameter")) {
				var diameter = Util.getOutletDiameter(v, $scope.design, true)
				if (v.Appl.OutShape == 'ROU' && !diameter) {
					v.warning = true;
					return true;
				}
			}
		})
		if (faultItems && faultItems.length > 0) {
			$scope.validationManager.addFault({
				message: "Please provide diameter for Appliance Vent Connection",
				faultItems: faultItems,
				type: "appliances"
			})
		}

		if (doesWarn("centerpointAssignements2way")) {
			faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
				var fitting = FittingMetaUtil.getFit1(v, $scope.fittings)
				var fittingMeta = FittingMetaUtil.getFittingMeta(v, $scope.design, $scope.fittings, target)
				var inputs = Util.getInlets(v, $scope.design)
				if (fitting.secno == 2) {
					if (inputs.length == 1) {
						var fixCenterpoint = null,
							fixGUIidentPath = null,
							fixID = null
						var invalidAssignement = _.find(["centerPointA", "centerPointB"], function(key){
							var centerpoint = fittingMeta[key]
							if (!(centerpoint.type == "section" && centerpoint.ID)) {
								fixCenterpoint = key
								var missing = _.find(["centerPointA", "centerPointB"], function(otherkey){
									if (key != otherkey) {
										var otherCenterpoint = fittingMeta[otherkey]
										if (otherCenterpoint.ID == inputs[0].ID){
											fixGUIidentPath = v.GUIidentPath
											fixID = v.ID
										} else {
											fixGUIidentPath = inputs[0].GUIidentPath
											fixID = inputs[0].ID
										}
									}
								})
								return true
							}
						})
						if (invalidAssignement) {
							v.warning = true;
							v.fixCenterpoint = fixCenterpoint
							v.fixGUIidentPath = fixGUIidentPath
							v.fixID = fixID
							return true;
						}
					}
				}
			})
			if (faultItems && faultItems.length > 0) {
				_.each(faultItems, function(v){
					$scope.validationManager.addFault({
						message: "Please set stack section",
						faultItems: [v],
						type: "vents",
						fixes:[{
							type: 'setSection',
							message: 'Set stack section to ' + v.fixGUIidentPath + " on " +v.fixCenterpoint,
							fixID: v.fixID,
							fixGUIidentPath: v.fixGUIidentPath,
							fixCenterpoint: v.fixCenterpoint
						}]
					})
				})
			}
		}
		if (doesWarn("centerpointAssignements")) {
			faultItems = _.filter($scope.design.fc5layout.Layout, function(v) {
				var fitting = FittingMetaUtil.getFit1(v, $scope.fittings)
				var fittingMeta = FittingMetaUtil.getFittingMeta(v, $scope.design, $scope.fittings, target)
				var inputs = Util.getInlets(v, $scope.design)
				if (fitting.secno == 3) {
					if (inputs.length == 1) {
						var fixCenterpoint = null
						var missingAccessory = _.find(["centerPointA", "centerPointB", "centerPointC"], function(key){
							var centerpoint = fittingMeta[key]
							if (centerpoint.type == "productSubtype" && !centerpoint.productSubtype) {
								fixCenterpoint = key
							}
						})
						if (missingAccessory) {
							v.warning = true;
							v.fixCenterpoint = fixCenterpoint
							return true;
						}
						var fixCenterpoint = null
						var invalidAssignement = _.find(["centerPointA", "centerPointB", "centerPointC"], function(key){
							var centerpoint = fittingMeta[key]
							if (centerpoint.type == "section" && !centerpoint.ID) {
								fixCenterpoint = key
								return true
							}
						})
						if (invalidAssignement) {
							v.warning = true;
							v.fixCenterpoint = fixCenterpoint
							return true;
						}
					}
				}
			})
			if (faultItems && faultItems.length > 0) {
				_.each(faultItems, function(v){
					var coverType = LayoutUtil.get3wayCover(v, $scope.design)
					var productSubtype = _.find($scope.allProductSubtypes, function(st){
						return st._id == coverType
					})
					if (productSubtype) {
						$scope.validationManager.addFault({
							message: "Please set stack cover",
							faultItems: [v],
							type: "vents",
							fixes:[{
								type: 'setCover',
								message: 'Set stack cover to ' + productSubtype.name,
								productSubtype: productSubtype._id,
								fixCenterpoint: v.fixCenterpoint
							}]
						})
					} else {
						$scope.validationManager.addFault({
							message: "Please set stack cover",
							faultItems: [v],
							type: "vents"
						})
					}
				})
			}
		}
		if (doesWarn("validateFittingMeta")) {
			_.each($scope.design.fc5layout.Layout, function(v) {
				var fitting = FittingMetaUtil.getFit1(v, $scope.fittings)
				var fittingMeta = FittingMetaUtil.getFittingMeta(v, $scope.design, $scope.fittings, target)
				var message = FittingMetaUtil.validateVentAsMessage(v, fittingMeta, $scope.design, $scope.fittings)
				if (message) {
					$scope.validationManager.addFault({
						message: message,
						faultItems: [v],
						type: "vents"
					})
				}
			})
		}

		//check compustion supply
		if (($scope.design.include.supply || $scope.design.application.computeAs == 'COM') && doesWarn("supply")) {
			if ($scope.design.fc5layout.Intake.Ducting == 'ROU' && $scope.design.fc5layout.Intake.DiaSel != 'AUT') {
				if (!$scope.design.fc5layout.Intake.Dia1) {
					$scope.validationManager.addFault({
						message: "Please provide Duct Diameter for Combustion Air Supply",
						type: "all"
					})
				}
			}
			if ($scope.design.fc5layout.Intake.Ducting == 'REC' && $scope.design.fc5layout.Intake.DiaSel == 'MAN') {
				if (!$scope.design.fc5layout.Intake.Dia1) {
					$scope.validationManager.addFault({
						message: "Please provide Duct (X) for Combustion Air Supply",
						type: "all"
					})
				}
				if (!$scope.design.fc5layout.Intake.Dia2) {
					$scope.validationManager.addFault({
						message: "Please provide Duct (Y) for Combustion Air Supply",
						type: "all"
					})
				}
			}
			if ($scope.design.fc5layout.Intake.Ducting != 'NON') {
				if (!$scope.design.fc5layout.Intake.DiaSel) {
					$scope.validationManager.addFault({
						message: "Please provide Diameter Selection for Combustion Air Supply",
						type: "all"
					})
				}
				if (!$scope.design.fc5layout.Intake.Length) {
					$scope.validationManager.addFault({
						message: "Please provide Total Length for Combustion Air Supply",
						type: "all"
					})
				}
			}
		}

		resetProcess();
		NotPro = notProcessed();
		NotPro.sort(compareXYZ);

		SystemsManager.traverseSystems($scope.design)

		while (NotPro.length > 0) {
			var ThisSys = [];
			ThisSys.push(NotPro[0]);
			NotPro[0].ProcessCheck = true;
			var ThisRun = [{
				None: "None"
			}];
			while (ThisRun.length > 0) {
				ThisRun = [];
				for (var i = 0; i < ThisSys.length; i++) {
					v = ThisSys[i];
					for (var ii = 0; ii < NotPro.length; ii++) {
						vv = NotPro[ii];
						if (v.ID != vv.ID && !vv.ProcessCheck && (
								(v.X1 == vv.X1 && v.Y1 == vv.Y1 && v.Z1 == vv.Z1) ||
								(v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) ||
								(v.X1 == vv.X2 && v.Y1 == vv.Y2 && v.Z1 == vv.Z2) ||
								(v.X2 == vv.X2 && v.Y2 == vv.Y2 && v.Z2 == vv.Z2))) {
							ThisRun.push(vv);
							vv.ProcessCheck = true;
						}
					}
				}
				if (ThisRun.length > 0) {
					for (var i = 0; i < ThisRun.length; i++) {
						ThisSys.push(ThisRun[i]);
					}
				}
			}
			// _cleanSections(ThisSys);
			Systems.push(ThisSys);
			NotPro = notProcessed();
		}

		resetProcess();
		for (var ns = 0; ns < Systems.length; ns++) {
			ThisSys = Systems[ns];
			SysNo++

			var ThisSec = {};
			var STPSec = [];
			var EndsIn = [];
			var Appls = [];

			//Validate Terminations
			var terms = _.filter(ThisSys, function(v) {
				return v.Fit2 == "TER"
			})
			if (terms.length < 1 && doesWarn("termination")) {
				paint();
				$scope.validationManager.addFault({
					message: "Endpoint Required: unable to locate an Exit Point",
					type: "all"
				})
				throw "Fatal Stop Validation Manager"
			} else if (terms.length > 1. && doesWarn("termination")) {
				paint();
				$scope.validationManager.addFault({
					message: "Endpoint Required: Multiple Endpoints found on",
					faultItems: terms,
					type: "vents"
				})
				throw "Fatal Stop Validation Manager"
			}

			//Set layout sections
			_.each(ThisSys, function(v) {
				if (v.Fit1 == "STP" || v.Fit2 == "STP") {
					STPSec.push(v);
				}
			})
			for (i = 0; i < STPSec.length; i++) {
				ThisSec = STPSec[i];
				if (ThisSec.Fit2 == "STP") {
					FlipSection(ThisSec);
				}
				ThisSec.ProcessCheck = true;
				var cont = true;
				while (cont == true) {
					EndsIn.length = 0;
					_.each(ThisSys, function(vv) {
						if (((ThisSec.X2 == vv.X1 && ThisSec.Y2 == vv.Y1 && ThisSec.Z2 == vv.Z1) || (ThisSec.X2 == vv.X2 && ThisSec.Y2 == vv.Y2 && ThisSec.Z2 == vv.Z2)) && ThisSec.ID != vv.ID) {
							EndsIn.push(vv);
						}
					})
					if (EndsIn.length == 0) {
						cont = false;
					} else if (EndsIn.length == 1) {
						vv = EndsIn[0];
						if (ThisSec.X2 == vv.X2 && ThisSec.Y2 == vv.Y2 && ThisSec.Z2 == vv.Z2) {
							FlipSection(vv)
						}
						vv.ProcessCheck = true;
						ThisSec = vv;
					} else {
						var NotChecked = 0;
						_.each(EndsIn, function(vv) {
							if (vv.ProcessCheck == false) {
								NotChecked++
							}
						})
						if (NotChecked == 1) {
							for (ii = 0; ii < EndsIn.length; ii++) {
								vv = EndsIn[ii];
								if (vv.ProcessCheck == false) {
									if (ThisSec.X2 == vv.X2 && ThisSec.Y2 == vv.Y2 && ThisSec.Z2 == vv.Z2) {
										FlipSection(vv)
									}
									vv.ProcessCheck = true;
									ThisSec = vv;
									break;
								}
							}
						} else {
							cont = false;
						}
					}
				}
			}

			_.each(STPSec, function(ThisSec) {
				var pa = {};
				pa.Sections = [];
				pa.VentLength = Math.sqrt((ThisSec.DimX * ThisSec.DimX) + (ThisSec.DimY * ThisSec.DimY) + (ThisSec.DimZ * ThisSec.DimZ));
				pa.X = ThisSec.X1
				pa.Sections.push(ThisSec);
				cont = true;
				while (cont == true) {
					var NextSec = _.find(ThisSys, function(vv) {
						return (ThisSec.X2 == vv.X1 && ThisSec.Y2 == vv.Y1 && ThisSec.Z2 == vv.Z1)
					})
					if (!NextSec) {
						cont = false;
					} else {
						pa.VentLength = pa.VentLength + Math.sqrt((NextSec.DimX * NextSec.DimX) + (NextSec.DimY * NextSec.DimY) + (NextSec.DimZ * NextSec.DimZ));
						pa.Sections.push(NextSec);
						ThisSec = NextSec;
					}
				}
				Appls.push(pa);
			})

			//Set GUIidents
			var SecNo = 0;
			var ApplNo = 0;

			_.each(ThisSys, function(v, index) {
				v.ProcessCheck = false;
				// v.GUIidentAlt = ""+index
			})

			Appls.sort(compareLength);
			function setGuid(section, length, SecNo, SysNo) {
				var before = section.GUIident
				var after = getLeading(length, SecNo, SysNo);
				section.GUIident = after
				// console.log("set " + section.GUIidentAlt +" GUIident to "+after)
			}
			_.each(Appls, function(pa, index) {
				// console.log("appliance."+index + ":" + _.map(pa.Sections,function(v) {
				// 	return v.GUIidentAlt
				// }))
				ThisSec = pa.Sections[0];
				SecNo++
				setGuid(ThisSec, ThisSys.length, SecNo, SysNo)
				// ThisSec.GUIident = getLeading(ThisSys.length, SecNo, SysNo);
				// console.log("set GUIident to ",ThisSec.GUIident)
				ThisSec.ProcessCheck = true;
				ApplNo++;
				if (ThisSec.Fit1 == "STP" && ThisSec.Appl) {
					ThisSec.Appl.GUIident = getLeading(Appls.length, ApplNo, SysNo);
				}

				for (var ii = 1; ii < pa.Sections.length; ii++) {
					ThisSec = pa.Sections[ii];
					var AllInCheck = true;
					_.each($scope.design.fc5layout.Layout, function(vv) {
						if (vv.X2 == ThisSec.X1 && vv.Y2 == ThisSec.Y1 && vv.Z2 == ThisSec.Z1) {
							if (vv.ProcessCheck == false) {
								// console.log("ProcessCheck false for "+ThisSec.GUIidentAlt +" due to nonchecked entry "+vv.GUIidentAlt)
								AllInCheck = false
							}
						}
					})
					if (AllInCheck == true) {
						SecNo++
						setGuid(ThisSec, ThisSys.length, SecNo, SysNo)
						// ThisSec.GUIident = getLeading(ThisSys.length, SecNo, SysNo);
						// console.log("set GUIident to ",ThisSec.GUIident)
						ThisSec.ProcessCheck = true;
					} else {
						// console.log("breaking, not set GUIident for " + ThisSec.GUIidentAlt)
						break;
					}
				}
			})
			ThisSys.sort(compareID);
			var missingGuidents = _.filter($scope.design.fc5layout.Layout, function(v) {
				if ((!v.GUIident || v.GUIident == "") && doesWarn("overlap")) {
					v.warning = true
					return true
				}
			})
			if (missingGuidents.length > 0){
				$scope.validationManager.addFault({
					message: "Drawing issues, most likely overlap",
					type: "vents",
					faultItems: missingGuidents
				})
			}

			//Validate Dryers
			if ($scope.design.application.computeAs == "DRY") {
				for (i = 0; i < ThisSys.length; i++) {
					v = ThisSys[i]
					if (v.Appl && v.Fit1 == "STP") {
						var pa = v.Appl
						if (pa.Volume < 1 && doesWarn("applianceProps")) {
							$scope.validationManager.addFault({
								message: "Volume must be higher than 0 on",
								type: "appliances",
								faultItems: [v]
							})
						}
						if (pa.OutDia < 2 && doesWarn("applianceProps")) {
							$scope.validationManager.addFault({
								message: "Outlet diameter must be 2 in. or more on",
								type: "appliances",
								faultItems: [v]
							})
						}
						if (pa.PresH < pa.PresL && doesWarn("applianceProps")) {
							$scope.validationManager.addFault({
								message: "Back Pressure Max. cannot be lower than Back Pressure Low on",
								type: "appliances",
								faultItems: [v]
							})
						}
					}
				}
			}
			_.each($scope.design.fc5layout.Layout, function(v) {
				var fittings1 = Util.getFittings($scope.design.fc5layout.Layout, v, $scope.design, $scope.fittings, true)
				var fit1 = v.Fit1;
				var fit2 = v.Fit2;
				var existing1 = _.find(fittings1, function(fitting){
					return fitting.code == v.Fit1
				})

				var fittings2 = Util.getFittings($scope.design.fc5layout.Layout, v, $scope.design, $scope.fittings, false)
				var existing2 = _.find(fittings2, function(fitting){
					return fitting.code == v.Fit2
				})
				var GUIident = parseInt(v.GUIident)
				if(!existing2 && doesWarn("compatableFitting")){
					v.warning = true;
					$scope.validationManager.addFault({
						message: "Incompatable fitment " + GUIident+".2 " + v.Fit2 + " on",
						type: "vents",
						faultItems: [v]
					})
				}
				if (!existing1 && doesWarn("compatableFitting")){
					v.warning = true;
					$scope.validationManager.addFault({
						message: "Incompatable fitment " + GUIident+".1 " + v.Fit1 + " on",
						type: "vents",
						faultItems: [v]
					})
				}
			})
		}
		if ($scope.configs.multipleSections != "true" && Systems.length > 1 && !SystemsManager.multipleSystemsSupported($scope.design)) {
			$scope.validationManager.addFault({
				message: "Multiple Systems not Supported",
				type: "all"
			})
		}
		_.each($scope.design.fc5layout.Layout, function(v) {
			if ($scope.design.stack && $scope.design.include.stack) {
				FittingMetaUtil.getFittingMeta(v, $scope.design, $scope.fittings, target)
			}
		})

		if ($scope.validationManager.hasBlockingFaults()){
			var sorted = _.sortBy($scope.validationManager.faults, function(f){
				var index = 0;
				if (f.type == "all"){
					index = 0
				} else if (f.type == "appliances"){
					index = 1000
					index = index + parseInt(f.faultItem && f.faultItem.Appl && f.faultItem.Appl.GUIident||0)
				} else if (f.type == "vents"){
					index = 1000000
					index = index + parseInt(f.faultItem && f.faultItem.GUIident||0)
				}
				return index;
			})
			$scope.transformGUIidents($scope.design)
			console.log('setSystems() throwing', sorted)
			throw sorted
		}

		$scope.validationManager.pass()
		$scope.transformGUIidents($scope.design)
		return true;
	}

	function notProcessed() {
		return _.filter($scope.design.fc5layout.Layout, function(v) {
			return (v.ProcessCheck == false)
		});
	}

	function getLeading(tot, no, _SysNo) {
		var SysLead = ""
		// if (Systems.length > 1) {
		// 	SysLead = _SysNo.toString() + '.';
		// }
		if (tot < 10) {
			return SysLead + no.toString();
		}
		if (tot < 100) {
			if (no < 10) {
				return SysLead + "0" + no.toString();
			} else {
				return SysLead + no.toString();
			}
		}

		if (no < 10) {
			return SysLead + "00" + no.toString();
		} else if (no < 100) {
			return SysLead + "0" + no.toString();
		} else {
			return SysLead + no.toString();
		}
	}

	function FlipSection(sec, _setValue) {
		if (_setValue){
			var original = _.clone(sec)
			sec.X1 = original.X2;
			sec.X2 = original.X1;
			sec.Y1 = original.Y2;
			sec.Y2 = original.Y1;
			sec.Z1 = original.Z2;
			sec.Z2 = original.Z1;
			setValue("Fit1", original.Fit2, "string", sec, true)
			setValue("Fit2", original.Fit1, "string", sec, true)
		} else {
			var x1 = 0;
			var y1 = 0;
			var z1 = 0;
			var fit1 = "";
			x1 = sec.X1;
			y1 = sec.Y1;
			z1 = sec.Z1
			fit1 = sec.Fit1
			sec.X1 = sec.X2;
			sec.Y1 = sec.Y2;
			sec.Z1 = sec.Z2;
			sec.X2 = x1;
			sec.Y2 = y1;
			sec.Z2 = z1;
			sec.Fit1 = sec.Fit2;
			sec.Fit2 = fit1;
		}
	}

	function compareLength(a, b) {
		if (a.VentLength < b.VentLength) {
			return 1;
		}
		if (a.VentLength > b.VentLength) {
			return -1;
		}
		if (a.X < b.X) {
			return -1;
		}
		if (a.X > b.X) {
			return 1;
		}
		return 0;
	}

	function isBranch(v1) {
		var v2 = {};
		var v3 = {};
		var v4 = {};
		var col = _.filter($scope.design.fc5layout.Layout, function(v2) {
			return (v1.ID != v2.ID && (v1.X2 == v2.X1 && v1.Y2 == v2.Y1 && v1.Z2 == v2.Z1))
		})
		if (col.length < 2) {
			return false
		};
		if (col.length == 2) {
			v2 = col[0];
			v3 = col[1];
			var av2v3 = Util.Get3DAngle(v2, v3);
			return (Util.Get3DAngle(v1, v2) > av2v3 || Util.Get3DAngle(v1, v3) > av2v3);
		} else {

		}
		return false;
	}

	function isInline(v1) {
		var found = _.find($scope.design.fc5layout.Layout, function(v2) {
			return (v1.ID != v2.ID && (v1.X2 == v2.X1 && v1.Y2 == v2.Y1 && v1.Z2 == v2.Z1))
		})
		return (found) ? (Util.Get3DAngle(v1, v2) < 10) : false
	}

	function printCanvas(el) {
		var dataUrl = document.getElementById(el).toDataURL(); //attempt to save base64 string to server using this var
		var windowContent = '<!DOCTYPE html>';
		windowContent += '<html>'
		windowContent += '<head><title>Print canvas</title></head>';
		windowContent += '<body>'
		windowContent += '<img src="' + dataUrl + '">';
		windowContent += '</body>';
		windowContent += '</html>';
		var printWin = window.open('', '', '');
		printWin.document.open();
		printWin.document.write(windowContent);
	}

	function SetControls(_setProps) {
		var SupFan = null;

		if ($scope.design.supFan) {
			SupFan = $scope.design.supFan;
		} else {
			SupFan = "None"
		}
		if (!SupFan) {
			SupFan = "None"
		}

		var ExhFan = $scope.design.exhFan;
		if (!ExhFan) {
			ExhFan = "None"
		}
		var isConstantVol = ConstantVol();
		$scope.cmbControls.options = Util.availableControls($scope.controls, $scope.design, isConstantVol, SupFan, ExhFan);

		$scope.stickySelections.capture("control")
		if ($scope.cmbControls.options && $scope.cmbControls.options.length > 0) {
			if (_overRide(_setProps)) {
				$scope.design.control = $scope.cmbControls.options[0].code;
			}
		} else {
			$scope.design.control = null;
		}
	}

	function ApplCount() {
		var Count = 0;
		_.each($scope.design.fc5layout.Layout, function(v) {
			if (v.Appl) {
				Count++
			}
		})
		return Count
	}
	function setVoltage(_setProps) {
		var exhFanName = ($scope.design.exhFan) ? $scope.design.exhFan.split("-")[0] : null;
		var supFanName = ($scope.design.supFan) ? $scope.design.supFan.split("-")[0] : null;
		var colExh = _.find($scope.fanvoltages, function(v) {
			return !v.isDefault && _.contains(v.fans, exhFanName)
		})
		if (!colExh) {
			colExh = _.find($scope.fanvoltages, function(v) {
				return v.isDefault
			})
		}
		$scope.cmbExhFanVoltage.options = colExh && colExh.voltages;
		var existingExh = _.find($scope.cmbExhFanVoltage.options, function(opt) {
			return opt == $scope.design.voltage;
		})
		if (_overRide(_setProps) || !existingExh) {
			$scope.design.voltage = $scope.cmbExhFanVoltage.options[0];
		}

		if (supFanName){
			var colSup = _.find($scope.fanvoltages, function(v) {
				return !v.isDefault && _.contains(v.fans, supFanName)
			})
			if (!colSup) {
				colSup = _.find($scope.fanvoltages, function(v) {
					return v.isDefault
				})
			}
			$scope.cmbSupFanVoltage.options = colSup && colSup.voltages;
			//try to use the exh voltage
			var existingSup = _.find($scope.cmbSupFanVoltage.options, function(opt) {
				return opt == $scope.design.voltage;
			})
			if (_overRide(_setProps) || !existingSup) {
				$scope.design.supVoltage = $scope.cmbSupFanVoltage.options[0];
			}
		}
	}

	function setAutoFittings(target) {
		_.each($scope.design.fc5layout.Layout, function(v) {
			var inlets = Util.getInlets(v, $scope.design);
			var endsIn = Util.getEndsIn(v, $scope.design)
			if (inlets.length == 0) {
				v.Fit1 = "STP"
			}
			if (endsIn.length > 0 && v.Fit2 == "TER") {
				v.Fit2 = ""
			}
			Util.setAutoFitting(v, inlets, $scope.design, $scope.fittings, target)
			if (endsIn.length == 0) {
				v.Fit2 = "TER"
			}
		})
	}

	function getNext(v) {
		return _.find($scope.design.fc5layout.Layout, function(vv) {
			return (vv.X1 == v.X2 && vv.Y1 == v.Y2 && vv.Z1 == v.Z2)
		})
	}

	function sortBOM(a, b) {

		var o1 = a.SectionID;
		var o2 = b.SectionID;

		var p1 = a.Order;
		var p2 = b.Order;

		if (o1 != o2) {
			if (o1 < o2) return -1;
			if (o1 > o2) return 1;
			return 0;
		}
		if (p1 < p2) return -1;
		if (p1 > p2) return 1;
		return 0;
	}

	function showDimsChange() {
		localStorage.LayShowDims = document.getElementById("chkShowDims").checked
		paint();
	}

	function showCompChange() {
		localStorage.LayShowComp = document.getElementById("chkShowComp").checked
		paint();
	}

	function setTermFan(fan) {
		var existing= _.find($scope.design.fc5layout.Layout, function(v){
			return v.Fit1 == "INL" || v.Fit1 == "ECO"
		})
		if (existing) {
			console.log("squahsing set of term fan, still contains inline")
			return
		}
		_.each($scope.design.fc5layout.Layout, function(v) {
			if (v.Fit1 == "INL") {
				v.Fit1 = ""
			};
			if (v.Fit2 == "TER") {
				if (fan == "None"){
					v.TermFan = null
				} else {
					v.TermFan = fan
				}
			};
		})
	}
	function convertPoints(p) {
		return _.filter(p, function(o) {
			o[0] = cfm2m3(o[0])
			o[1] = in2pa(o[1])
		})
	}

	function ConstantVol() {
		var AllDh = true
		var ApplCount;
		var TotalMBH = 0

		_.each($scope.design.fc5layout.Layout, function(v) {
			if (v.Appl) {
				var a = v.Appl;
				TotalMBH += a.InpHigh
				ApplCount++
				if (a.Cat != "I - Draft Hood") {
					AllDh = false
				};
			}
		})

		if (AllDh && (TotalMBH > 1499 || ApplCount == 0)) {
			return true;
		} else {
			return false
		}
	}

	function Condensing() {
		var found = _.find($scope.design.fc5layout.Layout, function(v) {
			return v.Appl && (v.Appl.Cate == "II" || v.Appl.Cat == "IV")
		})
		return found ? true : false
	}

	function getTotalHeight() {
		var Hei = 0.0;
		var v = $scope.design.fc5layout.Layout[0];
		var AtEnd = false;
		while (v) {
			Hei += v.DimY / 12;
			v = bj(v);
		}
		return Hei;
	}

	function bj(v) {
		for (var i = 0; i < $scope.design.fc5layout.Layout.length; i++) {
			var vv = $scope.design.fc5layout.Layout[i];
			if (v.X2 == vv.X1 && v.Y2 == vv.Y1 && v.Z2 == vv.Z1) {
				return vv
			}
		}
		return undefined;
	}
	function _traverseMODS(v, PresL, level) {
		var maxLevels = 50
		// var pressureDrop = $scope.design.results.ssss
		var pressureDropSection = _.find($scope.design.calcResult.VentDataMech, function(mechV){
			return mechV.No == v.GUIident
		})
		var pressureDropS = pressureDropSection && pressureDropSection.Loss || PresL
		var pressureDrop = parseFloat(pressureDropS)
		if (-1*pressureDrop < PresL) {
			return {
				v: v,
				pressureDrop: pressureDrop,
				applianceCapacity: PresL,
				level: level
			}
		} else {
			var outlet = _.first(Util.getEndsIn(v, $scope.design))
			if (outlet && level < maxLevels) {
				return _traverseMODS(outlet, PresL, level+1)
			} else {
				return null
			}
		}
	}
});
